import { useTranslation } from "react-i18next";
import { UseFormReturn, useWatch } from "react-hook-form";
import { BiDocument } from "@/models/document";
import React, { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types";
import {
  CustomSearchBox,
  GenericComboBox,
} from "@/components/form/GenericComboBox";
import { categoryApi } from "@/api/endpoints/categoryApi";
import { useDebounce } from "@/util/debounce";
import Spinner from "@/components/loading/spinner";

export const EditCategorySelection = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
  TNameLabel extends FieldPath<T> = FieldPath<T>,
>({
  name,
  labelName,
  form,
  document,
  className,
}: {
  edit?: boolean;
  name: TName;
  labelName: TNameLabel;
  form: UseFormReturn<T>;
  document: BiDocument;
  className?: string;
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 200);

  const documentCategory = useWatch({ name: name });
  const label = useWatch({ name: labelName });
  const documentKind = useWatch({ name: "kind" });

  const { data: categories, isLoading } = categoryApi.useSearchCategoriesQuery({
    document,
    source: debouncedSearch ? "search" : "label",
    search: debouncedSearch || label,
    kind: documentKind,
  });

  let invalid =
    categories &&
    !categories.results.find((d) => d.category.key === documentCategory);

  useEffect(() => {
    if (invalid) {
      form.setValue(name, "" as any);
    }
  }, [invalid]);

  if (!categories) {
    return (
      <div className={className}>
        <Spinner size={25} />
      </div>
    );
  }

  let options = categories.results.map((c) => ({
    value: c.category.key,
    label: c.category.title,
    description: c.category.description,
  }));

  return (
    <GenericComboBox
      className={className}
      form={form}
      placeholder={t("model.document.category_placeholder")}
      name={name}
      options={options}
      popoverContent={(args) =>
        CustomSearchBox({
          onSearch: setSearch,
          ...args,
        })
      }
    />
  );
};
