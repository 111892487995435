import React, { useEffect, useState } from "react";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import PersonalData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/PersonalData";
import ChildrenData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/ChildrenData";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import EmploymentData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/EmploymentData";
import TaxData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/TaxData";
import SocialSecurityData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/SocialSecurityData";
import VwlData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/VwlData";
import TaxableData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/TaxableData";
import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

const EmployeeData: React.FC<{
  employeeId?: string;
  currentTitle?: string;
}> = ({ employeeId, currentTitle }) => {
  const [error, setError] = useState<string>("");
  const [initialData, setInitialData] = useState<boolean>(false);

  const { data, error: apiError } = useGetEmployeeDataQuery<any>(
    { employeeId: employeeId || "" },
    { skip: !employeeId },
  );

  useEffect(() => {
    if (apiError) setError("Fehler beim Laden der Mitarbeiterdaten.");
  }, [apiError]);

  const employeeData = data?.events?.find(
    (event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent,
  )?.employeeFillQuestionnaireEvent;

  const renderContent = () => {
    switch (currentTitle) {
      case questionnaireTranslation("employmentCard.occupation"):
        return (
          <>
            <p>{employeeData?.createdAt}</p>
            <PersonalData
              personalDetails={employeeData?.personalDetails || {}}
            />
            <ChildrenData children={employeeData?.children || []} />
            <EmploymentData
              employmentDetails={employeeData?.employmentDetails || {}}
            />
          </>
        );
      case questionnaireTranslation("taxCard.taxes"):
        return (
          <>
            <TaxData taxInformation={employeeData?.taxInformation || {}} />
            <TaxableData
              taxableEmployment={employeeData?.taxableEmployment || {}}
            />
          </>
        );

      case questionnaireTranslation("socialSecurityCard.socialSecurity"):
        return (
          <SocialSecurityData
            socialSecurity={employeeData?.socialSecurity || {}}
          />
        );

      case questionnaireTranslation("benefitsCard.benefits"):
        return <VwlData vwlDetails={employeeData?.vwlDetails || {}} />;

      default:
        return (
          <p>
            Dieser Teil ist nur vom Arbeitgeber auszufüllen, daher gibt es keine
            Mitarbeiter-Daten.
          </p>
        );
    }
  };

  return (
    <div className="mx-auto max-w-4xl rounded-lg pl-6 pr-6 pt-2">
      <h2 className="mb-6 text-xl font-semibold">Mitarbeiterfragebogen</h2>
      {error && <p className="text-red-500">{error}</p>}
      {renderContent()}
    </div>
  );
};

export default EmployeeData;
