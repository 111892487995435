import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { formatDate } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/FormateDateFunction";

export const PrintLimit: React.FC<{
  formData: FormData;
}> = ({ formData }) => {
  return (
    <div>
      <h2 style={{ borderBottom: "2px solid gray" }}>
        {questionnaireTranslation("limitCard.timeLimit")}
      </h2>
      <div className="grid-container">
        <span className="title-fill">
          {questionnaireTranslation("limitCard.limitType")}
        </span>
        <span>
          {" "}
          {getField("contractLimitation.limitType", formData).length > 0 &&
            questionnaireTranslation(
              `limitCard.limitValues.${getField("contractLimitation.limitType", formData)}`,
            )}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("limitCard.dateOfLimit")}
        </span>
        <span>
          {formatDate(getField("contractLimitation.contractEndDate", formData))}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("limitCard.dateSetContract")}
        </span>
        <span>
          {formatDate(
            getField("contractLimitation.contractSignedDate", formData),
          )}
        </span>
      </div>
    </div>
  );
};

