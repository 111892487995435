import React, { useEffect, useRef } from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { CreateEmployeeSchema } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateEmployeesMutation } from "@/api/endpoints/payrollApi";

export interface CreateEmployeeProps {
  onClose: () => void;
}

export const CreateEmployee: React.FC<CreateEmployeeProps> = ({ onClose }) => {
  const firstNameRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);
  const [createEmployees] = useCreateEmployeesMutation();

  async function onSubmit(values: z.infer<typeof CreateEmployeeSchema>) {
    try {
      const result = await createEmployees(
        values
      ).unwrap();
      onClose();
      console.log("Erstellt:", result);
    } catch (error) {
      console.error("Fehler beim Erstellen des Fragebogens:", error);
    }

    if (onClose) {
      onClose();
    }
  }

  const form = useForm({
    resolver: zodResolver(CreateEmployeeSchema),
  });


  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (onClose) {
      onClose();
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-2 grid grid-cols-2 gap-4 gap-y-4 rounded-xl border bg-[#1b1b1b] p-4">
          <FormSimpleText
            form={form}
            name="firstName"
            label={`${payrollTranslation("universal.firstName")}*`}
            type="text"
            autoFocus={true}
          />

          <FormSimpleText
            form={form}
            name="lastName"
            label={`${payrollTranslation("universal.lastName")}*`}
            type="text"
          />

          <FormSimpleText
            form={form}
            name="email"
            label={`${questionnaireTranslation("personalCard.email")}*`}
            type="email"
          />

          <FormSimpleText
            form={form}
            name="personnelNumber"
            label={`${payrollTranslation("universal.personnelNumber")}*`}
            type="text"
          />
          <div className={"col-span-2 w-full flex justify-center"}>
            <Button className={"m-4"} type={"submit"} variant={"secondary"}>
              Mitarbeiter speichern
            </Button>
            <Button
              className={"m-4"}
              type={"button"}
              variant={"destructive"}
              onClick={handleCancel}
            >
              Abbrechen
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};
