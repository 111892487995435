import React from "react";
import { QuestionnaireEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";

export const formatToEuro = (amountInCents: number | undefined) => {
  if (amountInCents === undefined) return "-";
  const amountInEuro = amountInCents / 100;
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(amountInEuro);
};

const TaxData: React.FC<{ taxInformation: QuestionnaireEmployee["taxInformation"] }> = ({ taxInformation }) => {
  return (
    <section className="mb-8 font-light">
      <h3 className="mb-4 border-b-2 border-b-zinc-700 text-base font-semibold">
        {questionnaireTranslation("taxCard.taxes")}
      </h3>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <div className="col-span-1 font-medium">{questionnaireTranslation("taxCard.taxId")}:</div>
        <div className="col-span-1">{taxInformation?.taxId || "-"}</div>

        <div className="col-span-1 font-medium">{questionnaireTranslation("taxCard.taxClass")}:</div>
        <div className="col-span-1">{taxInformation?.taxClass || "-"}</div>

        <div className="col-span-1 font-medium">{questionnaireTranslation("taxCard.factor")}:</div>
        <div className="col-span-1">{taxInformation?.taxFactor || "-"}</div>

        <div className="col-span-1 font-medium">{questionnaireTranslation("taxCard.childAllowance")}:</div>
        <div className="col-span-1">
          {taxInformation?.childAllowance != null ? formatToEuro(taxInformation.childAllowance) : "-"}


        </div>
      </div>
    </section>
  );
};

export default TaxData;
