import React, { useEffect } from "react";
import { isValidIBAN } from "ibantools";
import { formatIBAN } from "@/util/format";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { CountryDropdown } from "@/feature/payroll/forms/questionnaire/components/CountryDropdown";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";
import { useSelector } from "react-redux";
import { RootState } from "@/api/store";
import { SelectEnum } from "@/feature/payroll/MultiStepGeneral";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { PhoneInput } from "@/components/ui/phone-input";

export function PersonalInfo({ form }: QuestionnaireProps) {
  const { watch, setValue } = form;
  const iban = watch("personalDetails.iban");
  const { isInConstruction } = useSelector(
    (state: RootState) => state.questionnaire,
  );

  const insuranceNumber = form.watch("personalDetails.insuranceNumber");

  useEffect(() => {
    if (iban) {
      const ibanWithoutSpaces = iban.replace(/\s+/g, "");

      if (!isValidIBAN(ibanWithoutSpaces)) {
        console.error("Ungültige IBAN");
      }
    }
  }, [iban]);

  const handleIbanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = formatIBAN(rawValue);
    setValue("personalDetails.iban", formattedValue);
  };

  return (
    <div className="mb-2 rounded-xl border bg-[#1b1b1b] p-4">
      <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
        {questionnaireTranslation("personalCard.personalInfo")}
      </h2>
      <div className={"mb-8 grid gap-4 md:grid-cols-2 lg:grid-cols-4"}>
        <FormSimpleText
          form={form}
          name={"personalDetails.birthName"}
          label={payrollTranslation("universal.birthName")}
        />

        <DateInputField
          control={form.control}
          name={"personalDetails.birthDate"}
          labelKey={payrollTranslation("universal.birthDate")}
          required={true}
        />

        <SelectFormField
          name={"personalDetails.gender"}
          control={form.control}
          labelKey={questionnaireTranslation("personalCard.gender")}
          placeholder={payrollTranslation("universal.choseOption")}
          options={[
            {
              label: questionnaireTranslation("personalCard.male"),
              value: "male",
            },
            {
              label: questionnaireTranslation("personalCard.female"),
              value: "female",
            },
            {
              label: questionnaireTranslation("personalCard.diverse"),
              value: "diverse",
            },
          ]}
        />
        <SelectFormField
          name={"personalDetails.disabled"}
          placeholder={payrollTranslation("universal.choseOption")}
          control={form.control}
          labelKey={questionnaireTranslation("personalCard.severelyDisabled")}
          options={[
            {
              label: payrollTranslation("universal.true"),
              value: SelectEnum.YES,
            },
            {
              label: payrollTranslation("universal.false"),
              value: SelectEnum.NO,
            },
          ]}
        />
        <FormField
          control={form.control}
          name="personalDetails.phone"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center">
                <FormLabel className={"mr-2 text-muted-foreground"}>
                  {questionnaireTranslation("personalCard.phone")}
                </FormLabel>
              </div>
              <FormControl>
                <PhoneInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/*<FormSimpleText
          inputClassName=" placeholder:opacity-50"
          form={form}
          name={"personalDetails.phone"}
          label={questionnaireTranslation("personalCard.phone")}
          placeholder={"+491764691478"}
        />*/}
        <FormSimpleText
          hoverInfoTitle={"jajaj"}
          hoverInfoDescription={"kjdkjs"}
          form={form}
          name={"personalDetails.nationality"}
          label={questionnaireTranslation("personalCard.nationality")}
        />
      </div>

      <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
        {questionnaireTranslation("personalCard.addressInfo")}
      </h2>
      <div
        className={
          "mb-8 grid gap-4 md:grid-cols-[8fr_2fr_8fr] lg:grid-cols-[8fr_2fr_8fr]"
        }
      >
        <FormSimpleText
          inputClassName=" placeholder:opacity-50"
          form={form}
          name={"personalDetails.address.street"}
          label={questionnaireTranslation("personalCard.address.street")}
          placeholder={"Musterstraße"}
          required={true}
        />
        <FormSimpleText
          inputClassName=" placeholder:opacity-50"
          form={form}
          name={"personalDetails.address.houseNumber"}
          label={questionnaireTranslation("personalCard.address.houseNumber")}
          placeholder={"1A"}
          required={true}
        />

        <FormSimpleText
          inputClassName=" placeholder:opacity-50"
          form={form}
          name={"personalDetails.address.addressAdditional"}
          placeholder={"Etage, Zimmernummer, etc."}
          label={questionnaireTranslation(
            "personalCard.address.addressAddition",
          )}
        />

        <FormSimpleText
          inputClassName=" placeholder:opacity-50"
          form={form}
          name={"personalDetails.address.city"}
          label={questionnaireTranslation("personalCard.address.city")}
          placeholder={"Musterstadt"}
          required={true}
        />
        <FormSimpleText
          inputClassName=" placeholder:opacity-50"
          form={form}
          name={"personalDetails.address.postalCode"}
          label={questionnaireTranslation("personalCard.address.postalCode")}
          placeholder={"12345"}
          required={true}
        />

        <CountryDropdown form={form} />
      </div>

      <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
        {questionnaireTranslation("personalCard.financialInfo")}
      </h2>
      <div className={"mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-4"}>
        <FormSimpleText
          hoverInfoTitle={questionnaireTranslation(
            "personalCard.insuranceNumber",
          )}
          hoverInfoDescription={questionnaireTranslation(
            "personalCard.infoCard.infoInsuranceNumber",
          )}
          form={form}
          name={"personalDetails.insuranceNumber"}
          label={questionnaireTranslation("personalCard.insuranceNumber")}
        />

        {isInConstruction ? (
          <FormSimpleText
            hoverInfoTitle={questionnaireTranslation(
              "personalCard.employeeNumber",
            )}
            hoverInfoDescription={questionnaireTranslation(
              "personalCard.infoCard.infoConstruction",
            )}
            form={form}
            name={"personalDetails.employeeNumberSocialFund"}
            label={questionnaireTranslation("personalCard.employeeNumber")}
          />
        ) : null}

        <FormSimpleText
          inputClassName=" placeholder:opacity-50"
          form={form}
          name={"personalDetails.iban"}
          label={payrollTranslation("universal.iban")}
          onChange={handleIbanChange}
          placeholder={"DE00 0000 0000 0000 0000 00"}
        />
        <FormSimpleText
          inputClassName=" placeholder:opacity-50"
          form={form}
          name={"personalDetails.bic"}
          label={payrollTranslation("universal.bic")}
          placeholder={"WPGGATTLXXX"}
        />
      </div>
      <div
        className={
          "grid gap-4 md:col-span-2 md:grid-cols-2 lg:col-span-4 lg:grid-cols-4"
        }
      >
        {!insuranceNumber && (
          <>
            <FormSimpleText
              hoverInfoTitle={questionnaireTranslation(
                "personalCard.birthPlace",
              )}
              hoverInfoDescription={questionnaireTranslation(
                "personalCard.infoCard.onMissingInsuranceNumber",
              )}
              form={form}
              name={"personalDetails.birthPlace"}
              label={questionnaireTranslation("personalCard.birthPlace")}
            />
            <FormSimpleText
              hoverInfoTitle={questionnaireTranslation(
                "personalCard.birthCountry",
              )}
              hoverInfoDescription={questionnaireTranslation(
                "personalCard.infoCard.onMissingInsuranceNumber",
              )}
              form={form}
              name={"personalDetails.birthCountry"}
              label={questionnaireTranslation("personalCard.birthCountry")}
            />
          </>
        )}
      </div>
      {/*<div className={"col-span-4"}>
        <InfoCard
          infoText={
            <>
              Hier kannst du deine persönlichen Details angeben. Dein
              Geburtsdatum und die Kontaktinformationen sind hierbei besonders
              wichtig.
            </>
          }
        />
      </div>*/}
    </div>
  );
}
