import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { formatDate } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/FormateDateFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { formatToEuro } from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/TaxData";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { EmploymentTypeEnum } from "@/feature/payroll/MultiStepGeneral";

export const PrintPayment: React.FC<{
  formData: FormData;
  employee?: Employee;
}> = ({ formData, employee }) => {
  const { employmentType } = useEmploymentType(employee!);
  const YEARS = ["yearOne", "yearTwo", "yearThree"] as const;

  return (
    <div>
      {employmentType === EmploymentTypeEnum.TRAINEE ? (
        <div>
          <h2 style={{ borderBottom: "2px solid gray" }}>
            {questionnaireTranslation("paymentCard.traineePayment")}
          </h2>
          <div className={"grid-container-five"}>
            <div></div>
            <span className="title-fill">
              {questionnaireTranslation("paymentCard.sum")}
            </span>
            <span className="title-fill">
              {payrollTranslation("universal.validFrom")}
            </span>
            <span className="title-fill">
              {questionnaireTranslation("paymentCard.hourlyWage")}
            </span>
            <span className="title-fill">
              {payrollTranslation("universal.validFrom")}
            </span>
          </div>
          {YEARS.map((year) => (
            <div key={year} className={"grid-container-five"}>
              <span className="title-fill">
                {questionnaireTranslation(`paymentCard.${year}`)}
              </span>
              <span>
                {getField("payment.sum", formData).length > 0 &&
                  formatToEuro(getField(`payment.${year}.sum`, formData))}
              </span>
              <span>
                {formatDate(getField(`payment.${year}.dateSum`, formData))}
              </span>
              <span>
                {" "}
                {getField("payment.hourlyWage", formData).length > 0 &&
                  formatToEuro(
                    getField(`payment.${year}.hourlyWage`, formData),
                  )}
              </span>
              <span>
                {formatDate(getField(`payment.${year}.dateWage`, formData))}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <h2 style={{ borderBottom: "2px solid gray" }}>
            {questionnaireTranslation("paymentCard.payment")}
          </h2>
          <div className="grid-container">
            <span className="title-fill">
              {questionnaireTranslation("paymentCard.paymentDescription")}
            </span>
            <span style={{ gridColumn: "span 3" }}>
              {getField("payment.description", formData)}
            </span>

            <span className="title-fill">
              {questionnaireTranslation("paymentCard.sum")}
            </span>
            <span>{formatToEuro(getField("payment.sum", formData))}</span>

            <span className="title-fill">
              {payrollTranslation("universal.validFrom")}
            </span>
            <span>{formatDate(getField("payment.dateSum", formData))}</span>

            <span className="title-fill">
              {questionnaireTranslation("paymentCard.hourlyWage")}
            </span>
            <span>
              {formatToEuro(getField("payment.hourlyWage", formData))}
            </span>

            <span className="title-fill">
              {payrollTranslation("universal.validFrom")}
            </span>
            <span>{formatDate(getField("payment.dateWage", formData))}</span>
          </div>
        </div>
      )}
    </div>
  );
};
