import React from "react";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";
import {
  EmploymentTypeEnum,
  UserTypeEnum,
} from "@/feature/payroll/MultiStepGeneral";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { TaxesMiniJobber } from "@/feature/payroll/forms/questionnaire/components/TaxesMiniJobber";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";

export function Taxes({ form, userType, employee }: QuestionnaireProps) {
  const selectedTaxClass = form.getValues("taxInformation.taxClass");
  const { employmentType } = useEmploymentType(employee!);
  return (
    <div className="mb-2 rounded-xl border bg-[#1b1b1b] p-4">
      <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
        {questionnaireTranslation("taxCard.taxData")}
      </h2>
      {userType === UserTypeEnum.EMPLOYEE ? (
        <div className={"grid gap-4 md:grid-cols-2 lg:grid-cols-4"}>
          <FormSimpleText
            form={form}
            name={"taxInformation.taxId"}
            label={questionnaireTranslation("taxCard.taxId")}
          />

          <SelectFormField
            name={"taxInformation.taxClass"}
            control={form.control}
            labelKey={questionnaireTranslation("taxCard.taxClass")}
            defaultValue={"taxClass1"}
            options={[
              {
                label: `${questionnaireTranslation("taxCard.taxClass")} 1`,
                value: "1",
              },
              {
                label: `${questionnaireTranslation("taxCard.taxClass")} 2`,
                value: "2",
              },
              {
                label: `${questionnaireTranslation("taxCard.taxClass")} 3`,
                value: "3",
              },
              {
                label: `${questionnaireTranslation("taxCard.taxClass")} 4`,
                value: "4",
              },
              {
                label: `${questionnaireTranslation("taxCard.taxClass")} 5`,
                value: "5",
              },
              {
                label: `${questionnaireTranslation("taxCard.taxClass")} 6`,
                value: "6",
              },
            ]}
          />

          {selectedTaxClass === "4" ? (
            <FormSimpleNumber
              hoverInfoTitle={questionnaireTranslation("taxCard.factor")}
              hoverInfoDescription={questionnaireTranslation(
                "taxCard.infoCard.infoTaxFactor",
              )}
              form={form}
              name={"taxInformation.taxFactor"}
              label={questionnaireTranslation("taxCard.factor")}
            />
          ) : null}

          <FormSimpleMoney
            hoverInfoTitle={questionnaireTranslation("taxCard.childAllowance")}
            hoverInfoDescription={questionnaireTranslation(
              "taxCard.infoCard.infoChildAllowance",
            )}
            placeholder={"9.312"}
            form={form}
            name={"taxInformation.childAllowance"}
            label={questionnaireTranslation("taxCard.childAllowance")}
          />
          {/*<div className={"md:col-span-2 lg:col-span-4"}>
            <InfoCard
              infoText={
                <>
                  Hier kannst du Angaben zu Steuerinformationen machen. <br />
                  Falls du die Lohnsteuerklasse 4 hast, gib bitte den{" "}
                  <strong>Lohnsteuerfaktor</strong> mit an. Diesen findest du in
                  der elektronischen Lohnsteuerkarte ELSTaM oder auf der
                  jährlichen Lohnsteuerbescheinigung.
                </>
              }
            />
          </div>*/}
        </div>
      ) : (
        employmentType === EmploymentTypeEnum.MINIJOB && (
          <TaxesMiniJobber form={form} />
        )
      )}
    </div>
  );
}
