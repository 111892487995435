import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { FieldPath, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { HoverInfo } from "@/feature/settings/UserSettings/form/info/hoverInfo";

interface Options {
  label: string;
  value: string;
}

interface RadioButtonGroupProps<T extends z.ZodTypeAny> {
  name: FieldPath<z.infer<T>>;
  control: UseFormReturn<z.infer<T>>["control"];
  labelKey?: string;
  options: Options[];
  hidden?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  columnLayout?: boolean,
  hoverInfoTitle?: string,
  hoverInfoDescription?: string,


}

export const RadioFormField = <T extends z.ZodTypeAny>({
  control,
  name,
  hidden = false,
  required = false,
  onChange,
  labelKey,
  options,
  defaultValue,
  columnLayout=false,
  hoverInfoTitle,
  hoverInfoDescription


}: RadioButtonGroupProps<T>) => {
  if (hidden) return null;
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="mb-2 ml-1 w-full space-y-3">
          <div className="flex items-center">
            {labelKey && (
              <FormLabel className={"mr-2 text-muted-foreground"}>
                {labelKey} {required && <span> *</span>}
              </FormLabel>
            )}
            {hoverInfoTitle && hoverInfoDescription && (
              <HoverInfo
                title={hoverInfoTitle}
                description={hoverInfoDescription}
                align="start"
              />
            )}
          </div>

          <FormControl>
            <RadioGroup
              value={field.value}
              onValueChange={(value) => {
                field.onChange(value);
                if (onChange) {
                  onChange(value);
                }
              }}
              defaultValue={defaultValue || field.value}
              className={columnLayout
                ? "grid grid-cols-2 gap-2"
                : "flex-col-2 flex space-x-2 mb-2"}
            >
              {options.map((option, index) => (
                <FormItem
                  key={index}
                  className="flex items-center space-x-3 space-y-0  mb-2"
                >
                  <FormControl>
                    <RadioGroupItem
                      value={option.value}
                    />
                  </FormControl>
                  <FormLabel className="font-light text-muted-foreground">
                    {option.label}
                  </FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default RadioFormField;
