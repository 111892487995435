import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"; // oder deine entsprechende Select-Komponente
import { FieldPath, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { HoverInfo } from "@/feature/settings/UserSettings/form/info/hoverInfo";

interface Options {
  label: string;
  value: string;
}

interface SelectFieldProps<T extends z.ZodTypeAny> {
  name: FieldPath<z.infer<T>>;
  control: UseFormReturn<z.infer<T>>["control"];
  labelKey?: string;
  options: Options[];
  disabled?: boolean;
  hidden?: boolean;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  onChange?: (value: string) => void;
  hoverInfoTitle?: string;
  hoverInfoDescription?: string;
}

export const SelectFormField = <T extends z.ZodTypeAny>({
                                                          control,
                                                          name,
                                                          disabled = false,
                                                          hidden = false,
                                                          required = false,
                                                          labelKey,
                                                          options,
                                                          defaultValue,
                                                          onChange,
  hoverInfoTitle,
  hoverInfoDescription,
                                                        }: SelectFieldProps<T>) => {
  if (hidden) return null;

  return (
    <div>
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <div className="flex items-center">
              <FormLabel className={"text-muted-foreground mr-2"}>
                {labelKey} {required && <span> *</span>}
              </FormLabel>
              {hoverInfoTitle && hoverInfoDescription && (
                <HoverInfo
                  title={hoverInfoTitle}
                  description={hoverInfoDescription}
                  align="start"
                />
              )}
            </div>
            <FormControl>
              <Select
                onValueChange={(value) => {
                  field.onChange(value);
                  if (onChange) {
                    onChange(value);
                  }
                }}
                defaultValue={field.value}
                disabled={disabled}
              >
                <SelectTrigger className="w-full max-w-full overflow-hidden">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent className="max-w-full overflow-hidden">
                  <SelectGroup>
                    {options.map((option, index) => (
                      <SelectItem
                        className="overflow-hidden text-ellipsis whitespace-nowrap"
                        key={index}
                        value={option.value}
                      >
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default SelectFormField;
