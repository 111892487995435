import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import MultiStepCard, {
  EmploymentTypeEnum,
  UserTypeEnum,
} from "@/feature/payroll/MultiStepGeneral";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { useCompleteQuestionnaireMutation } from "@/api/endpoints/payrollApi";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { defaultValuesQuestionnaire } from "@/feature/payroll/forms/questionnaire/schema/DefaultValuesQuestionnaire";
import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";
import { useStepManagement } from "@/feature/payroll/forms/questionnaire/hooks/UseStepManagement";
import { DialogOnSubmit } from "@/feature/payroll/forms/questionnaire/functions/DialogOnSave";

const EmployerForm: React.FC<{
  employment?: EmploymentTypeEnum;
  employeeId?: string;
  userType?: UserTypeEnum;
  employee?: Employee;
  setCurrentTitle?: (title: string) => void;
}> = ({ userType, setCurrentTitle, employment, employee, employeeId }) => {
  const form = useForm<z.infer<typeof FormSchemaEmployer>>({
    resolver: zodResolver(FormSchemaEmployer),
    defaultValues: defaultValuesQuestionnaire,
  });

  const { currentStep, totalSteps, handleStepChange, handleTotalStepsChange } =
    useStepManagement();
  const [formData, setFormData] = useState<z.infer<
    typeof FormSchemaEmployer
  > | null>(null);

  const [completeQuestionnaire] = useCompleteQuestionnaireMutation();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const subscription = form.watch((values) => {
      setFormData(values as z.infer<typeof FormSchemaEmployer>);
    });
    return () => subscription.unsubscribe();
  }, [form]);

  async function onSubmit(value: z.infer<typeof FormSchemaEmployer>) {
    try {
      const response = await completeQuestionnaire({
        questionnaire: value,
        employeeId: employee!.id!,
      }).unwrap();
      console.log("Successfully submitted:", response);

      setOpenDialog(true);
    } catch (error) {
      console.error(
        "Error while submitting the employee questionnaire:",
        error,
      );
    }
  }

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div>
            <DialogOnSubmit
              open={openDialog}
              onClose={() => setOpenDialog(false)}
            />
            <MultiStepCard
              form={form}
              userType={UserTypeEnum.EMPLOYER}
              employment={employment}
              setCurrentTitle={setCurrentTitle}
              employee={employee}
              onStepChange={handleStepChange}
              onTotalStepsChange={handleTotalStepsChange}
            />
          </div>

          {currentStep === totalSteps - 1 && (
            <div className={"flex justify-center"}>
              <Button type={"submit"}>Daten speichern</Button>
            </div>
          )}
        </form>
      </Form>
    </>
  );
};

export default EmployerForm;
