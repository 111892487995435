import React, { useEffect, useState } from "react";
import { z } from "zod";
import { PrintBenefits } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintBenefits";
import { PrintTaxable } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintTaxable";
import { PrintSocialSec } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintSocialSec";
import { PrintTaxes } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintTaxes";
import { PrintLimit } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintLimit";
import { PrintKids } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintKids";
import { PrintEmployment } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintEmployment";
import { PrintPersonalDetails } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintPersonalDetails";
import { PrintPayment } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintPayment";
import { FormSchemaComplete } from "@/feature/payroll/forms/questionnaire/schema/QuestionnaireSchema";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import {
  Employee,
  EmployeeEvent,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { defaultValuesQuestionnaire } from "@/feature/payroll/forms/questionnaire/schema/DefaultValuesQuestionnaire";
import { getEmploymentTypeLabel } from "@/feature/payroll/forms/questionnaire/functions/EmploymentTypeLabel";

export type FormDataQuestionnaire = z.infer<typeof FormSchemaComplete>;

const PrintForm: React.FC<{ employeeId?: string; employee?: Employee }> = ({
  employee,
}) => {
  const [formData, setFormData] = useState<typeof defaultValuesQuestionnaire>(
    defaultValuesQuestionnaire,
  );

  const { data, error: apiError } = useGetEmployeeDataQuery<any>(
    { employeeId: employee?.id || "" },
    { skip: !employee?.id },
  );

  const sendQuestionnaire = data?.events?.find(
    (event: EmployeeEvent) => event?.sendQuestionnaireEvent,
  )?.sendQuestionnaireEvent;

  const employmentType = sendQuestionnaire?.employment_type;
  const employmentTypeLabel = getEmploymentTypeLabel(employmentType);

  useEffect(() => {
    const latestEmployeeEvent = data?.events
      ?.filter((event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent)
      .sort(
        (
          a: { createdAt: string | number | Date },
          b: { createdAt: string | number | Date },
        ) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
      )
      .shift();

    const latestEmployerEvent = data?.events
      ?.filter(
        (event: EmployeeEvent) => event?.employerCompleteQuestionnaireEvent,
      )
      .sort(
        (
          a: { createdAt: string | number | Date },
          b: { createdAt: string | number | Date },
        ) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
      )
      .shift();

    const employerComplete =
      latestEmployerEvent?.employerCompleteQuestionnaireEvent;
    const employeeFill = latestEmployeeEvent?.employeeFillQuestionnaireEvent;

    setFormData((prevState) => {
      return {
        ...prevState,
        personalDetails: {
          ...prevState.personalDetails,
          birthName: employeeFill?.personalDetails.birthName,
          birthDate: employeeFill?.personalDetails.birthDate,
          phone: employeeFill?.personalDetails.phone,
          address: {
            street: employeeFill?.personalDetails.address.street,
            houseNumber: employeeFill?.personalDetails.address.houseNumber,
            city: employeeFill?.personalDetails.address.houseNumber,
            postalCode: employeeFill?.personalDetails.address.postalCode,
            country: employeeFill?.personalDetails.address.country,
            addressAdditional:
              employeeFill?.personalDetails.address.addressAdditional,
          },
          insuranceNumber: employeeFill?.personalDetails.insuranceNumber,
          birthPlace: employeeFill?.personalDetails.birthPlace,
          birthCountry: employeeFill?.personalDetails.birthCountry,
          gender: employeeFill?.personalDetails.gender,
          nationality: employeeFill?.personalDetails.nationality,
          disabled: employeeFill?.personalDetails.disabled,
          employeeNumberSocialFund:
            employeeFill?.personalDetails.employeeNumberSocialFund,
          iban: employeeFill?.personalDetails.iban,
          bic: employeeFill?.personalDetails.bic,
        },

        children: [...(employeeFill?.children || [])],

        employmentDetails: {
          ...prevState.employmentDetails,
          highestSchoolDegree:
            employeeFill?.employmentDetails.highestSchoolDegree,
          highestProfessionalEducation:
            employeeFill?.employmentDetails.highestProfessionalEducation,
          jobTitle: employeeFill?.employmentDetails.jobTitle,
          constructionEmployment:
            employeeFill?.employmentDetails.constructionEmployment,
          minorEmployment: employeeFill?.employmentDetails.minorEmployment,
          additionalEmployment:
            employeeFill?.employmentDetails.additionalEmployment,
          trainingStart: employerComplete?.employmentDetails.trainingStart,
          trainingEnd: employerComplete?.employmentDetails.trainingEnd,
          entryDate: employerComplete?.employmentDetails.entryDate,
          firstEntryDate: employerComplete?.employmentDetails.firstEntryDate,
          employmentCompany:
            employerComplete?.employmentDetails.employmentCompany,

          jobActivity: employerComplete?.employmentDetails.jobActivity,
          probation: employerComplete?.employmentDetails.probation,
          probationDuration:
            employerComplete?.employmentDetails.probationDuration,
          weeklyWorkHours: employerComplete?.employmentDetails.weeklyWorkHours,
          vacationEntitlement:
            employerComplete?.employmentDetails.vacationEntitlement,
          contractForm: employerComplete?.employmentDetails.contractForm,
          hourDistribution: {
            monday: employerComplete?.employmentDetails.hourDistribution.monday,
            tuesday:
              employerComplete?.employmentDetails.hourDistribution.tuesday,
            wednesday:
              employerComplete?.employmentDetails.hourDistribution.wednesday,
            thursday:
              employerComplete?.employmentDetails.hourDistribution.thursday,
            friday: employerComplete?.employmentDetails.hourDistribution.friday,
            saturday:
              employerComplete?.employmentDetails.hourDistribution.saturday,
            sunday: employerComplete?.employmentDetails.hourDistribution.sunday,
          },
          costCenter: employerComplete?.employmentDetails.costCenter,
          departmentNumber:
            employerComplete?.employmentDetails.departmentNumber,
        },

        contractLimitation: {
          ...prevState.contractLimitation,
          limitType: employerComplete?.contractLimitation.limitType,
          contractEndDate: employerComplete?.contractLimitation.contractEndDate,
          contractSignedDate:
            employerComplete?.contractLimitation.contractSignedDate,
        },

        payment: {
          ...prevState.payment,
          description: employerComplete?.payment?.description,
          sum: employerComplete?.payment?.sum,
          dateSum: employerComplete?.payment?.dateSum,
          hourlyWage: employerComplete?.payment?.hourlyWage,
          dateWage: employerComplete?.payment?.dateWage,
          traineePayment: {
            yearOne: {
              sum: employerComplete?.traineePayment?.yearOne?.sum,
              dateSum: employerComplete?.traineePayment?.yearOne?.dateSum,
              hourlyWage: employerComplete?.traineePayment?.yearOne?.hourlyWage,
              dateWage: employerComplete?.traineePayment?.yearOne?.dateWage,
            },
            yearTwo: {
              sum: employerComplete?.traineePayment?.yearTwo?.sum,
              dateSum: employerComplete?.traineePayment?.yearTwo?.dateSum,
              hourlyWage: employerComplete?.traineePayment?.yearTwo?.hourlyWage,
              dateWage: employerComplete?.traineePayment?.yearTwo?.dateWage,
            },
            yearThree: {
              sum: employerComplete?.traineePayment?.yearThree?.sum,
              dateSum: employerComplete?.traineePayment?.yearThree?.dateSum,
              hourlyWage:
                employerComplete?.traineePayment?.yearThree?.hourlyWage,
              dateWage: employerComplete?.traineePayment?.yearThree?.dateWage,
            },
          },
        },

        taxInformation: {
          ...prevState.taxInformation,
          miniJobberTax: {
            rollOff: employerComplete?.taxInformation.miniJobberTax.rollOff,
            generalize:
              employerComplete?.taxInformation.miniJobberTax.generalize,
          },
          taxId: employeeFill?.taxInformation.taxId,
          taxClass: employeeFill?.taxInformation.taxClass,
          taxFactor: employeeFill?.taxInformation.taxFactor,
          childAllowance: employeeFill?.taxInformation.childAllowance,
        },

        vwlDetails: [...(employerComplete?.vwlDetails || [])],

        taxableEmployment: {
          ...prevState.taxableEmployment,
          previousEmployment: [
            ...(employeeFill?.taxableEmployment?.previousEmployment || []),
          ],
          miniJobberLimit: {
            moneyLimit:
              employeeFill?.taxableEmployment?.miniJobberLimit?.moneyLimit ||
              prevState.taxableEmployment?.miniJobberLimit?.moneyLimit,
          },
        },

        socialSecurity: {
          ...prevState.socialSecurity,
          statutoryHealthInsurance:
            employeeFill?.socialSecurity.statutoryHealthInsurance,
          currentHealthInsurance:
            employeeFill?.socialSecurity.currentHealthInsurance,
          parentProperty: employeeFill?.socialSecurity.parentProperty,
          miniJobberSecurity: {
            freePensionInsurance:
              employeeFill?.socialSecurity.miniJobberSecurity
                .freePensionInsurance,
          },
          insuranceKey: employerComplete?.socialSecurity.insuranceKey,

          deuevStatus: employerComplete?.socialSecurity.deuevStatus,
        },
      };
    });
  }, [data]);

  const currentDateQuestionnaire = new Date().toLocaleDateString();

  return (
    <>
      <div style={{ margin: "12px", padding: "12px" }} className="page-break">
        <div
          className="grid-container-sign"
          style={{ borderBottom: "4px solid gray" }}
        >
          <h1 className="flex" style={{ whiteSpace: "nowrap" }}>
            Personalfragebogen: {employmentTypeLabel}
          </h1>
          <p style={{ justifySelf: "end", fontSize: "medium" }}>
            {currentDateQuestionnaire}
          </p>
        </div>

        <div
          className="grid-container-heading"
          style={{ marginTop: "30px", marginBottom: "30px" }}
        >
          <span className="heading-span">Name des Mitarbeiters</span>
          <span className="title-fill heading-span">
            {employee?.firstName && employee?.lastName
              ? `${employee?.firstName} ${employee?.lastName}`
              : ""}
          </span>
          <span className="heading-span" style={{ marginRight: "4px" }}>
            E-Mail
          </span>
          <span className="title-fill heading-span">{employee?.email}</span>
          <span className="heading-span" style={{ marginRight: "4px" }}>
            Personalnummer
          </span>
          <span className="title-fill heading-span">
            {employee?.personnelNumber}
          </span>
        </div>

        <PrintPersonalDetails formData={formData} />
        <PrintEmployment formData={formData} />
        <PrintKids formData={formData} />
        <PrintLimit formData={formData} />
        <PrintPayment formData={formData} employee={employee} />
        <PrintTaxes formData={formData} employee={employee} />
        <PrintSocialSec formData={formData} employee={employee} />
        <PrintBenefits formData={formData} />
        <PrintTaxable formData={formData} employee={employee} />

        <div className="page-break" style={{ margin: "12px", padding: "12px" }}>
          <div className="grid-container-sign" style={{ marginTop: "75px" }}>
            <div style={{ borderTop: "1px solid gray" }}>
              <h3>Ort, Datum</h3>
            </div>
            <div style={{ borderTop: "1px solid gray" }}>
              <h3>Unterschrift Arbeitnehmer</h3>
            </div>

            <div></div>

            <div>
              <img
                src={
                  "https://cdn.pixabay.com/photo/2014/11/09/08/06/signature-523237_960_720.jpg"
                }
                alt="Unterschrift Arbeitnehmer"
                style={{ width: "50%", height: "auto", border: "none" }}
              />
              <div
                style={{
                  borderTop: "1px solid gray",
                  gridRow: 2,
                  gridColumn: 2,
                }}
              >
                <h3>Unterschrift Arbeitgeber</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintForm;
