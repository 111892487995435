import React from "react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronsUpDown } from "lucide-react";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";

interface MainButtonsProps {
  onButtonClick: (title: string) => void;
  onEdit: () => void;
}

const MainButtons: React.FC<MainButtonsProps> = ({ onButtonClick, onEdit }) => {
  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            {payrollTranslation("dropdownMenu.personnelDocuments")}&nbsp;
            <ChevronsUpDown size={"16px"} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              {payrollTranslation("questionnaire")}
            </DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              <DropdownMenuItem onClick={() => onButtonClick("questionnaire")}>
                {payrollTranslation("dropdownMenu.createQuestionnaire")}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={onEdit}>
                {payrollTranslation("dropdownMenu.editQuestionnaire")}
              </DropdownMenuItem>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
          <DropdownMenuItem onClick={() => onButtonClick("changes")}>
            {payrollTranslation("changes")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onButtonClick("absence")}>
            {payrollTranslation("absence")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onButtonClick("termination")}>
            {payrollTranslation("termination")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onButtonClick("documents")}>
            {payrollTranslation("documents")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>

  );
};

export default MainButtons;
