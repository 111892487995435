import React from "react";
import { useFieldArray } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { payrollTranslation, questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";
import { Plus, X } from "lucide-react";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";

export function Kids({ form }: QuestionnaireProps) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "children",
  });

  return (
    <div className="gap-4 rounded-xl border bg-[#1b1b1b] p-4">
      {}
      <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
        {questionnaireTranslation("childCard.kidsDataTitle")}
      </h2>
      {fields.map((field, index) => (
        <div key={field.id} className="mb-4 flex gap-4">
          <div className="grid flex-grow gap-4 md:grid-cols-3 lg:grid-cols-3">
            <FormSimpleText
              form={form}
              name={`children.${index}.firstName`}
              label={payrollTranslation("universal.childFirstName")}
            />

            <FormSimpleText
              form={form}
              name={`children.${index}.lastName`}
              label={payrollTranslation("universal.childLastName")}
            />
            <DateInputField
              control={form.control}
              name={`children.${index}.birthDate`}
              labelKey={payrollTranslation("universal.birthDate")}
            />
          </div>
          <div className="mb-0.5 flex items-end justify-center">
            <Button
              type="button"
              variant="secondary"
              size="icon"
              onClick={() => remove(index)}
            >
              <X />
            </Button>
          </div>
        </div>
      ))}

      <Button
        type="button"
        variant={"secondary"}
        size={"icon"}
        onClick={() => append({ firstName: "", lastName: "", birthDate: "" })}
      >
        <Plus />
      </Button>

      {/*<div className={"col-span-4 mt-4"}>
        <InfoCard
          infoText={<>Falls du Kinder hast, kannst du diese hier eintragen.</>}
        />
      </div>*/}
    </div>
  );
}
