// src/api/store/slices/userSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Definiere den Zustand des Slices
interface FormState {
  hasChildren: boolean;
  hasVwl: boolean;
  hasTaxableEmployment: boolean;
  isInConstruction: boolean;
}

// Der initiale Zustand
const initialState: FormState = {
  hasChildren: false,
  hasVwl: false,
  hasTaxableEmployment: false,
  isInConstruction: false,
};


const formSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    setHasChildren: (state, action: PayloadAction<boolean>) => {
      state.hasChildren = action.payload;
    },
    setHasVwl: (state, action: PayloadAction<boolean>) => {
      state.hasVwl = action.payload;
    },
    setHasTaxableEmployment: (state, action: PayloadAction<boolean>) => {
      state.hasTaxableEmployment = action.payload;
    },
    setIsInConstruction: (state, action: PayloadAction<boolean>) => {
      state.isInConstruction = action.payload;
    }
  },
});

export const { setHasChildren, setHasVwl, setHasTaxableEmployment, setIsInConstruction } = formSlice.actions;
export default formSlice.reducer;
