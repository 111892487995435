import { z } from "zod";
import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { EmploymentTypeEnum } from "@/feature/payroll/MultiStepGeneral";

export const EmailSchema = z.object({
  createdAt: z.string().optional(),
  email: z.string().optional(),
  employment_type: z.nativeEnum(EmploymentTypeEnum).optional(),
});

export const CreateEmployeeSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  personnelNumber: z.string().optional(),
  createdAt: z.string().optional(),
})



export const EmployeeEventSchema = z.object({
  id: z.string().optional(),
  createdAt: z.string().optional(),
  createEmployeeEvent: CreateEmployeeSchema.optional(),
  employerCompleteQuestionnaireEvent: FormSchemaEmployer.optional(),
  employeeFillQuestionnaireEvent: FormSchemaEmployee.optional(),
  sendQuestionnaireEvent: EmailSchema.optional(),
})



export type EmployeeEvent = z.infer<typeof EmployeeEventSchema>

export const EmployeeSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  personnelNumber: z.string(),
  token: z.string(),
  employmentType: z.string(),
  status:z.string(),
  events: z.array(
    EmployeeEventSchema,
  ),
});




export const TokenSchema = z.object({
  token: z.string().optional(),
  id: EmployeeSchema.shape.id,
  validUntil: z.date().optional(),
});

export type CreateEmployee = z.infer<typeof CreateEmployeeSchema>;
export type EmployeeToken = z.infer<typeof TokenSchema>;
export type Employee = z.infer<typeof EmployeeSchema>