// src/components/EventName.tsx
import React from "react";
import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { getEmploymentTypeLabel } from "@/feature/payroll/forms/questionnaire/functions/EmploymentTypeLabel";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";

interface EventNameProps {
  event: EmployeeEvent;
}

const EventName = ({ event }: EventNameProps) => {
  const employmentType = event.sendQuestionnaireEvent?.employment_type;
  const employmentTypeLabel = employmentType
    ? getEmploymentTypeLabel(employmentType)
    : "Unbekannt";


  switch (true) {
    case !!event.sendQuestionnaireEvent:
      return (
        <h3 className="text-sm font-medium">
          {employmentTypeLabel} {payrollTranslation("timelineComponent.mailSent")}
        </h3>
      );
    case !!event.createEmployeeEvent:
      return <h3 className="text-sm font-medium">{payrollTranslation("timelineComponent.employeeCreated")}</h3>;
    case !!event.employeeFillQuestionnaireEvent:
      return <h3 className="text-sm font-medium">{payrollTranslation("timelineComponent.questionnaireFilled")}</h3>;
    case !!event.employerCompleteQuestionnaireEvent:
      return <h3 className="text-sm font-medium">{payrollTranslation("timelineComponent.questionnaireCompleted")}</h3>;
    default:
      return <h3 className="text-sm font-medium">{payrollTranslation("timelineComponent.unknown")}</h3>;
  }
};

export default EventName;
