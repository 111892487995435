import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";

const SocialSecurityData: React.FC<{
  socialSecurity: QuestionnaireEmployee["socialSecurity"];
}> = ({ socialSecurity }) => {
  return (
    <section className="mb-8 font-light">
      <h3 className="mb-4 border-b-2 border-b-zinc-700 text-base font-semibold">
        {questionnaireTranslation("socialSecurityCard.socialSecurity")}:
      </h3>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <div className="font-mediumd col-span-1">
          {questionnaireTranslation(
            "socialSecurityCard.statutoryHealthInsurance",
          )}
          :
        </div>
        <div className="col-span-1">
          {socialSecurity?.statutoryHealthInsurance &&
          socialSecurity?.statutoryHealthInsurance?.length > 0
            ? questionnaireTranslation(
                `socialSecurityCard.insuranceNames.${socialSecurity?.statutoryHealthInsurance}`,
              )
            : "-"}
        </div>
        <div className="col-span-1 font-medium">
          {" "}
          {questionnaireTranslation(
            "socialSecurityCard.currentHealthInsurance",
          )}
          :
        </div>
        <div className="col-span-1">
          {socialSecurity?.currentHealthInsurance &&
          socialSecurity?.currentHealthInsurance?.length > 0
            ? questionnaireTranslation(
                `socialSecurityCard.insuranceNames.${socialSecurity?.currentHealthInsurance}`,
              )
            : "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("socialSecurityCard.parentProperty")}:
        </div>
        <div className="col-span-1">
          {socialSecurity?.parentProperty &&
          socialSecurity?.parentProperty?.length > 0
            ? payrollTranslation(`universal.${socialSecurity?.parentProperty}`)
            : "-"}
        </div>
      </div>
    </section>
  );
};

export default SocialSecurityData;
