"use client";

import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

// Define the type for chart data
interface Data {
  month: string
  desktop: number
}

// Updated ChartConfigType with index signature
interface ChartConfigType {
  [key: string]: {
    label: string
    color: string
  }
}

interface DateRange {
  from: Date
  to: Date
}

interface NewAreaChartProps {
  title: string
  data: Data[]
  dateRange: DateRange // Adding dateRange prop
}

// Static chart config
const chartConfig: ChartConfigType = {
  xAchse: {
    label: "x-achse",
    color: "hsl(var(--chart-1))",
  },
}

export function NewAreaChart({ title, data, dateRange }: NewAreaChartProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>
          Zeitraum: {dateRange.from.toLocaleDateString("de-DE")} bis {dateRange.to.toLocaleDateString("de-DE")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <AreaChart
            accessibilityLayer
            data={data} // Pass the data prop here
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
                dataKey="date" // Match this to your data structure
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value: string) => {
                    const date = new Date(value)
                    return date.toLocaleDateString("de-DE") // Format date in German
                }}
            />


            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent 
              indicator="line"
    
              />}
            />
            <Area
              dataKey="value"
              name="Betrag"
              type="natural"
              fill="var(--color-xAchse)"
              fillOpacity={0.4}
              stroke="var(--color-xAchse)"
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
      <CardFooter>
        <div className="flex w-full items-start gap-2 text-sm">
          <div className="grid gap-2">
            <div className="flex items-center gap-2 font-medium leading-none">
              {/* Trending up by 5.2% this month <TrendingUp className="h-4 w-4" /> */}
            </div>
            <div className="flex text-xs items-center gap-2 leading-none text-muted-foreground">
              {/* {dateRange.from.toLocaleDateString("de-DE")} - {dateRange.to.toLocaleDateString("de-DE")} */}
              Das Diagramm wurde generiert basierend auf den Daten deiner Buchhaltung und Transaktionen.
            </div>
          </div>
        </div>
      </CardFooter>
    </Card>
  )
}
