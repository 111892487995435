import React, { useEffect } from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { UserTypeEnum } from "@/feature/payroll/MultiStepGeneral";
import { useFieldArray } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Plus, X } from "lucide-react";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { formatIBAN } from "@/util/format";
import { isValidIBAN } from "ibantools";

export function Benefits({ form, userType, employee }: QuestionnaireProps) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "vwlDetails",
  });

  const { data } = useGetEmployeeDataQuery<any>(
    { employeeId: employee?.id || "" },
    { skip: !employee?.id },
  );

  useEffect(() => {
    if (data) {
      const employeeFillData = data?.events?.find(
        (event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent,
      )?.employeeFillQuestionnaireEvent;

      if (employeeFillData?.vwlDetails?.length) {
        form.setValue(
          "vwlDetails",
          employeeFillData.vwlDetails.map((detail: any) => ({
            ...detail,
            employerShare: null,
          })),
        );
      }
    }
  }, [data, form]);

  const handleIbanChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const rawValue = e.target.value;
    const formattedValue = formatIBAN(rawValue);
    form.setValue(`vwlDetails.${index}.iban`, formattedValue);
  };

  useEffect(() => {
    fields.forEach((_, index) => {
      const iban = form.getValues(`vwlDetails.${index}.iban`);
      if (iban) {
        const ibanWithoutSpaces = iban.replace(/\s+/g, "");
        if (!isValidIBAN(ibanWithoutSpaces)) {
          console.error(`Ungültige IBAN für Index ${index}`);
        }
      }
    });
  }, [fields, form]);

  return (
    <div className="mb-2 rounded-xl border bg-[#1b1b1b] p-4">
      <h2 className="mb-4 border-b-2 border-b-zinc-700 text-sm">
        VWL-Vertragsdaten
      </h2>

      <div>
        {fields.map((field, index) => (
          <div key={field.id} className="mb-4 gap-4">
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {userType === UserTypeEnum.EMPLOYEE ? (
                <>
                  <FormSimpleText
                    hoverInfoTitle={questionnaireTranslation(
                      "benefitsCard.receptionThrough",
                    )}
                    hoverInfoDescription={questionnaireTranslation(
                      "benefitsCard.infoCard.infoInstitution",
                    )}
                    form={form}
                    name={`vwlDetails.${index}.receptionThrough`}
                    label={questionnaireTranslation(
                      "benefitsCard.receptionThrough",
                    )}
                  />
                  <FormSimpleMoney
                    form={form}
                    name={`vwlDetails.${index}.sumBenefits`}
                    label={questionnaireTranslation("benefitsCard.sumBenefits")}
                  />
                  <DateInputField
                    control={form.control}
                    name={`vwlDetails.${index}.dateSince`}
                    labelKey={questionnaireTranslation("benefitsCard.since")}
                  />
                  <FormSimpleText
                    form={form}
                    name={`vwlDetails.${index}.contractNumber`}
                    label={questionnaireTranslation("benefitsCard.contractNr")}
                  />
                  <FormSimpleText
                    form={form}
                    name={`vwlDetails.${index}.iban`}
                    label={questionnaireTranslation(
                      "benefitsCard.benefitsIban",
                    )}
                    onChange={(e) => handleIbanChange(e, index)}
                  />
                  <FormSimpleText
                    form={form}
                    name={`vwlDetails.${index}.bic`}
                    label={questionnaireTranslation("benefitsCard.benefitsBic")}
                  />
                </>
              ) : (
                <>
                  <FormSimpleText
                    disabled={true}
                    form={form}
                    name={`vwlDetails.${index}.receptionThrough`}
                    label={questionnaireTranslation(
                      "benefitsCard.receptionThrough",
                    )}
                  />
                  <FormSimpleMoney
                    disabled={true}
                    form={form}
                    name={`vwlDetails.${index}.sumBenefits`}
                    label={questionnaireTranslation("benefitsCard.sumBenefits")}
                  />
                  <DateInputField
                    disabled={true}
                    control={form.control}
                    name={`vwlDetails.${index}.dateSince`}
                    labelKey={questionnaireTranslation("benefitsCard.since")}
                  />
                  <FormSimpleText
                    disabled={true}
                    form={form}
                    name={`vwlDetails.${index}.contractNumber`}
                    label={questionnaireTranslation("benefitsCard.contractNr")}
                  />
                  <FormSimpleText
                    disabled={true}
                    form={form}
                    name={`vwlDetails.${index}.iban`}
                    label={questionnaireTranslation(
                      "benefitsCard.benefitsIban",
                    )}
                  />
                  <FormSimpleText
                    disabled={true}
                    form={form}
                    name={`vwlDetails.${index}.bic`}
                    label={questionnaireTranslation("benefitsCard.benefitsBic")}
                  />
                  <FormSimpleMoney
                    hoverInfoTitle={questionnaireTranslation(
                      "benefitsCard.employerShare",
                    )}
                    hoverInfoDescription={questionnaireTranslation(
                      "benefitsCard.infoCard.infoEmployerShare",
                    )}
                    form={form}
                    name={`vwlDetails.${index}.employerShare`}
                    label={questionnaireTranslation(
                      "benefitsCard.employerShare",
                    )}
                    labelClassName="text-white"
                    itemClassName="mb-2"
                  />
                </>
              )}
            </div>
            {userType === UserTypeEnum.EMPLOYEE && (
              <div className="mb-0.5 mt-4 flex items-end justify-center">
                <Button
                  type="button"
                  variant="secondary"
                  size="icon"
                  onClick={() => remove(index)}
                >
                  <X />
                </Button>
              </div>
            )}
          </div>
        ))}

        {userType === UserTypeEnum.EMPLOYEE && (
          <Button
            type="button"
            variant="secondary"
            size="icon"
            onClick={() =>
              append({
                receptionThrough: "",
                sumBenefits: null,
                dateSince: "",
                contractNumber: null,
                iban: "",
                bic: "",
                employerShare: null,
              })
            }
          >
            <Plus />
          </Button>
        )}
      </div>
    </div>
  );
}

