import React, { useContext, useEffect, useState } from "react";
import { DropzoneState, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import { Button } from "@/components/ui/button";
import { UploadContext } from "@/components/upload/UploadContextProvider";
import { cn } from "@/lib/utils";
import { Receipt, ReceiptText, Upload } from "lucide-react";

const FileUpload = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [dropActive, setDropActive] = useState(false);
  const [invoiceDropActive, setInvoiceDropActive] = useState(false);
  const [receiptDropActive, setReceiptDropActive] = useState(false);

  useEffect(() => {
    if (dropActive && !(invoiceDropActive || receiptDropActive)) {
      let t = setTimeout(() => {
        setDropActive(false);
      }, 1000);
      return () => clearTimeout(t);
    }
  }, [dropActive, invoiceDropActive, receiptDropActive]);

  return (
    <>
      <div
        className={cn("relative flex flex-1 flex-col")}
        onDragOver={(e) => {
          // Safari does not support DataTransfer.items
          if (
            window.navigator.userAgent.includes("Safari") &&
            !window.navigator.userAgent.includes("Chrome")
          ) {
            setDropActive(true);
          } else if (
            e.dataTransfer.items &&
            e.dataTransfer.items[0] &&
            e.dataTransfer.items[0].kind === "file"
          ) {
            setDropActive(true);
          }
        }}
      >
        <div
          className={cn(
            "flex flex-1 flex-col transition-all",
            dropActive && "blur-md",
          )}
        >
          <HorizontalFlex className="pb-1">
            <Title>{t("component.documents.main.title")}</Title>
            <FlexOne />
            <FileUploadButton name={"invoice"} />
            <FileUploadButton name={"receipt"} />
          </HorizontalFlex>
          {children}
        </div>

        {dropActive && (
          <div
            className={"absolute flex gap-8"}
            style={{
              top: -16,
              left: -16,
              right: -16,
              bottom: -16,
              zIndex: 100,
            }}
          >
            <FileUploadDropZone
              name={"receipt"}
              onDragChange={setReceiptDropActive}
              onDone={() => setDropActive(false)}
            />
            <FileUploadDropZone
              name={"invoice"}
              onDragChange={setInvoiceDropActive}
              onDone={() => setDropActive(false)}
            />
          </div>
        )}
      </div>
    </>
  );
};

function FileUploadButton(props: {
  name: "invoice" | "receipt";
  onDone?: () => void;
}) {
  const { getRootProps, getInputProps, open } = useDocumentDropzone(
    props.name,
    {
      onDone: props.onDone,
    },
  );
  const { t } = useTranslation();

  return (
    <Button {...getRootProps()} onClick={open}>
      {t("component.documents.upload." + props.name + "Upload")}
      <input {...getInputProps()} />
    </Button>
  );
}

function FileUploadDropZone(props: {
  name: "invoice" | "receipt";
  onDone?: () => void;
  onDragChange?: (isActive: boolean) => void;
}) {
  const { t } = useTranslation();

  const [isActive, setIsActive] = React.useState(false);
  const { getRootProps, getInputProps, isDragReject, fileRejections } =
    useDocumentDropzone(props.name, {
      onDone: () => {
        setIsActive(false);
        props.onDone?.();
      },
      onDragEnter: () => setIsActive(true),
      onDragLeave: () => setIsActive(false),
    });

  useEffect(() => {
    props.onDragChange?.(isActive);
  }, [isActive, props]);

  const rejectionMessage = (): string => {
    fileRejections.forEach((fileRejection) => {
      const hasFileTooLargeError = fileRejection.errors.some(
        (error) => error.code === "file-too-large",
      );
      if (hasFileTooLargeError) {
        return t("component.documents.upload.fileTooLarge");
      }
    });
    return t("component.documents.upload.invalidFileType");
  };
  return (
    <div
      className={cn(
        "flex-1 rounded-xl border-2 border-dashed border-accent-foreground transition-colors",
        isActive && "bg-background",
        isDragReject && "border-error",
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <VerticalFlex gap={8} align={"center"} className={"sticky top-80"}>
        {isDragReject ? (
          <p className="text-error">{rejectionMessage()}</p>
        ) : (
          <>
            <Upload className="h-16 w-16" />
            {t(`component.documents.upload.${props.name}Dropzone`)}
            {props.name === "invoice" && <ReceiptText className="h-16 w-16" />}
            {props.name === "receipt" && <Receipt className="h-16 w-16" />}
          </>
        )}
      </VerticalFlex>
    </div>
  );
}

function useDocumentDropzone(
  name: "invoice" | "receipt",
  events: {
    onDone?: () => void;
    onDragEnter?: () => void;
    onDragLeave?: () => void;
  },
): DropzoneState {
  const { addUpload } = useContext(UploadContext);

  let acceptedTypes = {
    "application/pdf": [".pdf"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "application/xml": [".xml"],
  };

  const onDrop = (acceptedFiles: File[]) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append(name + "[]", file);
    });

    addUpload({
      method: "POST",
      target: "document/",
      data: formData,
      type: name,
    });
    events.onDone?.();
  };

  return useDropzone({
    accept: acceptedTypes,
    maxSize: 30 * 1024 * 1024,
    maxFiles: 100,
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDragEnter: () => events.onDragEnter?.(),
    onDragLeave: () => events.onDragLeave?.(),
  });
}

export default FileUpload;
