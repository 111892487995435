import React from "react";
import RadioFormField from "@/feature/payroll/components/formFields/RadioFormField";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import { HourDistribution } from "@/feature/payroll/forms/questionnaire/components/HourDistribution";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { HandleEmploymentDates } from "@/feature/payroll/forms/questionnaire/components/HandleEmploymentDates";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";
import { SelectEnum, UserTypeEnum } from "@/feature/payroll/MultiStepGeneral";
import { useSelector } from "react-redux";
import { RootState } from "@/api/store";

export function Employment({ form, userType }: QuestionnaireProps) {
  const { isInConstruction } = useSelector(
    (state: RootState) => state.questionnaire,
  );

  return (
    <div className="mb-2 rounded-xl border bg-[#1b1b1b] p-4">
      {userType === UserTypeEnum.EMPLOYEE ? (
        <div>
          <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
            {payrollTranslation("universal.generalInfo")}
          </h2>
          <div
            className={
              "mb-4 grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
            }
          >
            <FormSimpleText
              hoverInfoTitle={questionnaireTranslation(
                "employmentCard.jobTitle",
              )}
              hoverInfoDescription={questionnaireTranslation(
                "employmentCard.infoCard.infoJobTitle",
              )}
              form={form}
              name={"employmentDetails.jobTitle"}
              label={questionnaireTranslation("employmentCard.jobTitle")}
            />
            {isInConstruction ? (
              <DateInputField
                control={form.control}
                name={"employmentDetails.constructionEmployment"}
                labelKey={questionnaireTranslation(
                  "employmentCard.constructionEmployment",
                )}
              />
            ) : null}
            <div>
              <SelectFormField
                name={"employmentDetails.highestSchoolDegree"}
                control={form.control}
                labelKey={questionnaireTranslation(
                  "employmentCard.highestEduQualification",
                )}
                options={[
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.noDegree",
                    ),
                    value: "noDegree",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.lowerSecondary",
                    ),
                    value: "lowerSecondary",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.intermediate",
                    ),
                    value: "intermediate",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.advanced",
                    ),
                    value: "advanced",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.aLevel",
                    ),
                    value: "aLevel",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.vocational",
                    ),
                    value: "vocational",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.bachelor",
                    ),
                    value: "bachelor",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.master",
                    ),
                    value: "master",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.diploma",
                    ),
                    value: "diploma",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.stateExamination",
                    ),
                    value: "stateExamination",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.magister",
                    ),
                    value: "magister",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.doctorate",
                    ),
                    value: "doctorate",
                  },
                  {
                    label: questionnaireTranslation(
                      "employmentCard.eduValues.habilitation",
                    ),
                    value: "habilitation",
                  },
                ]}
              />
            </div>

            <SelectFormField
              name={"employmentDetails.highestProfessionalEducation"}
              control={form.control}
              labelKey={questionnaireTranslation(
                "employmentCard.highestProfEducation",
              )}
              options={[
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.journeyman",
                  ),
                  value: "journeyman",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.certifiedSpecialist",
                  ),
                  value: "certifiedSpecialist",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.masterCraftsman",
                  ),
                  value: "masterCraftsman",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.certifiedTechnician",
                  ),
                  value: "certifiedTechnician",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.businessAdministrator",
                  ),
                  value: "businessAdministrator",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.certifiedCommercialSpecialist",
                  ),
                  value: "certifiedCommercialSpecialist",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.certifiedBusinessEconomist",
                  ),
                  value: "certifiedBusinessEconomist",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.industrialMaster",
                  ),
                  value: "industrialMaster",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.profValues.stateCertifiedBusinessManager",
                  ),
                  value: "stateCertifiedBusinessManager",
                },
              ]}
            />
          </div>
          <h2
            className={"col-span-4 mb-4 border-b-2 border-b-zinc-700 text-sm"}
          >
            {questionnaireTranslation(
              "employmentCard.additionalEmploymentTitle",
            )}
          </h2>
          <div
            className={
              "col-span-4 mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-4"
            }
          >
            <RadioFormField
              name={"employmentDetails.additionalEmployment"}
              control={form.control}
              labelKey={questionnaireTranslation(
                "employmentCard.additionalEmployment",
              )}
              options={[
                {
                  label: payrollTranslation("universal.yes"),
                  value: SelectEnum.YES,
                },
                {
                  label: payrollTranslation("universal.no"),
                  value: SelectEnum.NO,
                },
              ]}
            />

            <RadioFormField
              name={"employmentDetails.minorEmployment"}
              control={form.control}
              labelKey={questionnaireTranslation(
                "employmentCard.minorEmployment",
              )}
              options={[
                {
                  label: payrollTranslation("universal.yes"),
                  value: SelectEnum.YES,
                },
                {
                  label: payrollTranslation("universal.no"),
                  value: SelectEnum.NO,
                },
              ]}
            />
          </div>
          {/*<div className={"col-span-4"}>
            <InfoCard
              infoText={
                <>
                  Hier kannst du allgemeine Informationen zur Beschäftigung
                  eintragen.
                </>
              }
            />
          </div>*/}
        </div>
      ) : (
        <div>
          <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
            {payrollTranslation("universal.generalInfo")}
          </h2>
          <div className={"mb-8 grid grid-cols-2 gap-4"}>
            <div className={"col-span-2"}>
              <HandleEmploymentDates form={form} />
            </div>

            <FormSimpleText
              form={form}
              name={"employmentDetails.employmentCompany"}
              label={questionnaireTranslation(
                "employmentCard.employmentCompany",
              )}
            />

            <FormSimpleText
              form={form}
              name={"employmentDetails.jobActivity"}
              label={questionnaireTranslation("employmentCard.activities")}
            />
          </div>

          <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
            {questionnaireTranslation("employmentCard.workVacationTitle")}
          </h2>
          <div className={"mb-8 grid grid-cols-2 gap-4"}>
            <FormSimpleNumber
              form={form}
              name={"employmentDetails.weeklyWorkHours"}
              label={questionnaireTranslation("employmentCard.weeklyWorkHours")}
            />

            <FormSimpleNumber
              form={form}
              name={"employmentDetails.vacationEntitlement"}
              label={questionnaireTranslation(
                "employmentCard.vacationEntitlement",
              )}
            />
            <div className={"col-span-2 mt-2"}>
              <h3 className="mb-1 text-sm text-muted-foreground">
                {questionnaireTranslation("employmentCard.hourDistribution")}
              </h3>
              <div>
                <HourDistribution form={form} />
              </div>
            </div>
          </div>
          <h2 className={"mb-4 border-b-2 border-b-zinc-700 text-sm"}>
            {questionnaireTranslation("employmentCard.contractDetailsTitle")}
          </h2>
          <div className={"mb-8 grid grid-cols-2 gap-4"}>
            <RadioFormField
              name={"employmentDetails.probation"}
              control={form.control}
              labelKey={questionnaireTranslation("employmentCard.probation")}
              options={[
                { label: payrollTranslation("universal.yes"), value: "yes" },
                { label: payrollTranslation("universal.no"), value: "no" },
              ]}
            />
            <FormSimpleNumber
              form={form}
              name={"employmentDetails.probationDuration"}
              label={questionnaireTranslation(
                "employmentCard.probationDuration",
              )}
            />
            <SelectFormField
              hoverInfoTitle={questionnaireTranslation(
                "employmentCard.contractForm",
              )}
              hoverInfoDescription={questionnaireTranslation(
                "employmentCard.infoCard.infoContractForm",
              )}
              name={"employmentDetails.contractForm"}
              control={form.control}
              labelKey={questionnaireTranslation("employmentCard.contractForm")}
              options={[
                {
                  label: questionnaireTranslation(
                    "employmentCard.contractValues.unlimitedFullTime",
                  ),
                  value: "1",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.contractValues.unlimitedPartTime",
                  ),
                  value: "2",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.contractValues.limitedFullTime",
                  ),
                  value: "3",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.contractValues.limitedPartTime",
                  ),
                  value: "4",
                },
              ]}
            />
            <SelectFormField
              hoverInfoTitle={questionnaireTranslation(
                "employmentCard.peopleGroup",
              )}
              hoverInfoDescription={questionnaireTranslation(
                "employmentCard.infoCard.infoPeopleGroup",
              )}
              name={"employmentDetails.peopleGroup"}
              control={form.control}
              labelKey={questionnaireTranslation("employmentCard.peopleGroup")}
              options={[
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.101",
                  ),
                  value: "101",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.102",
                  ),
                  value: "102",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.103",
                  ),
                  value: "103",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.104",
                  ),
                  value: "104",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.105",
                  ),
                  value: "105",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.106",
                  ),
                  value: "106",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.107",
                  ),
                  value: "107",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.108",
                  ),
                  value: "108",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.109",
                  ),
                  value: "109",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.110",
                  ),
                  value: "110",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.111",
                  ),
                  value: "111",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.112",
                  ),
                  value: "112",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.113",
                  ),
                  value: "113",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.114",
                  ),
                  value: "114",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.116",
                  ),
                  value: "116",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.117",
                  ),
                  value: "117",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.118",
                  ),
                  value: "118",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.119",
                  ),
                  value: "119",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.120",
                  ),
                  value: "120",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.121",
                  ),
                  value: "121",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.122",
                  ),
                  value: "122",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.123",
                  ),
                  value: "123",
                },
                {
                  label: questionnaireTranslation(
                    "employmentCard.peopleGroupValues.124",
                  ),
                  value: "124",
                },
              ]}
            />
            <FormSimpleText
              form={form}
              name={"employmentDetails.departmentNumber"}
              label={questionnaireTranslation("employmentCard.departmentNr")}
              placeholder={"1234"}
            />
            <FormSimpleText
              hoverInfoTitle={questionnaireTranslation(
                "employmentCard.costCenter",
              )}
              hoverInfoDescription={questionnaireTranslation(
                "employmentCard.infoCard.infoCostCenter",
              )}
              form={form}
              name={"employmentDetails.costCenter"}
              label={questionnaireTranslation("employmentCard.costCenter")}
            />
          </div>
          {/*<div className={"col-span-4"}>
            <InfoCard
              infoText={
                <>
                  Hier kannst du die Beschäftigungsdetails deines Mitarbeiters
                  definieren. Wichtig ist vor allem das Eintrittsdatum.
                  <br />
                  Du kann
                </>
              }
            />
          </div>*/}
        </div>
      )}
    </div>
  );
}
