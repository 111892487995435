// NavigationButtons.tsx
import React from "react";
import { Button } from "@/components/ui/button";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";

interface NavigationButtonsProps {
  currentStep: number;
  totalSteps: number;
  onNext: () => void;
  onPrev: () => void;
}

export const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  currentStep,
  totalSteps,
  onNext,
  onPrev,
}) => {
  return (
    <div className="w-full mt-4 flex justify-between">
      {currentStep > 0 && (
        <Button type="button" onClick={onPrev} variant="secondary">
          {payrollTranslation("universal.back")}
        </Button>
      )}
      {currentStep < totalSteps - 1 && (
        <Button type="button" onClick={onNext}>
          {payrollTranslation("universal.forth")}
        </Button>
      )}
    </div>
  );
};
