import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import errorDE from "./translations/de/error.json";
import commonDE from "./translations/de/common.json";
import modelDE from "./translations/de/model.json";
import componentDE from "./translations/de/component.json";
import zodDE from "./translations/de/zod.json";
import uiDE from "./translations/de/ui.json";
import countriesDE from "./translations/de/countries.json";
import { zodI18nMap } from "zod-i18n-map";

import { z } from "zod";

import dayjs from "dayjs";
import "dayjs/locale/de";
import localizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";
import QuarterOfYear from "dayjs/plugin/quarterOfYear";

import DayJsLocal from "dayjs/locale/de";

const _DayJsLocal = DayJsLocal;

dayjs.locale("de");
dayjs.extend(localizedFormat);
dayjs.extend(QuarterOfYear);
dayjs.extend(RelativeTime);
dayjs.locale("de");

const resources = {
  "de-DE": {
    translation: {
      component: componentDE,
      error: errorDE,
      common: commonDE,
      model: modelDE,
      ui: uiDE,
      countries: countriesDE
    },
    zod: zodDE,
  },
};

const currencyOptions = {
  style: "currency",
  currency: "EUR",
};

function customFormat(value: any, format?: string, lng?: string): string {
  if (format === "date") return dayjs(value).format("L");
  if (format === "datetime")
    return dayjs(value).format("L") + ", " + dayjs(value).format("LT");
  if (format === "dateShort") return dayjs(value).format("DD MMM");
  if (format === "dateNameMonth") return dayjs(value).format("LL");
  if (format === "time") return dayjs(value).format("LT");
  if (format === "currency")
    return new Intl.NumberFormat(lng, currencyOptions).format(value / 100);
  if (format === "currencyNoFraction")
    return new Intl.NumberFormat(lng, {
      ...currencyOptions,
      maximumFractionDigits: 0,
    }).format(value / 100);
  return value;
}

i18n.use(initReactI18next).init({
  resources,
  compatibilityJSON: "v4",
  lng: "de-DE",
  fallbackLng: "de-DE",
  interpolation: {
    escapeValue: false,
    format: customFormat,
  },
});

z.setErrorMap(zodI18nMap);
export const translation = i18n;
