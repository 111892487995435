import { HorizontalFlex } from "@/components/layout/Flex";
import { MouseEvent, ReactNode } from "react";
import { Loader2 } from "lucide-react";

interface InvoiceActionButtonProps {
  onClick: (e: MouseEvent) => void; // Hier ist die Änderung
  icon: ReactNode;
  label: string;
  disabled?: boolean;
  loading?: boolean;
}

const InvoiceActionButton = ({
  onClick,
  icon,
  label,
  disabled,
  loading,
}: InvoiceActionButtonProps) => (
  <HorizontalFlex title={label} onClick={onClick}>
    {loading ? <Loader2 size={16} className="animate-spin" /> : icon}
  </HorizontalFlex>
);

export default InvoiceActionButton;
