import React, { useMemo } from "react";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { Card } from "@/components/ui/card";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import EventName from "@/feature/payroll/forms/questionnaire/components/printForm/functions/getEventName";

interface TimelineProps {
  employee?: Employee;
}

const Timeline = ({ employee }: TimelineProps) => {
  const sortedEvents = useMemo(() => {
    const events = [...(employee?.events || [])];
    events.sort(
      (a, b) =>
        new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime(),
    );
    return events;
  }, [employee?.events]);


  return (
    <div className={"mr-4 mt-2 w-full"}>
      <h2 className="mb-6 text-left text-lg font-semibold">{payrollTranslation("timelineComponent.timeline")}</h2>
      <div className="relative ml-4">
        <ul className="relative">
          <div className="absolute h-full w-px bg-gray-400" />

          {sortedEvents.map((event) => (
            <div key={event.id} className="relative mb-8 pl-8">
              <div
                className={`absolute left-0 top-6 h-4 w-4 -translate-x-1/2 rounded-full border border-white bg-[#1b1b1b] shadow-md`}
              />
              <Card className="gap-4 border p-2 transition-shadow hover:shadow-xl">
                <div className="mb-1 flex w-full items-center justify-between text-sm font-semibold opacity-50">
                  <div>
                    {event.createdAt
                      ? new Date(event.createdAt).toLocaleDateString("de-DE", {
                          weekday: "short",
                          day: "2-digit",
                          month: "numeric",
                          year: "2-digit",
                        })
                      : "Kein Datum verfügbar"}
                  </div>
                  <div>
                    {event.createdAt
                      ? new Date(event.createdAt).toLocaleTimeString("de-DE", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "Keine Uhrzeit verfügbar"}
                  </div>
                </div>
                <EventName event={event} />
              </Card>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Timeline;
