import React from "react";
import { payrollTranslation, questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";

const EmploymentData: React.FC<{
  employmentDetails: QuestionnaireEmployee["employmentDetails"];
}> = ({ employmentDetails }) => {
  return (
    <section className="mb-8 font-light">
      <h3 className="mb-4 border-b-2 border-b-zinc-700 text-base font-semibold">
        {questionnaireTranslation("employmentCard.occupation")}
      </h3>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <div className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.jobTitle")}:
        </div>
        <div className="col-span-1">{employmentDetails?.jobTitle || "-"}</div>
        <div className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.additionalEmployment")}:
        </div>
        <div className="col-span-1">
          {employmentDetails?.additionalEmployment
            ? payrollTranslation(`universal.${employmentDetails?.additionalEmployment}`)
            : "-"}

        </div>
        <div className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.minorEmployment")}:
        </div>
        <div className="col-span-1">
          {employmentDetails?.minorEmployment
            ? payrollTranslation(`universal.${employmentDetails?.minorEmployment}`)
            : "-"}

        </div>
        <div className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.highestEduQualification")}:
        </div>
        <div className="col-span-1">
          {employmentDetails?.highestSchoolDegree &&
          employmentDetails.highestSchoolDegree.length > 0
            ? questionnaireTranslation(
                `employmentCard.eduValues.${employmentDetails.highestSchoolDegree}`,
              )
            : "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.highestProfEducation")}:
        </div>
        <div className="col-span-1">
          {employmentDetails.highestProfessionalEducation &&
          employmentDetails.highestProfessionalEducation?.length > 0
            ? questionnaireTranslation(
                `employmentCard.profValues.${employmentDetails?.highestProfessionalEducation}`,
              )
            : "-"}
        </div>
        <div className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.constructionEmployment")}:
        </div>
        <div className="col-span-1">
          {employmentDetails?.constructionEmployment
            ? new Date(employmentDetails?.constructionEmployment).toLocaleDateString("de-DE")
            : "-"}
        </div>
      </div>
    </section>
  );
};

export default EmploymentData;
