import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { formatToEuro } from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/TaxData";

export const PrintBenefits: React.FC<{
  formData: FormData;
}> = ({ formData }) => {
  const vwlDetails = Array.isArray(getField("vwlDetails", formData))
    ? getField("vwlDetails", formData)
    : [];

  return (
    <div>
      {vwlDetails.length > 0 && (
        <div>
          <h2 style={{ borderBottom: "2px solid gray" }}>
            {questionnaireTranslation("benefitsCard.benefits")}
          </h2>
        </div>
      )}

      {vwlDetails?.map(
        (
          vwl: {
            receptionThrough?: string;
            sumBenefits?: number;
            dateSince?: string;
            iban?: string;
            bic?: string;
            contractNumber?: string;
            employerShare?: number;
          },
          index: number,
        ) => (
          <div
            className="grid-container"
            key={index}
            style={{ marginBottom: "4px" }}
          >
            <span className="title-fill">
              {questionnaireTranslation("benefitsCard.receptionThrough")}
            </span>
            <span>{vwl.receptionThrough}</span>
            <span className="title-fill">
              {questionnaireTranslation("benefitsCard.sumBenefits")}
            </span>
            <span>
              {vwl.sumBenefits &&
                vwl.sumBenefits > 0 &&
                formatToEuro(vwl.sumBenefits)}
            </span>
            <span className="title-fill">
              {questionnaireTranslation("benefitsCard.contractNr")}
            </span>
            <span>{vwl.contractNumber}</span>
            <span className="title-fill">
              {questionnaireTranslation("benefitsCard.since")}
            </span>
            <span>{vwl.dateSince}</span>
            <span className="title-fill">
              {payrollTranslation("universal.iban")}
            </span>
            <span>{vwl.iban}</span>
            <span className="title-fill">
              {payrollTranslation("universal.bic")}
            </span>
            <span>{vwl.bic}</span>
            <span className="title-fill">
              {questionnaireTranslation("benefitsCard.employerShare")}
            </span>
            <span>{formatToEuro(vwl.employerShare)}</span>
          </div>
        ),
      )}
    </div>
  );
};
