import { EmploymentTypeEnum } from "@/feature/payroll/MultiStepGeneral";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";

export const getEmploymentTypeLabel = (
  employmentType: EmploymentTypeEnum,
): string => {
  switch (employmentType) {
    case EmploymentTypeEnum.MINIJOB:
      return questionnaireTranslation("miniJobContract");
    case EmploymentTypeEnum.EMPLOYEE:
      return questionnaireTranslation("normalEmployee");
    case EmploymentTypeEnum.TRAINEE:
      return questionnaireTranslation("trainee");
    default:
      return "Unbekannt";
  }
};