export const defaultValuesQuestionnaire = {

  personalDetails: {
    birthName: "",
    birthDate: "",
    phone: "",
    address: {
      street: "",
      houseNumber: "",
      city: "",
      postalCode: "",
      country: "",
      addressAdditional: "",
    },
    insuranceNumber: "",
    birthPlace: "",
    birthCountry: "",
    gender: "",
    nationality: "",
    disabled: null,
    employeeNumberSocialFund: "",
    iban: "",
    bic: "",
  },

  children: [
    {
      lastName: "",
      firstName: "",
      birthDate: "",
    },
  ],

  employmentDetails: {
    highestSchoolDegree: "",
    highestProfessionalEducation: "",
    jobTitle: "",
    constructionEmployment: null,
    minorEmployment: null,
    additionalEmployment: null,
    trainingStart: null,
    trainingEnd: null,
    entryDate: null,
    firstEntryDate: null,
    employmentCompany: "",

    jobActivity: "",
    probation: null,
    probationDuration: null,
    weeklyWorkHours: null,
    vacationEntitlement: null,
    contractForm: "",
    hourDistribution: {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
    costCenter: "",
    departmentNumber: null,
  },

  contractLimitation: {
    limitType: "",
    contractEndDate: null,
    contractSignedDate: null,
  },

  payment: {
    description: "",
    sum: null,
    dateSum: null,
    hourlyWage: null,
    dateWage: null,
    traineePayment: {
      yearOne: {
        sum: null,
        dateSum: null,
        hourlyWage: null,
        dateWage: null,
      },
      yearTwo: {
        sum: null,
        dateSum: null,
        hourlyWage: null,
        dateWage: null,
      },
      yearThree: {
        sum: null,
        dateSum: null,
        hourlyWage: null,
        dateWage: null,
      },
    },
  },

  taxInformation: {
    miniJobberTax: {
      rollOff: "",
      generalize: "",
    },
    taxId: null,
    taxClass: "1",
    taxFactor: null,
    childAllowance: null,
  },

  vwlDetails: [
      {
        receptionThrough: "",
        sumBenefits: null,
        dateSince: "",
        contractNumber: null,
        iban: "",
        bic: "",
        employerShare: null,
      },

  ],

  taxableEmployment: {
    previousEmployment: [
      {
        dateFrom: null,
        dateUntil: null,
        typeOccupation: "",
        daysOccupation: null,
      },
    ],
    miniJobberLimit: {
      moneyLimit: "",
    },
  },

  socialSecurity: {
    statutoryHealthInsurance: "",
    currentHealthInsurance: "",
    parentProperty: "",
    miniJobberSecurity: {
      freePensionInsurance: "",
    },
    insuranceKey: "",
    deuevStatus: "",
  },
};
