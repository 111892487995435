import React, { useEffect, useMemo, useState } from "react";
import { FieldPath, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { Progress } from "@/components/ui/progress";
import { NavigationButtons } from "@/feature/payroll/components/formFunctions/NavigationButtons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import { StepsAbsence } from "@/feature/payroll/forms/absence/AbsenceSteps";
import { StepsChanges } from "@/feature/payroll/forms/changes/ChangesSteps";
import { FilterStepsQuestionnaire } from "@/feature/payroll/forms/questionnaire/functions/StepFilter";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

export interface Step<T extends z.ZodTypeAny> {
  title: string;
  icon: JSX.Element;
  component: JSX.Element;
  fields: FieldPath<z.infer<T>>[];
  showForType: UserTypeEnum[];
  filter?: {
    hasChildren?: boolean;
    hasVwl?: boolean;
    hasTaxableEmployment?: boolean;
    employmentType?: string;
  };
}

interface MultiStepCardProps {
  form: UseFormReturn<z.infer<any>>;
  userType: UserTypeEnum;
  type?: "absence" | "changes" | "questionnaire";
  employment?: EmploymentTypeEnum;
  token?: string;
  setCurrentTitle?: (title: string) => void;
  employee?: Employee;
  onStepChange: (step: number) => void;
  onTotalStepsChange?: (totalSteps: number) => void

}

export enum UserTypeEnum {
  EMPLOYER = "employer",
  EMPLOYEE = "employee",
}

export enum SelectEnum {
  YES = "yes",
  NO = "no",
}

export enum EmploymentTypeEnum {
  MINIJOB = "miniJob",
  EMPLOYEE = "normalEmployee",
  TRAINEE = "trainee",
}




const MultiStepCard: React.FC<MultiStepCardProps> = ({
  form,
  userType,
  type,
  employment,
  setCurrentTitle,
  employee,
  onStepChange,
  onTotalStepsChange
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  const steps: Step<any>[] =
    type === "absence"
      ? StepsAbsence(form)
      : type === "changes"
        ? StepsChanges(form)
        : FilterStepsQuestionnaire(form, userType, employment!, employee!);

  const totalSteps = steps.length;
  useEffect(() => {
    if (onTotalStepsChange) {
      onTotalStepsChange(totalSteps);
    }
  }, [totalSteps, onTotalStepsChange]);



  const nextStep = async () => {
    const fieldsInCurrentComponent = steps[currentStep].fields;
    const result = await form.trigger(fieldsInCurrentComponent);

    if (result) {
      if (currentStep < totalSteps - 1) {
        const newStep = currentStep + 1;
        setCurrentStep(newStep);
        onStepChange?.(newStep);
        setShowAlert(false);
      }
    } else {
      setShowAlert(true);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      const newStep = currentStep - 1;
      setCurrentStep(newStep);
      onStepChange?.(newStep);
    }
  };

  const progressPercentage = useMemo(() => {
    return (currentStep / (totalSteps - 1)) * 100;
  }, [currentStep, totalSteps]);

  useEffect(() => {
    const currentTitleComponent = steps[currentStep].title;
    if (setCurrentTitle) {
      setCurrentTitle(currentTitleComponent);
    }
  }, [currentStep, steps, setCurrentTitle]);

  return (
    <div className={"mb-4 mt-4"}>
      <Progress value={progressPercentage} className="w-full" />

      <div className="card w-full">
        <h2 className="text-l mb-2 mt-4 flex items-center font-bold">
          {steps[currentStep].title}
          <span className="ml-2">{steps[currentStep].icon}</span>
        </h2>

        {steps[currentStep].component}
      </div>


      <NavigationButtons
        currentStep={currentStep}
        totalSteps={totalSteps}
        onNext={nextStep}
        onPrev={prevStep}
      />

      <div className={"mt-4 w-[80%]"}>
        {showAlert && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>{payrollTranslation("universal.error")}</AlertTitle>
            <AlertDescription>
              {payrollTranslation("universal.warning")}
            </AlertDescription>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default MultiStepCard;


