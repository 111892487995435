import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import {
  Baby,
  CircleEllipsis,
  Coins,
  HandCoins,
  HandHeart,
  History,
  Hourglass,
  Receipt,
  ShieldCheck,
  SquareMousePointer,
  User,
} from "lucide-react";
import React from "react";
import { PersonalInfo } from "@/feature/payroll/forms/questionnaire/steps/PersonalInfo";
import { Kids } from "@/feature/payroll/forms/questionnaire/steps/Kids";
import { Employment } from "@/feature/payroll/forms/questionnaire/steps/Employment";
import { Limitation } from "@/feature/payroll/forms/questionnaire/steps/Limit";
import { Taxes } from "@/feature/payroll/forms/questionnaire/steps/Taxes";
import { SocialSecurity } from "@/feature/payroll/forms/questionnaire/steps/SocialSecurity";
import { Payment } from "@/feature/payroll/forms/questionnaire/steps/Payment";
import { Benefits } from "@/feature/payroll/forms/questionnaire/steps/Benefits";
import { TaxableEmployment } from "@/feature/payroll/forms/questionnaire/steps/TaxableEmployment";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import {
  EmploymentTypeEnum,
  Step,
  UserTypeEnum,
} from "@/feature/payroll/MultiStepGeneral";
import { StartPageEmployee } from "@/feature/payroll/forms/questionnaire/steps/StartPageEmployee";
import { FormSchemaComplete } from "@/feature/payroll/forms/questionnaire/schema/QuestionnaireSchema";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { OverviewCard } from "@/feature/payroll/forms/questionnaire/steps/FormOverview";

export const StepsQuestionnaire = (
  form: UseFormReturn<z.infer<typeof FormSchemaComplete>>,
  userType: UserTypeEnum,
  employment: EmploymentTypeEnum,
  employee: Employee,
): Step<any>[] => {
  return [
    {
      icon: <CircleEllipsis className="h-5 w-5" />,
      title: questionnaireTranslation("preInformation"),
      component: <StartPageEmployee />,
      fields: [],
      showForType: [UserTypeEnum.EMPLOYEE],
    },

    {
      icon: <User className="h-5 w-5" />,
      title: questionnaireTranslation("personalCard.personalInfo"),
      component: (
        <PersonalInfo form={form} userType={userType} employee={employee} />
      ),
      fields: [
        "personalDetails.birthDate",
        "personalDetails.address.street",
        "personalDetails.address.houseNumber",
        "personalDetails.address.city",
        "personalDetails.address.postalCode",
      ],
      showForType: [UserTypeEnum.EMPLOYEE],
    },

    {
      icon: <Baby className="h-5 w-5" />,
      title: questionnaireTranslation("childCard.kids"),
      component: <Kids form={form} userType={userType} employee={employee} />,
      fields: [],
      showForType: [UserTypeEnum.EMPLOYEE],
      filter: {
        hasChildren: true,
      },
    },
    {
      icon: <SquareMousePointer className="h-5 w-5" />,
      title: questionnaireTranslation("employmentCard.occupation"),
      component: (
        <Employment form={form} userType={userType} employee={employee} />
      ),
      fields: [],
      showForType: [UserTypeEnum.EMPLOYEE, UserTypeEnum.EMPLOYER],
    },
    {
      icon: <Hourglass className="h-5 w-5" />,
      title: questionnaireTranslation("limitCard.timeLimit"),
      component: (
        <Limitation form={form} userType={userType} employee={employee} />
      ),
      fields: [],
      showForType: [UserTypeEnum.EMPLOYER],
    },
    {
      icon: <Receipt className="h-5 w-5" />,
      title: questionnaireTranslation("taxCard.taxes"),
      component: <Taxes form={form} userType={userType} employee={employee} />,
      fields: [],
      showForType: [UserTypeEnum.EMPLOYEE, UserTypeEnum.EMPLOYER],
    },
    {
      icon: <HandHeart className="h-5 w-5" />,
      title: questionnaireTranslation("socialSecurityCard.socialSecurity"),
      component: (
        <SocialSecurity form={form} userType={userType} employee={employee} />
      ),
      fields: [],
      showForType: [UserTypeEnum.EMPLOYEE, UserTypeEnum.EMPLOYER],
    },

    {
      icon: <HandCoins className="h-5 w-5" />,
      title: questionnaireTranslation("paymentCard.payment"),
      component: (
        <Payment form={form} userType={userType} employee={employee} />
      ),
      fields: [],
      showForType: [UserTypeEnum.EMPLOYER],
    },
    {
      icon: <Coins className="h-5 w-5" />,
      title: questionnaireTranslation("benefitsCard.benefits"),
      component: (
        <Benefits form={form} userType={userType} employee={employee} />
      ),
      fields: [],
      showForType: [UserTypeEnum.EMPLOYEE],
      filter: {
        hasVwl: true,
      },
    },

    {
      icon: <Coins className="h-5 w-5" />,
      title: questionnaireTranslation("benefitsCard.benefits"),
      component: (
        <Benefits form={form} userType={userType} employee={employee} />
      ),
      fields: [],
      showForType: [UserTypeEnum.EMPLOYER],
    },
    {
      icon: <History className="h-5 w-5" />,
      title: questionnaireTranslation("taxableCard.taxableEmployment"),
      component: (
        <TaxableEmployment
          form={form}
          userType={userType}
          employmentType={employment}
          employee={employee}
        />
      ),
      fields: [],
      showForType: [UserTypeEnum.EMPLOYEE],
      filter: {
        hasTaxableEmployment: true,
      },
    },
    {
      icon: <ShieldCheck className="h-5 w-5" />,
      title: "Überprüfen und fertigstellen",
      component: (
        <OverviewCard form={form} userType={userType} employee={employee} />
      ),
      fields: [],
      showForType: [UserTypeEnum.EMPLOYEE],
    },
    /*
    {
      icon: <Signature className="h-5 w-5" />,
      title: questionnaireTranslation("signCard.signature"),
      component: <ContractSign form={form} userType={userType} />,
      fields: [],
    },
    */
  ];
};
