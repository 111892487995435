import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { formatDate } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/FormateDateFunction";

export const PrintKids: React.FC<{
  formData: FormData;
}> = ({ formData }) => {
  const children = Array.isArray(getField("children", formData))
    ? getField("children", formData)
    : [];

  return (
    <div>
      {children.length > 1 && (
        <>
          <div>
            <h2 style={{ borderBottom: "2px solid gray" }}>
              {questionnaireTranslation("childCard.kids")}
            </h2>
            <div
              className="grid-container-three"
              style={{ marginBottom: "3px" }}
            >
              <span className="title-fill">
                {payrollTranslation("universal.firstName")}
              </span>
              <span className="title-fill">
                {payrollTranslation("universal.lastName")}
              </span>
              <span className="title-fill">
                {payrollTranslation("universal.birthDate")}
              </span>
            </div>
          </div>

          {children.map(
            (
              child: {
                firstName?: string;
                lastName?: string;
                birthDate?: string;
              },
              index: number,
            ) => (
              <div
                className="grid-container-three"
                key={index}
                style={{ marginBottom: "4px" }}
              >
                <span>{child.firstName}</span>
                <span>{child.lastName}</span>
                <span>{formatDate(child?.birthDate!)}</span>
              </div>
            ),
          )}
        </>
      )}
    </div>
  );
};
