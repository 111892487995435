import { Card, CardContent } from "@/components/ui/card";
import { FilterResult } from "@/api/types";
import { useTranslation } from "react-i18next";

export const DataTableNoEntriesHandler = <T,>(props: {
  result?: FilterResult<T>;
}) => {
  const { t } = useTranslation();

  if (props.result && props.result.total == 0) {
    return (
      <Card>
        <CardContent className={"p-8 py-32 text-center opacity-50"}>
          {t("common.noSearchResults")}
        </CardContent>
      </Card>
    );
  }
  return null;
};
