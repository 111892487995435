import { FieldValues } from "react-hook-form/dist/types/fields";
import { Control, FieldPath } from "react-hook-form/dist/types";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import React, { ReactNode } from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { HoverInfo } from "@/feature/settings/UserSettings/form/info/hoverInfo";

export const FormSimpleText = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  props: {
    autoComplete?: "on" | "off";
    autoFocus?: boolean;
    edit?: boolean;
    form: { control: Control<T> };
    name: TName;
    placeholder?: string;
    label?: ReactNode;
    type?: string;
    className?: string;
    inputClassName?: string;
    displayClassName?: string;
    itemClassName?: string;
    hidden?: boolean;
    disabled?: boolean;
    required?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    hoverInfoTitle?: string;
    hoverInfoDescription?: string;
  } & (
    | {
        textarea?: false;
        inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
      }
    | {
        textarea: true;
        inputProps?: React.InputHTMLAttributes<HTMLTextAreaElement>;
      }
  ),
) => {
  return (
    <FormField
      key={props.name}
      control={props.form.control}
      name={props.name}
      render={({ field }) => {
        if (props.edit === false) {
          if (field.value) {
            return (
              <span className={"whitespace-pre-line " + props.displayClassName}>
                {field.value}
              </span>
            );
          } else {
            return (
              <span className={(props.displayClassName || "") + " opacity-50"}>
                {props.placeholder}
              </span>
            );
          }
        }

        // Edit mode
        return (
          <FormItem className={cn(props.itemClassName)}>
            {props.label && (
              <div className="flex items-center">
                <FormLabel className={"mr-2 text-muted-foreground"}>
                  {props.label}
                  {props.required && <span> *</span>}
                </FormLabel>
                {props.hoverInfoTitle && props.hoverInfoDescription && (
                  <HoverInfo
                    title={props.hoverInfoTitle}
                    description={props.hoverInfoDescription}
                    align="start"
                  />
                )}
              </div>
            )}
            {!props.textarea && (
              <FormControl>
                <Input
                  autoComplete={props.autoComplete}
                  autoFocus={props.autoFocus}
                  autoSave={props.autoComplete}
                  className={cn(props.inputClassName, "")}
                  type={props.type}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  style={{ cursor: props.disabled ? "default" : "text" }}
                  {...props.inputProps}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (props.onChange) {
                      props.onChange(e);
                    }
                  }}
                />
              </FormControl>
            )}
            {props.textarea && (
              <FormControl>
                <Textarea
                  autoComplete={props.autoComplete}
                  autoFocus={props.autoFocus}
                  autoSave={props.autoComplete}
                  className={cn(props.className, "")}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  style={{ cursor: props.disabled ? "default" : "text" }}
                  rows={3}
                  {...props.inputProps}
                  {...field}
                />
              </FormControl>
            )}
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};
