import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ISO_COUNTRIES } from "@/models/contact";
import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { t } from "i18next";
import { VerticalFlex } from "@/components/layout/Flex";

interface CountrySelectProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T>;
  label?: string;
  defaultValue?: string;
}

const CountrySelect = <T extends FieldValues>({
                                                  form,
                                                  name,
                                                  label = t("component.contacts.addresses.country"),
                                                  defaultValue = "DE",
                                              }: CountrySelectProps<T>) => {
    const [open, setOpen] = React.useState(false);
    const [query, setQuery] = React.useState("");

    const filteredCountries =
        query === ""
            ? ISO_COUNTRIES
            : ISO_COUNTRIES.filter(
                (country) =>
                    country.name.toLowerCase().includes(query.toLowerCase()) ||
                    country.code.toLowerCase().includes(query.toLowerCase()),
            );

    const {control, setValue, watch} = form;

    React.useEffect(() => {
        const currentCountry = watch(name);
        if (!currentCountry) {
            setValue(name, defaultValue as PathValue<T, Path<T>>);
        }
    }, [name, defaultValue, setValue, watch]);

    return (
        <FormField
            control={control}
            name={name}
            render={({field}) => (
                <FormItem>
                    <VerticalFlex gap={2}>

                        <FormLabel className={"text-muted-foreground"}>{label}</FormLabel>
                        <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                                <FormControl>
                                    <Button
                                        variant="outline"
                                        role="combobox"
                                        className={cn(
                                            "w-[180px] bg-transparent",
                                            !field.value && "text-muted-foreground",
                                        )}
                                    >
                                        {field.value
                                            ? ISO_COUNTRIES.find(
                                                (country) => country.code === field.value,
                                            )?.name
                                            : "Wähle ein Land"}
                                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                                    </Button>
                                </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-[200px] p-0">
                                <Command>
                                    <CommandInput
                                        placeholder="Suche nach Land..."
                                        className="h-9"
                                        value={query}
                                        onValueChange={(value) => setQuery(value)}
                                    />
                                    <CommandList>
                                        <CommandEmpty>Kein Land gefunden.</CommandEmpty>
                                        <CommandGroup>
                                            {filteredCountries.map((country) => (
                                                <CommandItem
                                                    value={country.name}
                                                    key={country.code}
                                                    onSelect={() => {
                                                        setValue(name, country.code as PathValue<T, Path<T>>);
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {country.name}
                                                    <CheckIcon
                                                        className={cn(
                                                            "ml-auto h-4 w-4",
                                                            country.code === field.value
                                                                ? "opacity-100"
                                                                : "opacity-0",
                                                        )}
                                                    />
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                        <FormMessage/>
                    </VerticalFlex>

                </FormItem>
            )}
        />
    );
};

export default CountrySelect;
