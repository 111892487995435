import React from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";

interface EmploymentTypeOptionsProps {
  employmentType: string;
  onEmploymentTypeChange: (value: string) => void;
  onSendLink: () => void;
  showLinkButton: boolean;
}

const EmploymentTypeOptions: React.FC<EmploymentTypeOptionsProps> = ({ employmentType, onEmploymentTypeChange, onSendLink, showLinkButton }) => {
  return (
    <div className="grid grid-cols-2 gap-4 pt-4">

        <Select onValueChange={onEmploymentTypeChange} value={employmentType}>
          <SelectTrigger>
            <SelectValue placeholder={payrollTranslation("universal.choseOption")} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="normalEmployee">{questionnaireTranslation("normalEmployee")}</SelectItem>
              <SelectItem value="trainee">{questionnaireTranslation("trainee")}</SelectItem>
              <SelectItem value="miniJob">{questionnaireTranslation("miniJob")}</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>

      {showLinkButton && (
        <Button onClick={onSendLink}>Fragebogen senden</Button>
      )}
    </div>
  );
};

export default EmploymentTypeOptions;
