import React from "react";
import { Center } from "@/components/layout/Center";
import { payrollTranslation, questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";

const CompletionPage = () => {
  return (
    <Center v h>
      <div className="flex flex-col items-center space-y-4 text-2xl">
        <h1 className={"text-3xl"}>{payrollTranslation("universal.thankYou")}</h1>
        <p className={"text-xl"}>
          {questionnaireTranslation("closeTabSuccess")}
        </p>
      </div>
    </Center>
  );
};

export default CompletionPage;
