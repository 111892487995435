import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { VerticalFlex } from "@/components/layout/Flex";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { Button } from "@/components/ui/button";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import { X } from "lucide-react";
import EmployerForm from "@/feature/payroll/forms/questionnaire/EmployerForm";
import { useSendQuestionnaireLinkMutation } from "@/api/endpoints/payrollApi";
import EmployeeData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/EmployeeData";
import {
  EmploymentTypeEnum,
  UserTypeEnum,
} from "@/feature/payroll/MultiStepGeneral";
import Timeline from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/Timeline";
import MainButtons from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/DropdownMenue";
import EmploymentTypeOptions from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/EmploymentOptions";
import PrintPreview from "@/feature/payroll/forms/questionnaire/components/printForm/preview/PDFPreview";

export interface EmployeeCardProps {
  employee?: Employee;
  employeeId?: string;
  editQuestionnaire: boolean;
  setEditQuestionnaire: (value: boolean) => void;
  employment?: EmploymentTypeEnum;
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({
  employee,
  employeeId,
  employment,
  editQuestionnaire,
  setEditQuestionnaire,
}) => {
  const [employmentType, setEmploymentType] = useState<string>(
    employment || "",
  );
  const [showEmploymentTypeOptions, setShowEmploymentTypeOptions] =
    useState(false);
  const [showMainButtons, setShowMainButtons] = useState(true);
  const [showLinkButton, setShowLinkButton] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [showTitle, setShowTitle] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [currentTitle, setCurrentTitle] = useState<string>("");
  const [sendQuestionnaireLink] = useSendQuestionnaireLinkMutation();

  const handleSendLink = async (employee: Employee) => {
    try {
      const result = await sendQuestionnaireLink({
        employee: {
          ...employee,
        },
        employment_type: employmentType,
      }).unwrap();
      console.log("Erstellt:", result);
      setShowLinkButton(false);
      setShowMainButtons(true);
      setShowEmploymentTypeOptions(false);
    } catch (error) {
      console.error("Fehler beim Senden des Links:", error);
    }
  };

  const handleCloseEdit = () => {
    setShowMainButtons(true);
    setEditQuestionnaire(false);
  };

  const handleEmploymentTypeChange = (value: string) => {
    setEmploymentType(value);
    setShowLinkButton(true);
  };

  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };

  const handleButtonClick = (title: string) => {
    setShowMainButtons(false);
    setShowEmploymentTypeOptions(true);
    setShowLinkButton(true);
    setShowTitle(true);
    setTitle(title);
  };

  const handleLink = () => {
    setShowLinkButton(false);
    setShowEmploymentTypeOptions(false);
    setShowMainButtons(true);
  };

  const handleEdit = () => {
    setShowLinkButton(false);
    setShowEmploymentTypeOptions(false);
    setShowMainButtons(false);
    setEditQuestionnaire(true);
  };

  if (!employee) return null;

  return (
    <VerticalFlex className={cn("sticky top-[74px]")}>
      <Card
        className={` ${editQuestionnaire ? "w-full" : "w-[1/3]"} h-auto border-none shadow active:border-2`}
      >
        <CardHeader className={"sticky top-0 mb-2 w-full pb-0"}>
          <VerticalFlex>
            <div className={"flex"}>
              <div
                className={`grid w-full ${editQuestionnaire ? "grid-cols-[1fr,2fr,1fr,2fr]" : "grid-cols-[1fr,2fr]"} text-sm`}
              >
                <span className={"w-1/3"}>
                  {payrollTranslation("universal.firstName")}:
                </span>
                <span>{employee.firstName}</span>
                <span className={"w-1/3"}>
                  {payrollTranslation("universal.lastName")}:
                </span>
                <span>{employee.lastName}</span>
                <span className={"w-1/3"}>
                  {payrollTranslation("universal.email")}:
                </span>
                <span>{employee.email}</span>
                <span className={"w-1/3"}>
                  {payrollTranslation("universal.personnelNumber")}:
                </span>
                <span>{employee.personnelNumber}</span>
              </div>
              {editQuestionnaire && (
                <Button type="button" size={"icon"} onClick={handleCloseEdit}>
                  <X />
                </Button>
              )}
            </div>

            <HorizontalGradientLine />
          </VerticalFlex>
        </CardHeader>

        <div className={"mb-2 pt-2"}>
          <CardContent className={"flex flex-1 flex-col text-sm"}>
            {showLinkButton && showTitle && (
              <div className={"mt-2 grid grid-cols-[1fr_auto] gap-4"}>
                <div className={"mr-4 flex items-center border-b-2"}>
                  <h2 className={"text-base"}>{payrollTranslation(title)}</h2>
                </div>
                <div className={"flex items-center justify-end"}>
                  <Button
                    variant={"outline"}
                    size={"icon"}
                    className={"flex justify-center"}
                    onClick={handleLink}
                  >
                    <X />
                  </Button>
                </div>
              </div>
            )}

            {showMainButtons && (
              <MainButtons
                onButtonClick={handleButtonClick}
                onEdit={handleEdit}
              />
            )}

            {showEmploymentTypeOptions && (
              <EmploymentTypeOptions
                employmentType={employmentType}
                onEmploymentTypeChange={handleEmploymentTypeChange}
                onSendLink={() => handleSendLink(employee)}
                showLinkButton={showLinkButton}
              />
            )}

            {editQuestionnaire ? (
              <div className={"mt-2 grid h-auto w-full grid-cols-2 gap-4"}>
                <div>
                  <EmployerForm
                    userType={UserTypeEnum.EMPLOYER}
                    employment={employment}
                    employee={employee}
                    setCurrentTitle={setCurrentTitle}
                  />
                </div>

                <div>
                  <EmployeeData
                    employeeId={employeeId}
                    currentTitle={currentTitle}
                  />
                </div>
                <PrintPreview employee={employee} />
              </div>
            ) : (
              <div className={"mt-4"}>
                <HorizontalGradientLine />
                <Timeline employee={employee} />
              </div>
            )}
          </CardContent>
        </div>

        <CardFooter></CardFooter>
      </Card>
    </VerticalFlex>
  );
};

export default EmployeeCard;
