import {
  Employee,
  EmployeeEvent,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

export const useEmploymentType = (employee: Employee) => {
  if (!employee) {
    return { employeeData: null, employmentType: null };
  }

  const employeeData = employee.events?.find(
    (event: EmployeeEvent) => event?.sendQuestionnaireEvent,
  )?.sendQuestionnaireEvent;

  if (!employeeData) {
    console.error("Kein sendQuestionnaireEvent gefunden.");
    return { employeeData: null, employmentType: null };
  }

  const employmentType = employeeData?.employment_type;

  if (!employmentType) {
    console.error("Kein employment_type im sendQuestionnaireEvent gefunden.");
  }

  return { employeeData, employmentType };
};
