import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Button } from "@/components/ui/button";
import RadioFormField from "@/feature/payroll/components/formFields/RadioFormField";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";

import { EmploymentTypeEnum } from "@/feature/payroll/MultiStepGeneral";
import { Plus, X } from "lucide-react";

import { useParams } from "react-router";
import { useGetEmployeeQuestionnaireQuery } from "@/api/endpoints/payrollApi";

export function TaxableEmployment(
  { form, employee }: QuestionnaireProps,
  employeeId: string,
) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "taxableEmployment.previousEmployment",
  });

  const [employmentType, setEmploymentType]=useState<EmploymentTypeEnum>();

  const { token } = useParams<{ token: string }>();
  const { data, error } = useGetEmployeeQuestionnaireQuery<any>({
    employeeToken: token!,
  });

  useEffect(() => {
    setEmploymentType(data?.sendQuestionnaireEvent?.employment_type)
  }, [data]);


  return (
    <div className="gap-4 rounded-xl border bg-[#1b1b1b] p-4">
      <div>
        {fields.map((field, index) => (
          <div key={field.id} className="mb-4 flex gap-4">
            <div className="grid flex-grow gap-4 md:grid-cols-2 lg:grid-cols-4">
              <DateInputField
                control={form.control}
                name={`taxableEmployment.previousEmployment.${index}.dateFrom`}
                labelKey={questionnaireTranslation("taxableCard.from")}
              />

              <DateInputField
                control={form.control}
                name={`taxableEmployment.previousEmployment.${index}.dateUntil`}
                labelKey={questionnaireTranslation("taxableCard.until")}
              />

              <FormSimpleNumber
                form={form}
                name={`taxableEmployment.previousEmployment.${index}.daysOccupation`}
                label={questionnaireTranslation("taxableCard.daysOccupation")}
                labelClassName={"text-white"}
              />

              <FormSimpleText
                form={form}
                name={`taxableEmployment.previousEmployment.${index}.typeOccupation`}
                label={questionnaireTranslation("taxableCard.typeOccupation")}
              />
            </div>
            <div className="mt-4 flex items-center justify-center sm:items-end lg:mb-2">
              <Button
                type="button"
                variant="secondary"
                size={"icon"}
                onClick={() => remove(index)}
              >
                <X />
              </Button>
            </div>
          </div>
        ))}

        <Button
          type="button"
          size={"icon"}
          variant={"secondary"}
          className={"mb-4"}
          onClick={() =>
            append({
              dateFrom: "",
              dateUntil: "",
              typeOccupation: "",
              daysOccupation: undefined,
            })
          }
        >
          <Plus />
        </Button>
      </div>

      {employmentType === EmploymentTypeEnum.MINIJOB && (
        <RadioFormField
          name={"taxableEmployment.miniJobberLimit.moneyLimit"}
          control={form.control}
          labelKey={questionnaireTranslation("taxableCard.moneyLimit")}
          options={[
            { label: payrollTranslation("universal.yes"), value: "yes" },
            { label: payrollTranslation("universal.no"), value: "no" },
          ]}
        />
      )}

      {/*<div className={"col-span-4"}>
        <InfoCard
          infoText={
            <>
              Hier kannst du Angaben zur steuerpflichtigen Vorbeschäftigung
              machen.
            </>
          }
        />
      </div>*/}
    </div>
  );
}
