import React, { useEffect } from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import RadioFormField from "@/feature/payroll/components/formFields/RadioFormField";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";
import {
  EmploymentTypeEnum,
  UserTypeEnum,
} from "@/feature/payroll/MultiStepGeneral";
import { GenericComboBox } from "@/components/form/GenericComboBox";
import privateHealthInsurance from "@/feature/payroll/privateHealthInsurance.json";
import statutoryHealthInsurance from "@/feature/payroll/statutoryHealthInsurance.json";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { useGetEmployeeQuestionnaireQuery } from "@/api/endpoints/payrollApi";

export function SocialSecurity({
  form,
  userType,
  employee,
}: QuestionnaireProps) {
  const { token } = useParams<{ token: string }>();

  const { data } = useGetEmployeeQuestionnaireQuery<any>(
    { employeeToken: token || "" },
    { skip: !token },
  );

  const employmentType = data?.sendQuestionnaireEvent?.employment_type;

  const { control, setValue } = form;
  const currentHealthInsurance = useWatch({
    control,
    name: "socialSecurity.currentHealthInsurance",
  });

  const healthInsuranceOptions = [
    ...(statutoryHealthInsurance.statutory || []).map((insurance) => ({
      label: insurance.label,
      value: insurance.value,
    })),
    ...(privateHealthInsurance.private || []).map((insurance) => ({
      label: insurance.label,
      value: insurance.value,
    })),
  ];

  const checkInsuranceType = (selectedInsurance: string) => {
    const isPrivate = privateHealthInsurance.private.some(
      (insurance) => insurance.value === selectedInsurance,
    );
    const isStatutory = statutoryHealthInsurance.statutory.some(
      (insurance) => insurance.value === selectedInsurance,
    );

    if (isPrivate) {
      return "private";
    } else if (isStatutory) {
      return "statutory";
    } else {
      return "unknown";
    }
  };

  const insuranceType = currentHealthInsurance
    ? checkInsuranceType(currentHealthInsurance)
    : "unknown";

  useEffect(() => {
    if (currentHealthInsurance) {
      setValue("socialSecurity.currentHealthInsurance", currentHealthInsurance);
    }
  }, [currentHealthInsurance, setValue]);

  return (
    <div className="mb-2 rounded-xl border bg-[#1b1b1b] p-4 sm:max-w-[80vw]">
      <h2 className="mb-4 border-b-2 border-b-zinc-700 text-sm">
        {questionnaireTranslation(
          "socialSecurityCard.securityData",
        )}
      </h2>
      {userType === UserTypeEnum.EMPLOYEE ? (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          <GenericComboBox
            className={"overflow-y-hidden"}
            form={form}
            name="socialSecurity.currentHealthInsurance"
            label={questionnaireTranslation(
              "socialSecurityCard.currentHealthInsurance",
            )}
            options={healthInsuranceOptions}
          />

          {insuranceType === "private" && (
            <GenericComboBox
              form={form}
              name="socialSecurity.statutoryHealthInsurance"
              label={questionnaireTranslation(
                "socialSecurityCard.statutoryHealthInsurance",
              )}
              options={(statutoryHealthInsurance.statutory || []).map(
                (insurance) => ({
                  label: insurance.label,
                  value: insurance.value,
                }),
              )}
            />
          )}

          <RadioFormField
            hoverInfoTitle={questionnaireTranslation(
              "socialSecurityCard.parentProperty",
            )}
            hoverInfoDescription={questionnaireTranslation(
              "socialSecurityCard.infoCard.infoParentProperty",
            )}
            name="socialSecurity.parentProperty"
            control={form.control}
            labelKey={questionnaireTranslation(
              "socialSecurityCard.parentProperty",
            )}
            options={[
              { label: payrollTranslation("universal.true"), value: "yes" },
              { label: payrollTranslation("universal.false"), value: "no" },
            ]}
          />

          {employmentType === EmploymentTypeEnum.MINIJOB && (
            <RadioFormField
              hoverInfoTitle={questionnaireTranslation(
                "socialSecurityCard.miniJobberSecurity.freePensionInsurance",
              )}
              hoverInfoDescription={questionnaireTranslation(
                "socialSecurityCard.infoCard.infoFreePensionInsurance",
              )}
              name={"socialSecurity.miniJobberSecurity.freePensionInsurance"}
              control={form.control}
              labelKey={questionnaireTranslation(
                "socialSecurityCard.miniJobberSecurity.freePensionInsurance",
              )}
              options={[
                { label: payrollTranslation("universal.true"), value: "yes" },
                {
                  label: payrollTranslation("universal.false"),
                  value: "no",
                },
              ]}
            />
          )}

          {/*<div className="md:col-span-2 lg:col-span-4">
            <InfoCard
              infoText={
                <>
                  Hier kannst du Angaben zu deiner Krankenversicherung machen.
                </>
              }
            />
          </div>*/}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4">
          <SelectFormField
            hoverInfoTitle={questionnaireTranslation(
              "socialSecurityCard.insuranceKey",
            )}
            hoverInfoDescription={questionnaireTranslation(
              "socialSecurityCard.infoCard.infoInsuranceKey",
            )}
            labelKey={questionnaireTranslation(
              "socialSecurityCard.insuranceKey",
            )}
            name={"socialSecurity.insuranceKey"}
            control={form.control}
            options={[
              {
                label: "Kurzfristige Beschäftigung",
                value: "0000_shortTermJob",
              },
              {
                label: "Studentisches Zwischenpraktikum",
                value: "0000_internship",
              },
              {
                label: "Versicherungspflichtiger Arbeitnehmer",
                value: "1111_employee",
              },
              { label: "Auszubildender", value: "1111_trainee" },
              {
                label:
                  "Arbeitnehmer freiwillig gesetzlich versichert - Selbstzahler",
                value: "0111",
              },
              {
                label:
                  "Arbeitnehmer freiwillig gesetzlich versichert - Firmenzahler",
                value: "9111",
              },
              { label: "Arbeitnehmer privat versichert", value: "0110" },
              { label: "Schüler", value: "1101" },
              { label: "Werkstudent", value: "0100" },
              {
                label: "SV-pflichtige mit berufsständischer Versorgung",
                value: "1011",
              },
              { label: "Geringfügig entlohnte Beschäftigung", value: "6500" },
              {
                label:
                  "Geringfügig entlohnte Beschäftigung mit Rentenversicherungspflicht",
                value: "6100",
              },
              {
                label: "Vollrente vor Erreichen der Regelaltersgrenze",
                value: "3111",
              },
              {
                label: "Vollrente nach Vollendung der Regelaltersgrenze",
                value: "3321",
              },
              {
                label:
                  "Vollrente nach Vollendung der Regelaltersgrenze ohne Versicherungsfreiheit in der Rentenversicherung",
                value: "3121",
              },
            ]}
          />

          <SelectFormField
            hoverInfoTitle={questionnaireTranslation(
              "socialSecurityCard.status",
            )}
            hoverInfoDescription={questionnaireTranslation(
              "socialSecurityCard.infoCard.infoDeuevStatus",
            )}
            control={control}
            defaultValue="0"
            name="socialSecurity.deuevStatus"
            labelKey={questionnaireTranslation("socialSecurityCard.status")}
            options={[
              {
                label: questionnaireTranslation(
                  "socialSecurityCard.statusValues.0",
                ),
                value: "0",
              },
              {
                label: questionnaireTranslation(
                  "socialSecurityCard.statusValues.1",
                ),
                value: "1",
              },
              {
                label: questionnaireTranslation(
                  "socialSecurityCard.statusValues.2",
                ),
                value: "2",
              },
            ]}
          />

          {/*<div className="col-span-4">
            <InfoCard
              infoText={
                <>
                  Hier kannst du Angaben zur Krankenversicherung deines
                  Mitarbeiters machen.
                </>
              }
            />
          </div>*/}
        </div>
      )}
    </div>
  );
}


