import React from "react";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";

const ChildrenDetails: React.FC<{
  children: QuestionnaireEmployee["children"];
}> = ({ children }) => {
  return (
    <section className={"mb-8 font-light"}>
      <h3 className="mb-4 border-b-2 border-b-zinc-700 text-base font-semibold">
        {questionnaireTranslation("childCard.kids")}
      </h3>
      {children.length > 0 ? (
        children.map((child, index) => (
          <div
            key={index}
            className="mb-4 grid grid-cols-2 gap-4 md:grid-cols-4"
          >
            <div className="col-span-1 font-medium">
              {payrollTranslation("universal.lastName")}:
            </div>
            <div className="col-span-1">{child.lastName || "-"}</div>
            <div className="col-span-1 font-medium">
              {payrollTranslation("universal.firstName")}:
            </div>
            <div className="col-span-1">{child.firstName || "-"}</div>
            <div className="col-span-1 font-medium">
              {payrollTranslation("universal.birthDate")}:
            </div>
            <div className="col-span-1">
              {" "}
              {child.birthDate
                ? new Date(child.birthDate).toLocaleDateString("de-DE")
                : "-"}
            </div>
            <HorizontalGradientLine className={"col-span-4"} />
          </div>
        ))
      ) : (
        <p>{questionnaireTranslation("childCard.noChild")}</p>
      )}
    </section>
  );
};

export default ChildrenDetails;
