import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { FormSchemaAbsence } from "@/feature/payroll/forms/absence/FormSchemaAbsence";
import { Step } from "@/feature/payroll/MultiStepGeneral";

export const StepsAbsence = (
  form: UseFormReturn<z.infer<typeof FormSchemaAbsence>>,
): Step<typeof FormSchemaAbsence>[] => {
  return [
    /*{
      title: payrollTranslation("universal.generalInfo"),
      icon: <Briefcase className="h-5 w-5" />,
      component: <GeneralInfoAbsence form={form} />,
      fields: [
        "generalDetails.personnelNumber",
        "generalDetails.firstName",
        "generalDetails.lastName",
      ],
    },
    {
      icon: <Syringe className="h-5 w-5" />,
      title: absenceTranslation("sicknessCard.sickness"),
      component: <Sickness form={form} />,
      fields: [],
    },
    {
      icon: <Baby className="h-5 w-5" />,
      title: absenceTranslation("childCareCard.childCare"),
      component: <ChildCare form={form} />,
      fields: [],
    },
    {
      icon: <SmilePlus className="h-5 w-5" />,
      title: absenceTranslation("maternityCard.maternity"),
      component: <Maternity form={form} />,
      fields: [],
    },
    {
      icon: <CalendarOff className="h-5 w-5" />,
      title: absenceTranslation("additionalCard.additionalAbsence"),
      component: <AdditionalAbsence form={form} />,
      fields: [],
    },*/
  ];
};