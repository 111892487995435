import { useSelector } from "react-redux";
import { RootState } from "@/api/store";
import {
  EmploymentTypeEnum,
  Step,
  UserTypeEnum,
} from "@/feature/payroll/MultiStepGeneral";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { StepsQuestionnaire } from "@/feature/payroll/forms/questionnaire/steps/QuestionnaireSteps";
import { FormSchemaComplete } from "@/feature/payroll/forms/questionnaire/schema/QuestionnaireSchema";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

export const FilterStepsQuestionnaire = (
  form: UseFormReturn<z.infer<typeof FormSchemaComplete>>,
  userType: UserTypeEnum,
  employment: EmploymentTypeEnum,
  employee: Employee,
): Step<typeof FormSchemaComplete>[] => {
  const { hasChildren, hasVwl, hasTaxableEmployment } = useSelector(
    (state: RootState) => state.questionnaire,
  );

  const steps = StepsQuestionnaire(form, userType, employment, employee);

  return steps.filter((step) => {
    const shouldBeDisplayed = step.showForType?.includes(userType);

    if (shouldBeDisplayed) {
      if (!hasChildren && step.filter?.hasChildren) {
        return false;
      }
      if (!hasVwl && step.filter?.hasVwl) {
        return false;
      }
      if (!hasTaxableEmployment && step.filter?.hasTaxableEmployment) {
        return false;
      }
    }

    return shouldBeDisplayed;
  });
};
