import React from "react";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { QuestionnaireEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { payrollTranslation, questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";

type PreviousType =
  QuestionnaireEmployee["taxableEmployment"]["previousEmployment"][number];

const TaxableData: React.FC<{ taxableEmployment: QuestionnaireEmployee["taxableEmployment"] }> = ({
  taxableEmployment,
}) => {
  return (
    <section className="mb-8">
      <h3 className="mb-4 border-b-2 border-b-zinc-700 text-base font-semibold">
        {questionnaireTranslation("taxableCard.taxableEmployment")}
      </h3>
      <div className="grid grid-cols-4 gap-4">
        {Array.isArray(taxableEmployment?.previousEmployment) &&
        taxableEmployment.previousEmployment.length > 0
          ? taxableEmployment.previousEmployment.map(
              (employment: PreviousType, index: number) => (
                <React.Fragment key={index}>
                  <div className={"col-span-4 mb-4 grid grid-cols-4 gap-4"}>
                    <div className="font-semibold">{questionnaireTranslation("taxableCard.fromUntil")}:</div>
                    {employment.dateFrom
                      ? new Date(employment.dateFrom).toLocaleDateString(
                          "de-DE",
                        )
                      : undefined}{" "}
                    -{" "}
                    {employment.dateUntil
                      ? new Date(employment.dateUntil).toLocaleDateString(
                          "de-DE",
                        )
                      : undefined}
                    <div className="font-semibold">{questionnaireTranslation("taxableCard.typeOccupation")}:</div>
                    <div>{employment.typeOccupation || "-"}</div>
                    <div className="font-semibold">{questionnaireTranslation("taxableCard.daysOccupation")}</div>
                    <div>{employment.daysOccupation || "-"}</div>
                    <HorizontalGradientLine className={"col-span-4"} />
                  </div>
                </React.Fragment>
              ),
            )
          : ""}

        <div className="font-semibold col-span-2 ">{questionnaireTranslation("taxableCard.moneyLimit")}:</div>
        <div >
          {taxableEmployment?.miniJobberLimit?.moneyLimit
            ? payrollTranslation(`universal.${taxableEmployment?.miniJobberLimit?.moneyLimit}`)
            : "-"}
        </div>
      </div>
    </section>
  );
};

export default TaxableData;
