// doc FormSimpleMoney.tsx
import { FieldValues } from "react-hook-form/dist/types/fields";
import {
  Control,
  ControllerRenderProps,
  FieldPath,
} from "react-hook-form/dist/types";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import React, { ReactNode, useEffect } from "react";
import { cn } from "@/lib/utils";
import { HoverInfo } from "@/feature/settings/UserSettings/form/info/hoverInfo";

export interface FormSimpleNumberProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> {
  form: { control: Control<T> };
  name: TName;
  className?: string;
  label?: string;
  displayClassName?: string;
  labelClassName?: string;
  itemClassName?: string;
  prefix?: (value: number) => ReactNode;
  postfix?: (value: number) => ReactNode;
  valueDisplay?: (value: number) => string;
  valueChangePreprocess?: (value: string) => string;
  parseNumber?: (value: string) => number;
  placeholder?: string;
  hidden?: boolean;
  disabled?: boolean;
  hoverInfoTitle?: string;
  hoverInfoDescription?: string;
}

export const FormSimpleNumber = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  props: FormSimpleNumberProps<T, TName>,
) => {
  if (props.hidden) return null;
  return (
    <FormField
      control={props.form.control}
      name={props.name}
      render={({ field }) => <InnerNumber {...props} field={field} />}
    />
  );
};

export const InnerNumber = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  field,
  label,
  ...props
}: FormSimpleNumberProps<T, TName> & {
  field: ControllerRenderProps<T, TName>;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [focus, setFocus] = React.useState(false);
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (!focus) {
      if (field.value === 0 || field.value === null) {
        setValue("");
      }
    }
  }, [focus, value]);

  return (
    <FormItem className={cn("min-w-40", props.itemClassName)}>
      <div className="flex items-center">
        {label && (
          <FormLabel
            className={cn("mr-2 text-muted-foreground", props.labelClassName)}
          >
            {label}
          </FormLabel>
        )}
        {props.hoverInfoTitle && props.hoverInfoDescription && (
          <HoverInfo
            title={props.hoverInfoTitle}
            description={props.hoverInfoDescription}
            align="start"
          />
        )}
      </div>

      <FormControl>
        <div
          onClick={() => {
            inputRef.current?.focus();
          }}
          className={cn(
            "flex h-9 min-w-40 flex-1 rounded-md border border-input bg-transparent px-3 py-1 align-baseline text-sm shadow-sm transition-colors",
            focus ? "border-primary" : "border-input",
            props.className,
          )}
        >
          {props.prefix && props.prefix(field.value)}
          <input
            ref={inputRef}
            className={cn(
              "min-w-16 flex-1 border-none bg-transparent text-right outline-none placeholder:text-muted-foreground",
              field.value < 0 ? "text-red-300" : "",
              props.className,
              props.disabled ? "opacity-50" : "",
            )}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            disabled={props.disabled}
            value={
              focus
                ? value
                : props.valueDisplay
                  ? props.valueDisplay(field.value)
                  : field.value
            }
            onChange={(e) => {
              let value = (e.target.value + "").replaceAll(/[^0-9,\-]/g, "");
              setValue(value);
              field.onChange(
                props.parseNumber ? props.parseNumber(value) : parse(value),
              );
            }}
            placeholder={props.placeholder}
          />
          {props.postfix && props.postfix(field.value)}
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};

function parse(value: string): number {
  let v = parseFloat(value.replaceAll(",", "."));
  if (isNaN(v) || !isFinite(v)) {
    return 0;
  }
  return v;
}
