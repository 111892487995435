import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { t } from "i18next";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { GenericComboBox } from "@/components/form/GenericComboBox";

const countryTranslation = (key: string) => t(`countries.countryName.${key}`);

const COUNTRIES = [
  { label: countryTranslation("afghanistan"), value: "afghanistan" },
  { label: countryTranslation("albania"), value: "albania" },
  { label: countryTranslation("algeria"), value: "algeria" },
  { label: countryTranslation("andorra"), value: "andorra" },
  { label: countryTranslation("angola"), value: "angola" },
  {
    label: countryTranslation("antiguaAndBarbuda"),
    value: "antiguaAndBarbuda",
  },
  { label: countryTranslation("argentina"), value: "argentina" },
  { label: countryTranslation("armenia"), value: "armenia" },
  { label: countryTranslation("australia"), value: "australia" },
  { label: countryTranslation("austria"), value: "austria" },
  { label: countryTranslation("azerbaijan"), value: "azerbaijan" },
  { label: countryTranslation("bahamas"), value: "bahamas" },
  { label: countryTranslation("bahrain"), value: "bahrain" },
  { label: countryTranslation("bangladesh"), value: "bangladesh" },
  { label: countryTranslation("barbados"), value: "barbados" },
  { label: countryTranslation("belarus"), value: "belarus" },
  { label: countryTranslation("belgium"), value: "belgium" },
  { label: countryTranslation("belize"), value: "belize" },
  { label: countryTranslation("benin"), value: "benin" },
  { label: countryTranslation("bhutan"), value: "bhutan" },
  { label: countryTranslation("bolivia"), value: "bolivia" },
  {
    label: countryTranslation("bosniaAndHerzegovina"),
    value: "bosniaAndHerzegovina",
  },
  { label: countryTranslation("botswana"), value: "botswana" },
  { label: countryTranslation("brazil"), value: "brazil" },
  { label: countryTranslation("brunei"), value: "brunei" },
  { label: countryTranslation("bulgaria"), value: "bulgaria" },
  { label: countryTranslation("burkinaFaso"), value: "burkinaFaso" },
  { label: countryTranslation("burundi"), value: "burundi" },
  { label: countryTranslation("caboVerde"), value: "caboVerde" },
  { label: countryTranslation("cambodia"), value: "cambodia" },
  { label: countryTranslation("cameroon"), value: "cameroon" },
  { label: countryTranslation("canada"), value: "canada" },
  {
    label: countryTranslation("centralAfricanRepublic"),
    value: "centralAfricanRepublic",
  },
  { label: countryTranslation("chad"), value: "chad" },
  { label: countryTranslation("chile"), value: "chile" },
  { label: countryTranslation("china"), value: "china" },
  { label: countryTranslation("colombia"), value: "colombia" },
  { label: countryTranslation("comoros"), value: "comoros" },
  { label: countryTranslation("congo"), value: "congo" },
  { label: countryTranslation("costaRica"), value: "costaRica" },
  { label: countryTranslation("coteDIvoire"), value: "coteDIvoire" },
  { label: countryTranslation("croatia"), value: "croatia" },
  { label: countryTranslation("cuba"), value: "cuba" },
  { label: countryTranslation("cyprus"), value: "cyprus" },
  { label: countryTranslation("czechRepublic"), value: "czechRepublic" },
  { label: countryTranslation("denmark"), value: "denmark" },
  { label: countryTranslation("djibouti"), value: "djibouti" },
  { label: countryTranslation("dominica"), value: "dominica" },
  {
    label: countryTranslation("dominicanRepublic"),
    value: "dominicanRepublic",
  },
  { label: countryTranslation("ecuador"), value: "ecuador" },
  { label: countryTranslation("egypt"), value: "egypt" },
  { label: countryTranslation("elSalvador"), value: "elSalvador" },
  { label: countryTranslation("equatorialGuinea"), value: "equatorialGuinea" },
  { label: countryTranslation("eritrea"), value: "eritrea" },
  { label: countryTranslation("estonia"), value: "estonia" },
  { label: countryTranslation("eswatini"), value: "eswatini" },
  { label: countryTranslation("ethiopia"), value: "ethiopia" },
  { label: countryTranslation("fiji"), value: "fiji" },
  { label: countryTranslation("finland"), value: "finland" },
  { label: countryTranslation("france"), value: "france" },
  { label: countryTranslation("gabon"), value: "gabon" },
  { label: countryTranslation("gambia"), value: "gambia" },
  { label: countryTranslation("georgia"), value: "georgia" },
  { label: countryTranslation("germany"), value: "germany" },
  { label: countryTranslation("ghana"), value: "ghana" },
  { label: countryTranslation("greece"), value: "greece" },
  { label: countryTranslation("grenada"), value: "grenada" },
  { label: countryTranslation("guatemala"), value: "guatemala" },
  { label: countryTranslation("guinea"), value: "guinea" },
  { label: countryTranslation("guineaBissau"), value: "guineaBissau" },
  { label: countryTranslation("guyana"), value: "guyana" },
  { label: countryTranslation("haiti"), value: "haiti" },
  { label: countryTranslation("honduras"), value: "honduras" },
  { label: countryTranslation("hungary"), value: "hungary" },
  { label: countryTranslation("iceland"), value: "iceland" },
  { label: countryTranslation("india"), value: "india" },
  { label: countryTranslation("indonesia"), value: "indonesia" },
  { label: countryTranslation("iran"), value: "iran" },
  { label: countryTranslation("iraq"), value: "iraq" },
  { label: countryTranslation("ireland"), value: "ireland" },
  { label: countryTranslation("israel"), value: "israel" },
  { label: countryTranslation("italy"), value: "italy" },
  { label: countryTranslation("jamaica"), value: "jamaica" },
  { label: countryTranslation("japan"), value: "japan" },
  { label: countryTranslation("jordan"), value: "jordan" },
  { label: countryTranslation("kazakhstan"), value: "kazakhstan" },
  { label: countryTranslation("kenya"), value: "kenya" },
  { label: countryTranslation("kiribati"), value: "kiribati" },
  { label: countryTranslation("koreaNorth"), value: "koreaNorth" },
  { label: countryTranslation("koreaSouth"), value: "koreaSouth" },
  { label: countryTranslation("kuwait"), value: "kuwait" },
  { label: countryTranslation("kyrgyzstan"), value: "kyrgyzstan" },
  { label: countryTranslation("laos"), value: "laos" },
  { label: countryTranslation("latvia"), value: "latvia" },
  { label: countryTranslation("lebanon"), value: "lebanon" },
  { label: countryTranslation("lesotho"), value: "lesotho" },
  { label: countryTranslation("liberia"), value: "liberia" },
  { label: countryTranslation("libya"), value: "libya" },
  { label: countryTranslation("liechtenstein"), value: "liechtenstein" },
  { label: countryTranslation("lithuania"), value: "lithuania" },
  { label: countryTranslation("luxembourg"), value: "luxembourg" },
  { label: countryTranslation("madagascar"), value: "madagascar" },
  { label: countryTranslation("malawi"), value: "malawi" },
  { label: countryTranslation("malaysia"), value: "malaysia" },
  { label: countryTranslation("maldives"), value: "maldives" },
  { label: countryTranslation("mali"), value: "mali" },
  { label: countryTranslation("malta"), value: "malta" },
  { label: countryTranslation("marshallIslands"), value: "marshallIslands" },
  { label: countryTranslation("mauritania"), value: "mauritania" },
  { label: countryTranslation("mauritius"), value: "mauritius" },
  { label: countryTranslation("mexico"), value: "mexico" },
  { label: countryTranslation("micronesia"), value: "micronesia" },
  { label: countryTranslation("moldova"), value: "moldova" },
  { label: countryTranslation("monaco"), value: "monaco" },
  { label: countryTranslation("mongolia"), value: "mongolia" },
  { label: countryTranslation("montenegro"), value: "montenegro" },
  { label: countryTranslation("morocco"), value: "morocco" },
  { label: countryTranslation("mozambique"), value: "mozambique" },
  { label: countryTranslation("myanmar"), value: "myanmar" },
  { label: countryTranslation("namibia"), value: "namibia" },
  { label: countryTranslation("nauru"), value: "nauru" },
  { label: countryTranslation("nepal"), value: "nepal" },
  { label: countryTranslation("netherlands"), value: "netherlands" },
  { label: countryTranslation("newZealand"), value: "newZealand" },
  { label: countryTranslation("nicaragua"), value: "nicaragua" },
  { label: countryTranslation("niger"), value: "niger" },
  { label: countryTranslation("nigeria"), value: "nigeria" },
  { label: countryTranslation("northMacedonia"), value: "northMacedonia" },
  { label: countryTranslation("norway"), value: "norway" },
  { label: countryTranslation("oman"), value: "oman" },
  { label: countryTranslation("pakistan"), value: "pakistan" },
  { label: countryTranslation("palau"), value: "palau" },
  { label: countryTranslation("palestine"), value: "palestine" },
  { label: countryTranslation("panama"), value: "panama" },
  { label: countryTranslation("papuaNewGuinea"), value: "papuaNewGuinea" },
  { label: countryTranslation("paraguay"), value: "paraguay" },
  { label: countryTranslation("peru"), value: "peru" },
  { label: countryTranslation("philippines"), value: "philippines" },
  { label: countryTranslation("poland"), value: "poland" },
  { label: countryTranslation("portugal"), value: "portugal" },
  { label: countryTranslation("qatar"), value: "qatar" },
  { label: countryTranslation("romania"), value: "romania" },
  { label: countryTranslation("russia"), value: "russia" },
  { label: countryTranslation("rwanda"), value: "rwanda" },
  {
    label: countryTranslation("saintKittsAndNevis"),
    value: "saintKittsAndNevis",
  },
  { label: countryTranslation("saintLucia"), value: "saintLucia" },
  {
    label: countryTranslation("saintVincentAndTheGrenadines"),
    value: "saintVincentAndTheGrenadines",
  },
  { label: countryTranslation("samoa"), value: "samoa" },
  { label: countryTranslation("sanMarino"), value: "sanMarino" },
  {
    label: countryTranslation("saoTomeAndPrincipe"),
    value: "saoTomeAndPrincipe",
  },
  { label: countryTranslation("saudiArabia"), value: "saudiArabia" },
  { label: countryTranslation("senegal"), value: "senegal" },
  { label: countryTranslation("serbia"), value: "serbia" },
  { label: countryTranslation("singapore"), value: "singapore" },
  { label: countryTranslation("slovakia"), value: "slovakia" },
  { label: countryTranslation("slovenia"), value: "slovenia" },
  { label: countryTranslation("solomonIslands"), value: "solomonIslands" },
  { label: countryTranslation("somalia"), value: "somalia" },
  { label: countryTranslation("southAfrica"), value: "southAfrica" },
  { label: countryTranslation("southSudan"), value: "southSudan" },
  { label: countryTranslation("spain"), value: "spain" },
  { label: countryTranslation("sriLanka"), value: "sriLanka" },
  { label: countryTranslation("sudan"), value: "sudan" },
  { label: countryTranslation("suriname"), value: "suriname" },
  { label: countryTranslation("sweden"), value: "sweden" },
  { label: countryTranslation("switzerland"), value: "switzerland" },
  { label: countryTranslation("syria"), value: "syria" },
  { label: countryTranslation("taiwan"), value: "taiwan" },
  { label: countryTranslation("tajikistan"), value: "tajikistan" },
  { label: countryTranslation("tanzania"), value: "tanzania" },
  { label: countryTranslation("thailand"), value: "thailand" },
  { label: countryTranslation("timorLeste"), value: "timorLeste" },
  { label: countryTranslation("togo"), value: "togo" },
  { label: countryTranslation("tonga"), value: "tonga" },
  {
    label: countryTranslation("trinidadAndTobago"),
    value: "trinidadAndTobago",
  },
  { label: countryTranslation("tunisia"), value: "tunisia" },
  { label: countryTranslation("turkey"), value: "turkey" },
  { label: countryTranslation("turkmenistan"), value: "turkmenistan" },
  { label: countryTranslation("tuvalu"), value: "tuvalu" },
  { label: countryTranslation("uganda"), value: "uganda" },
  { label: countryTranslation("ukraine"), value: "ukraine" },
  {
    label: countryTranslation("unitedArabEmirates"),
    value: "unitedArabEmirates",
  },
  { label: countryTranslation("unitedKingdom"), value: "unitedKingdom" },
  { label: countryTranslation("unitedStates"), value: "unitedStates" },
  { label: countryTranslation("uruguay"), value: "uruguay" },
  { label: countryTranslation("uzbekistan"), value: "uzbekistan" },
  { label: countryTranslation("vanuatu"), value: "vanuatu" },
  { label: countryTranslation("vaticanCity"), value: "vaticanCity" },
  { label: countryTranslation("venezuela"), value: "venezuela" },
  { label: countryTranslation("vietnam"), value: "vietnam" },
  { label: countryTranslation("yemen"), value: "yemen" },
  { label: countryTranslation("zambia"), value: "zambia" },
  { label: countryTranslation("zimbabwe"), value: "zimbabwe" },
];

export function CountryDropdown({ form }: QuestionnaireProps) {
  return (
    <GenericComboBox
      form={form}
      name={"personalDetails.address.country"}
      label={questionnaireTranslation("personalCard.address.country")}
      options={COUNTRIES}
    />
  );
}
