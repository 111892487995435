import { useEffect, useState } from "react";
import { NewAreaChart } from "../charts/area-chart";

export function Chart({ content }) {
  const [adaptedData, setAdaptedData] = useState([]);

  useEffect(() => {
    if (content?.chartType === "areaChart" && content?.chartData) {
      const adapted = content.chartData.map((item) => ({
        date: item.date,
        value: item.value,
      }));
      console.log(adaptedData);
      setAdaptedData(adapted);
    }
  }, [content]);

  return (
    <>
      {/* {content?.chartType === "getTotalRevenueForDateRange" && (
                <NumberCard
                    title={content?.title}
                    totalAmount={content?.incomeResult}
                    subtext={content?.startDate}
                />
            )} */}
      {content?.chartType === "areaChart" && (
        <>
          {/* <BarChartCard
                        title={content?.title}
                        // description={"TBD"}
                        data={adaptedData}
                        height={200}
                    /> */}
          {/* <AreaChartCard
                        title={content?.title}
                        data={adaptedData}
                        dateRange={{
                            from: new Date(content?.startDate),
                            to: new Date(content?.endDate)
                        }}
                    /> */}
          <NewAreaChart
            title={content?.title}
            data={adaptedData}
            dateRange={{
              from: new Date(content?.startDate),
              to: new Date(content?.endDate),
            }}
          />
        </>
      )}
    </>
  );
}
