import React from "react";
import {
  countriesTranslation,
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";

const PersonalData: React.FC<{
  personalDetails: QuestionnaireEmployee["personalDetails"];
}> = ({ personalDetails }) => {
  return (
    <section className="mb-8 font-light">
      <h3 className="mb-4 border-b-2 border-b-zinc-700 text-base font-medium">
        {" "}
        {questionnaireTranslation("personalCard.personalInfo")}:
      </h3>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <div className="col-span-1 font-medium">
          {payrollTranslation("universal.birthName")}:
        </div>
        <div className="col-span-1">{personalDetails?.birthName}</div>

        <div className="col-span-1 font-medium">
          {payrollTranslation("universal.birthDate")}:
        </div>
        <div className="col-span-1">
          {" "}
          {personalDetails?.birthDate
            ? new Date(personalDetails?.birthDate).toLocaleDateString("de-DE")
            : "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.phone")}:
        </div>
        <div className="col-span-1">{personalDetails?.phone || "-"}</div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.street")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.address?.street || "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.houseNumber")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.address?.houseNumber || "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.city")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.address?.city || "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.postalCode")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.address?.postalCode || "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.country")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.address?.country
            ? countriesTranslation(`${personalDetails?.address?.country}`)
            : "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.addressAddition")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.address?.addressAdditional || "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.birthPlace")}:
        </div>
        <div className="col-span-1">{personalDetails?.birthPlace || "-"}</div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.birthCountry")}:
        </div>
        <div className="col-span-1">{personalDetails?.birthCountry || "-"}</div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.insuranceNumber")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.insuranceNumber || "-"}
        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.severelyDisabled")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.disabled
            ? questionnaireTranslation(`personalCard.${personalDetails?.disabled}`)
            : "-"}


        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.gender")}:
        </div>
        <div className="col-span-1">
          {personalDetails?.gender
            ? questionnaireTranslation(`personalCard.${personalDetails?.gender}`)
            : "-"}

        </div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.nationality")}:
        </div>
        <div className="col-span-1">{personalDetails?.nationality || "-"}</div>

        <div className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.employeeNumber")}
        </div>
        <div className="col-span-1">
          {personalDetails?.employeeNumberSocialFund || "-"}
        </div>

        <div className="col-span-1 font-medium">
          {payrollTranslation("universal.iban")}:
        </div>
        <div className="col-span-1">{personalDetails?.iban || "-"}</div>

        <div className="col-span-1 font-medium">
          {payrollTranslation("universal.bic")}:
        </div>
        <div className="col-span-1">{personalDetails?.bic || "-"}</div>
      </div>
    </section>
  );
};

export default PersonalData;
