import React from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import PersonalData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/PersonalData";
import ChildrenData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/ChildrenData";
import EmploymentData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/EmploymentData";
import TaxData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/TaxData";
import SocialSecurityData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/SocialSecurityData";
import VwlData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/VwlData";
import TaxableData from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/TaxableData";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { payrollTranslation, questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";

export function OverviewCard({ form }: QuestionnaireProps) {
  const formValues = form.getValues();

  return (
    <div className={"flex w-full flex-wrap justify-center"}>
      <Card className="flex w-[80vw] flex-col flex-wrap">
        <CardHeader>
          <h2 className="text-lg font-bold">{payrollTranslation("universal.dataOverview")}</h2>
          <p className="text-sm text-gray-600">
            {payrollTranslation("universal.isCorrect")}
          </p>
        </CardHeader>
        <CardContent className="flex-grow overflow-auto">
          <Tabs defaultValue="personalData">
            <TabsList className={"mb-4"}>
              <TabsTrigger value="personalData">
                {questionnaireTranslation("personalCard.personalInfo")}
              </TabsTrigger>
              <TabsTrigger value="children">
                {questionnaireTranslation("childCard.kids")}
              </TabsTrigger>
              <TabsTrigger value="employment">
                {questionnaireTranslation("employmentCard.occupation")}
              </TabsTrigger>
              <TabsTrigger value="taxes">
                {questionnaireTranslation("taxCard.taxes")}
              </TabsTrigger>
              <TabsTrigger value="socialSecurity">
                {questionnaireTranslation("socialSecurityCard.socialSecurity")}
              </TabsTrigger>
              <TabsTrigger value="Vwl">
                {questionnaireTranslation("benefitsCard.benefits")}
              </TabsTrigger>
              <TabsTrigger value="Vorbeschäftigung">
                {questionnaireTranslation("taxableCard.taxableEmploymentShort")}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="personalData">
              <PersonalData personalDetails={formValues.personalDetails} />
            </TabsContent>
            <TabsContent value="children">
              <ChildrenData children={formValues.children} />
            </TabsContent>
            <TabsContent value="employment">
              <EmploymentData
                employmentDetails={formValues.employmentDetails}
              />
            </TabsContent>
            <TabsContent value="taxes">
              <TaxData taxInformation={formValues.taxInformation} />
            </TabsContent>
            <TabsContent value="socialSecurity">
              <SocialSecurityData socialSecurity={formValues.socialSecurity} />
            </TabsContent>
            <TabsContent value="Vwl">
              <VwlData vwlDetails={formValues.vwlDetails} />
            </TabsContent>
            <TabsContent value="Vorbeschäftigung">
              <TaxableData taxableEmployment={formValues.taxableEmployment} />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
}
