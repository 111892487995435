import React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import Logo from "@/components/page/components/Logo";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ForgotForm } from "@/feature/auth/components/ForgotForm";

const ForgotPassword = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-auto flex-col items-center pt-20">
      <Card className="m-auto flex max-w-xl flex-col gap-8 bg-card">
        <CardHeader className="text-center">
          <Logo></Logo>
        </CardHeader>
        <CardContent>
          <ForgotForm />
        </CardContent>
        <CardFooter>
          <div className="mx-auto mt-4 flex flex-col text-center text-sm">
            <p>{t("component.auth.reset.alreadyRegistered")}</p>
            <Link to="/login" className="underline">
              {t("component.auth.reset.actionLogin")}
            </Link>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ForgotPassword;
