import React, { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client"; // Import von createRoot
import PrintForm from "@/feature/payroll/forms/questionnaire/components/printForm/PrintForm";
import { Provider } from "react-redux";
import { store } from "@/api/store";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

const PrintPreview: React.FC<{ employee?: Employee }> = ({ employee }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current?.contentWindow?.document) {
      const iframeDoc = iframeRef.current.contentWindow.document;

      iframeDoc.open();
      iframeDoc.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <link rel="stylesheet" type="text/css" href="/payroll-print.css" />
          </head>
          <body></body>
        </html>
      `);
      iframeDoc.close();

      const root = iframeDoc.body;

      if (root) {
        const previewRoot = document.createElement("div");
        root.appendChild(previewRoot);

        const reactRoot = createRoot(previewRoot);
        reactRoot.render(
          <Provider store={store}>
            <PrintForm employee={employee} />
          </Provider>,
        );
      }
    }
  }, [employee]);

  return (
    <iframe
      title={"preview"}
      className={"size-fit h-[80vh] w-full bg-white"}
      ref={iframeRef}
    ></iframe>
  );
};

export default PrintPreview;
