import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import * as React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "@/components/modal/useModal";
import AddBankForm from "@/components/bank/AddBankForm";

export const AddBankButton: FC<{}> = () => {
  const { t } = useTranslation();

  const { showModal } = useModal();

  const showAddBankGocardlessModal = () => {
    showModal({
      title: t("component.transactions.modal.title"),
      description: t("component.transactions.modal.description"),
      content: ({ close }) => <AddBankForm close={close} />,
      className: "overflow-hidden h-[500px]",
    });
  };

  return (
    <Button className={"gap-2"} onClick={showAddBankGocardlessModal}>
      {t("component.bankSettings.addBank")}
      <Plus size={18} />
    </Button>
  );
};
