import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { EmploymentTypeEnum } from "@/feature/payroll/MultiStepGeneral";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";

export function HandleEmploymentDates({ form, employee }: QuestionnaireProps) {
  const { employmentType } = useEmploymentType(employee!);

  return (
    <div>
      {employmentType === EmploymentTypeEnum.TRAINEE ? (
        <>
          <DateInputField
            control={form.control}
            name={"employmentDetails.trainingEnd"}
            labelKey={questionnaireTranslation("employmentCard.trainingStart")}
            required={true}
          />

          <DateInputField
            control={form.control}
            name={"employmentDetails.trainingEnd"}
            labelKey={questionnaireTranslation("employmentCard.trainingEnd")}
          />
        </>
      ) : (
        <div className={"grid grid-cols-2 gap-4"}>
          <DateInputField
            control={form.control}
            name={"employmentDetails.entryDate"}
            labelKey={questionnaireTranslation("employmentCard.entryDate")}
            required={true}
          />
          <DateInputField
            control={form.control}
            name={"employmentDetails.firstEntryDate"}
            labelKey={questionnaireTranslation("employmentCard.firstEntryDate")}
          />
        </div>
      )}
    </div>
  );
}
