import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { FieldPath, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import React, { useEffect, useRef, useState } from "react";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { ControllerRenderProps } from "react-hook-form/dist/types";

interface DateFieldProps<T extends z.ZodTypeAny> {
  control: UseFormReturn<z.infer<T>>["control"];
  name: FieldPath<z.infer<T>>;
  hidden?: boolean;
  labelKey?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string | undefined) => void; // now expecting a string
}

export const DateInputField = <T extends z.ZodTypeAny>({
                                                         control,
                                                         name,
                                                         labelKey,
                                                         hidden = false,
                                                         required = false,
  disabled =false,
                                                       }: DateFieldProps<T>) => {
  if (hidden) return null;

  return (
    <div className="flex w-full flex-col">
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <FormLabel className="block text-muted-foreground">
              {labelKey}
              {required && <span> *</span>}
            </FormLabel>
            <InnerDateStuff name={name} field={field} disabled={disabled} />
            <FormMessage />
          </>
        )}
      />
    </div>
  );
};

export interface InnerDateStuffProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> {
  name: TName;
}

const InnerDateStuff = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
    field,
    disabled,
    ...props
  }: InnerDateStuffProps<T, TName> & {
  field: ControllerRenderProps<T, TName>;
  disabled?: boolean;
}) => {
  const [day, setDay] = useState<string | undefined>();
  const [month, setMonth] = useState<string | undefined>();
  const [year, setYear] = useState<string | undefined>();


  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (field.value) {
      const [y, m, d] = field.value.split("-").map(Number);
      setYear(String(y));
      setMonth(m ? String(m).padStart(2, "0") : undefined);
      setDay(d ? String(d).padStart(2, "0") : undefined);
    }
  }, [field.value]);

  useEffect(() => {
    if (day !== undefined && month !== undefined && year !== undefined) {
      const dateString = `${year}-${month}-${day}`;
      field.onChange(dateString);
    } else {
      field.onChange(null);
    }
  }, [day, month, year]);

  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (/^(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value) || value === "") {
      setDay(value);
      if (value.length === 2 && monthRef.current) {
        monthRef.current.focus();
      }
    }
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (/^(0[1-9]|1[0-2]|)$/.test(value) || value === "") {
      setMonth(value);
      if (value.length === 2 && yearRef.current) {
        yearRef.current.focus();
      }
    }
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (/^\d*$/.test(value) && value.length <= 4) {
      const numValue = parseInt(value);
      if (numValue >= 190) {
        setYear(value);
      } else if (value === "") {
        setYear(undefined);
      }
    }
  };

  return (
    <FormItem className="flex h-9 w-full flex-col rounded-md border border-white border-opacity-15 pl-2 pr-2">
      <FormControl className="w-full flex-1">
        <div className="grid grid-cols-[1fr_auto_1fr_auto_1fr] items-center gap-2 pl-3 pr-3">
          <input
            ref={dayRef}
            className={`mx-0 w-full appearance-none border-none bg-transparent text-center text-sm caret-white outline-none placeholder-white placeholder-opacity-25 ${
              disabled ? "opacity-50" : ""
            }`}

            value={day}
            maxLength={2}
            type="text"
            onChange={handleDayChange}
            placeholder="TT"
            disabled={disabled}
          />
          <span className="text-center whitespace-nowrap font-bold text-white text-opacity-20">
            .
          </span>
          <input
            ref={monthRef}
            className={`mx-0 w-full appearance-none border-none bg-transparent text-center text-sm caret-white outline-none placeholder-white placeholder-opacity-25 ${
              disabled ? "opacity-50" : ""
            }`}

            maxLength={2}
            min={1}
            max={12}
            type="text"
            value={month}
            onChange={handleMonthChange}
            placeholder="MM"
            disabled={disabled}
          />
          <span className="text-center whitespace-nowrap font-bold text-white text-opacity-20">
            .
          </span>
          <input
            ref={yearRef}
            className={`mx-0 w-full appearance-none border-none bg-transparent text-center text-sm caret-white outline-none placeholder-white placeholder-opacity-25 ${
              disabled ? "opacity-50" : ""
            }`}

            type="text"
            maxLength={4}
            min={1950}
            value={year}
            onChange={handleYearChange}
            placeholder="JJJJ"
            disabled={disabled}
          />
        </div>
      </FormControl>
    </FormItem>
  );
};

export default InnerDateStuff;
