import { z } from "zod";

export const errorRequired = "Darf nicht leer sein";

export const FormSchemaEmployee = z.object({

  personalDetails: z.object({
    birthName: z.string().optional().nullable(),
    birthDate: z.string().min(1, {
      message: errorRequired,
    }),
    phone: z.string().optional().nullable(),
    address: z.object({
      street: z.string().min(1, {
        message: errorRequired,
      }),
      houseNumber: z.string().min(1, {
        message: errorRequired,
      }),
      city: z.string().min(1, {
        message: errorRequired,
      }),
      postalCode: z.string().min(1, {
        message: errorRequired,
      }),
      country: z.string().optional().nullable(),
      addressAdditional: z.string().optional().nullable(),
    }),
    insuranceNumber: z.string().optional().nullable(),
    birthPlace: z.string().optional().nullable(),
    birthCountry: z.string().optional().nullable(),
    gender: z.string().optional().nullable(),
    nationality: z.string().optional().nullable(),
    employeeNumberSocialFund: z.string().optional().nullable(),
    iban: z.string().optional().nullable(),
    bic: z.string().optional().nullable(),
    disabled: z.string().optional().nullable(),
  }),

  children: z.array(
    z.object({
      lastName: z.string().optional().nullable(),
      firstName: z.string().optional().nullable(),
      birthDate: z.string().optional().nullable(),
    }),
  ),

  employmentDetails: z.object({
    jobTitle: z.string().optional().nullable(),
    highestSchoolDegree: z.string().optional().nullable(),
    highestProfessionalEducation: z.string().optional().nullable(),
    constructionEmployment: z.string().optional().nullable(),
    minorEmployment:z.string().optional().nullable(),
    additionalEmployment: z.string().optional().nullable(),
  }),

  taxInformation: z.object({
    taxId: z.string().optional().nullable(),
    taxClass: z.string().optional().nullable(),
    taxFactor: z.number().optional().nullable(),
    childAllowance: z.number().optional().nullable(),
  }),


  socialSecurity: z.object({
    statutoryHealthInsurance: z.string().optional().nullable(),
    currentHealthInsurance: z.string().optional().nullable(),
    parentProperty: z.string().optional().nullable(),
    miniJobberSecurity: z.object({
      freePensionInsurance: z.string().optional().nullable(),
    }),

  }),

  vwlDetails: z.array(
    z.object({
      receptionThrough: z.string().optional().nullable(),
      sumBenefits: z.number().optional().nullable(),
      dateSince: z.string().optional().nullable(),
      contractNumber: z.string().optional().nullable(),
      iban: z.string().optional().nullable(),
      bic: z.string().optional().nullable(),
    }),
  ),


  taxableEmployment: z.object({
    previousEmployment: z.array(
      z.object({
        dateFrom: z.string().optional().nullable(),
        dateUntil: z.string().optional().nullable(),
        typeOccupation: z.string().optional().nullable(),
        daysOccupation: z.number().optional().nullable(),
      }),
    ),
    miniJobberLimit: z.object({
      moneyLimit: z.string().optional().nullable(),
    }),
  }),
  createdAt: z.string().optional(),
});

export type QuestionnaireEmployee= z.infer<typeof FormSchemaEmployee>











