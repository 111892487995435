import * as React from "react";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useGetListEmployeesQuery } from "@/api/endpoints/payrollApi";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { CreateEmployee } from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/CreateEmployee";
import {
  DataTable,
  DataTableColumn,
  Sort,
} from "@/components/display/DataTable";
import {
  DataTableMultiselect,
  SelectGroup,
} from "@/components/display/DataTableMultiselect";
import {
  DataTablePagination,
  DataTablePaginationElement,
} from "@/components/display/DataTablePagination";
import EmployeeCard from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/EmployeeCard";
import { Filter, FilterEq, FilterInner, FilterParameters } from "@/api/types";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { cn } from "@/lib/utils";
import { Path } from "@/util/DeepProps";
import { CheckedState } from "@radix-ui/react-menu";
import { StatusChange } from "@/feature/payroll/forms/questionnaire/components/StatusChange";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";

const EmployeeOverview: React.FC = () => {
  const [error, setError] = React.useState<string>("");
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<Employee | null>(null);
  const [editQuestionnaire, setEditQuestionnaire] = useState(false);
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);
  const [employeeId, setEmployeeId] = useState<string>("");
  const [employeeToken, setEmployeeToken] = useState<string>("");
  const [employeeName, setEmployeeName] = useState<string>("");
  const [sort, setSort] = useState<Sort<Path<Employee>>>(null);
  const [defaultSelected, setDefaultSelected] = useState<CheckedState>(false);
  const [filter, setFilter] = useState<FilterInner<Employee>>({});
  const [selectionInversion, setSelectionInversion] = useState<string[]>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<
    string | undefined
  >();
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const [filterParams, setFilterParams] =
    useState<FilterParameters<Employee>>();
  const paginationRef = React.createRef<DataTablePaginationElement<Employee>>();
  const handleCloseCreateEmployee = () => {
    setShowCreateEmployee(false);
  };
  const handleShowCreateEmployee = () => {
    setShowCreateEmployee(true);
  };
  const filterQuery: Filter<Employee> = {
    sort: sort ? sort[0] : "lastName",
    direction: sort ? sort[1] : "desc",
    ...filterParams,
    ...filter,
  };
  const {
    data: employeeData,
    error: apiError,
    refetch,
  } = useGetListEmployeesQuery(filterQuery);

  const employees =
    employeeData?.items?.map((employee) => ({
      ...employee,
      id: employee.id! || "",
    })) || [];

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  useEffect(() => {
    setSelectionInversion([]);
  }, [defaultSelected]);

  useEffect(() => {
    if (apiError) {
      setError("Fehler beim Laden der Mitarbeiter.");
    }
  }, [apiError]);

  const employeeColumns: DataTableColumn<Employee>[] = [
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("universal.firstName")}
        </HorizontalFlex>
      ),
      sortKey: "firstName",
      cell: (item) => (
        <HorizontalFlex gap={4} align={"center"} className={"text-nowrap"}>
          {item.firstName}
        </HorizontalFlex>
      ),
    },
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("universal.lastName")}
        </HorizontalFlex>
      ),
      sortKey: "lastName",
      cell: (item) => (
        <VerticalFlex gap={0}>
          <span className={"font-semibold"}>{item.lastName}</span>
        </VerticalFlex>
      ),
    },
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("universal.email")}
        </HorizontalFlex>
      ),
      sortKey: "email",
      cell: (item) => (
        <HorizontalFlex justify={"between"}>
          <span>{item.email}</span>
        </HorizontalFlex>
      ),
    },
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("universal.personnelNumber")}
        </HorizontalFlex>
      ),
      sortKey: "personnelNumber",
      cell: (item) => (
        <HorizontalFlex justify={"between"}>
          <span>{item.personnelNumber}</span>
        </HorizontalFlex>
      ),
    },
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("statusDisplay.status")}
        </HorizontalFlex>
      ),
      cell: (item) => (
        <HorizontalFlex justify={"between"}>
          <span className={cn("status-indicator")}>
            <StatusChange events={item.events} />
          </span>
        </HorizontalFlex>
      ),
    },
  ];

  const selectGroups: SelectGroup<Employee>[] = [
    {
      key: "firstName",
      label: "Vorname",
      values: (employees || []).map((e) => ({
        label: e.firstName!,
        value: e.firstName!,
        filter: FilterEq("firstName", e.firstName!),
      })),
    },
    {
      key: "lastName",
      label: "Nachname",
      values: (employees || []).map((e) => ({
        label: e.lastName!,
        value: e.lastName!,
        filter: FilterEq("lastName", e.lastName!),
      })),
    },
    {
      key: "email",
      label: "E-Mail",
      values: (employees || []).map((e) => ({
        label: e.email!,
        value: e.email!,
        filter: FilterEq("email", e.email!),
      })),
    },
    {
      key: "status",
      label: "Status",
      values: [
        {
          label: "Daten abrufbar",
          value: "status_filled",
          filter: FilterEq("status", "filled"),
        },
        {
          label: "Email geschickt",
          value: "status_email_sent",
          filter: FilterEq("status", "email_sent"),
        },
        {
          label: "Vollständig",
          value: "status_complete",
          filter: FilterEq("status", "complete"),
        },
        {
          label: "Offen",
          value: "status_open",
          filter: FilterEq("status", "open"),
        },
      ],
    },
  ];

  useEffect(() => {
    if (selectedEmployee) {
      setEmployeeId(selectedEmployee.id);
      setEmployeeToken(selectedEmployee.token);
      setEmployeeName(selectedEmployee.firstName);
    }
  }, [selectedEmployee]);

  return (
    <>
      <div>
        <h1 className="text-xl font-medium">Mitarbeiterübersicht</h1>
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex items-center space-x-2 py-4">
          <Button onClick={handleShowCreateEmployee}>
            Mitarbeiter anlegen
          </Button>
        </div>
        {showCreateEmployee && (
          <CreateEmployee onClose={handleCloseCreateEmployee} />
        )}
        {!editQuestionnaire && (
          <div
            className={`transition-all ${selectedEmployee ? (editQuestionnaire ? "w-0" : "w-[65%]") : "w-full"}`}
          >
            <DataTableMultiselect<Employee>
              textSearchKeys={[
                "firstName",
                "lastName",
                "email",
                "personnelNumber",
              ]}
              selectGroups={selectGroups}
              placeholder={"Filtern"}
              onFilterChange={setFilter}
            />
          </div>
        )}
        <div className="mt-4 flex gap-4">
          <div
            className={`transition-all ${selectedEmployee ? (editQuestionnaire ? "w-0" : "w-[65%]") : "w-full"}`}
          >
            <DataTable<Employee>
              onSortChange={setSort}
              columns={employeeColumns}
              onClick={(t) => {
                if (selectedEmployeeId === t.id!) {
                  setSelectedEmployeeId(undefined);
                  setSelectedEmployee(null);
                  setShowDrawer(false);
                } else {
                  const employee = employees.find((e) => e.id === t.id);
                  setSelectedEmployeeId(t.id!);
                  setSelectedEmployee(employee || null);
                  setShowDrawer(true);
                }
              }}
              data={employeeData?.items ?? []}
              selected={
                showDrawer && selectedEmployeeId ? [selectedEmployeeId] : []
              }
            />

            {!editQuestionnaire && (
              <div className={"flex justify-end"}>
                <DataTablePagination
                  ref={paginationRef}
                  onChange={setFilterParams}
                  defaultPageSize={10}
                  result={employeeData}
                />
              </div>
            )}
          </div>

          {selectedEmployee && (
            <div
              className={`duration-600 transition-all ease-in-out ${
                editQuestionnaire ? "w-full" : "w-[35%]"
              }`}
            >
              <EmployeeCard
                setEditQuestionnaire={setEditQuestionnaire}
                editQuestionnaire={editQuestionnaire}
                employee={selectedEmployee}
                employeeId={employeeId}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeOverview;
