import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasChildren,
  setHasTaxableEmployment,
  setHasVwl,
  setIsInConstruction,
} from "src/feature/payroll/apiSlice/formSelectionSlice";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { RootState } from "@/api/store";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";
import { HoverInfo } from "@/feature/settings/UserSettings/form/info/hoverInfo";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";

export function StartPageEmployee() {
  const { hasChildren, hasVwl, hasTaxableEmployment, isInConstruction } =
    useSelector((state: RootState) => state.questionnaire);
  const dispatch = useDispatch();

  return (
    <div className="mb-2 rounded-xl border bg-[#1b1b1b] p-4">
      <div className={"grid gap-4 md:grid-cols-2 lg:grid-cols-4"}>
        <RadioGroup
          value={hasChildren ? "true" : "false"}
          onValueChange={(value) => dispatch(setHasChildren(value === "true"))}
        >
          <Label> {questionnaireTranslation("childCard.hasChildren")}</Label>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="true" id="childrenYes" />
            <Label>{payrollTranslation("universal.yes")}</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="false" id="childrenNo" />
            <Label>{payrollTranslation("universal.no")}</Label>
          </div>
        </RadioGroup>

        <div className={"flex space-x-2"}>
          <div className={"flex-grow"}>
            <HoverInfo
              title={questionnaireTranslation("benefitsCard.benefits")}
              description={questionnaireTranslation(
                "benefitsCard.infoCard.infoBenefits",
              )}
              align={"start"}
            />
          </div>

          <RadioGroup
            value={hasVwl ? "true" : "false"}
            onValueChange={(value) => dispatch(setHasVwl(value === "true"))}
          >
            <Label>
              {questionnaireTranslation("benefitsCard.hasBenefits")}
            </Label>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="true" id="vwlYes" />
              <Label>{payrollTranslation("universal.yes")}</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="false" id="vwlNo" />
              <Label>{payrollTranslation("universal.no")}</Label>
            </div>
          </RadioGroup>
        </div>

        <div className={"flex space-x-2"}>
          <div className={"flex-grow"}>
            <HoverInfo
              title={questionnaireTranslation("taxableCard.taxableEmployment")}
              description={questionnaireTranslation(
                "taxableCard.infoCard.infoTaxable",
              )}
              align={"start"}
            />
          </div>

          <RadioGroup
            value={hasTaxableEmployment ? "true" : "false"}
            onValueChange={(value) =>
              dispatch(setHasTaxableEmployment(value === "true"))
            }
          >
            <Label>{questionnaireTranslation("taxableCard.hasTaxable")}</Label>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="true" id="taxableEmploymentYes" />
              <Label>{payrollTranslation("universal.yes")}</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="false" id="taxableEmploymentNo" />
              <Label>{payrollTranslation("universal.no")}</Label>
            </div>
          </RadioGroup>
        </div>

        <RadioGroup
          value={isInConstruction ? "true" : "false"}
          onValueChange={(value) =>
            dispatch(setIsInConstruction(value === "true"))
          }
        >
          <Label>
            {questionnaireTranslation("personalCard.isInConstruction")}
          </Label>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="true" id="constructionYes" />
            <Label>{payrollTranslation("universal.yes")}</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="false" id="constructionNo" />
            <Label>{payrollTranslation("universal.no")}</Label>
          </div>
        </RadioGroup>
      </div>
      {/*<div className={"col-span-4 mt-8"}>
        <InfoCard
          infoText={
            <>
              Hast du Kinder, VWL-Veträge, steuerpflichtige Vorbeschäftigungen
              oder bist im Baugewerbe tätig? Dann lass es uns wissen! <br />
              <strong>VWL</strong>, oder auch{" "}
              <strong>vermögenswirksame Leistungen</strong>, sind Zahlungen, die
              in bestimmte Sparformen wie Bausparverträge oder Fonds fließen und
              den Vermögensaufbau unterstützen.
              <br />
              <strong>Steuerpflichtige Vorbeschäftigungen</strong> sind frühere
              Arbeitsverhältnisse, bei denen der du steuerpflichtige Einkünfte
              erzielt hast.
            </>
          }
        />
      </div>*/}
    </div>
  );
}
