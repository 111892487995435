import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { formatDate } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/FormateDateFunction";

export const PrintEmployment: React.FC<{
  formData: FormData;
}> = ({ formData }) => {
  const DAYS = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ] as const;

  return (
    <div>
      <h2 style={{ borderBottom: "2px solid gray" }}>
        {questionnaireTranslation("employmentCard.occupation")}
      </h2>
      <div className="grid-container">
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.entryDate")}
        </span>
        <span>
          {formatDate(getField("employmentDetails.entryDate", formData))}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.firstEntryDate")}
        </span>
        <span>
          {formatDate(getField("employmentDetails.firstEntryDate", formData))}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.employmentCompany")}
        </span>
        <span>{getField("employmentDetails.employmentCompany", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.additionalEmployment")}
        </span>
        <span>
          {getField("employmentDetails.additionalEmployment", formData).length >
            0 &&
            payrollTranslation(
              `universal.${getField("employmentDetails.additionalEmployment", formData)}`,
            )}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.minorEmployment")}
        </span>
        <span>
          {getField("employmentDetails.minorEmployment", formData).length > 0 &&
            payrollTranslation(
              `universal.${getField("employmentDetails.minorEmployment", formData)}`,
            )}
        </span>

        <span className="title-fill">
          {questionnaireTranslation("employmentCard.jobTitle")}
        </span>
        <span>{getField("employmentDetails.jobTitle", formData)}</span>

        <span className="title-fill">
          {questionnaireTranslation("employmentCard.activities")}
        </span>
        <span>{getField("employmentDetails.jobActivity", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.probation")}
        </span>
        <span>{getField("employmentDetails.probation", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.probationDuration")}
        </span>
        <span>{getField("employmentDetails.probationDuration", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.weeklyWorkHours")}
        </span>
        <span>{getField("employmentDetails.weeklyWorkHours", formData)}</span>

        <span className="title-fill">
          {questionnaireTranslation("employmentCard.vacationEntitlement")}
        </span>
        <span>
          {getField("employmentDetails.vacationEntitlement", formData)}
        </span>

        <span className="title-fill">
          {questionnaireTranslation("employmentCard.contractForm")}
        </span>
        <span>{getField("employmentDetails.contractForm", formData)}</span>

        <span className={"title-fill"}>
          {questionnaireTranslation("employmentCard.hourDistribution")}
        </span>
        <span style={{ gridColumn: "span 3" }}>
          <div
            className="grid-container-hours"
            style={{ gridColumn: "span 3", padding: "2px" }}
          >
            {DAYS.map((day, dayIndex) => (
              <span
                key={dayIndex}
                className="title-fill-opaque hour-distribution"
              >
                {questionnaireTranslation(
                  `employmentCard.distributionValues.${day}`,
                )}
              </span>
            ))}
            {DAYS.map((day, hourIndex) => (
              <span key={hourIndex} className="hour-distribution">
                {getField(
                  `employmentDetails.hourDistribution.${day}`,
                  formData,
                ) ?? "--"}
              </span>
            ))}
          </div>
        </span>

        <span className="title-fill">
          {questionnaireTranslation("employmentCard.costCenter")}
        </span>
        <span>{getField("employmentDetails.costCenter", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.departmentNr")}
        </span>
        <span>{getField("employmentDetails.departmentNumber", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("employmentCard.highestEduQualification")}
        </span>
        <span>
          {getField("employmentDetails.highestSchoolDegree", formData).length >
            0 &&
            questionnaireTranslation(
              `employmentCard.eduValues.${getField("employmentDetails.highestSchoolDegree", formData)}`,
            )}
        </span>

        <span className="title-fill">
          {questionnaireTranslation("employmentCard.highestProfEducation")}
        </span>
        <span>
          {getField("employmentDetails.highestProfessionalEducation", formData)
            .length > 0 &&
            questionnaireTranslation(
              `employmentCard.profValues.${getField("employmentDetails.highestProfessionalEducation", formData)}`,
            )}
        </span>

        <span className="title-fill">
          {questionnaireTranslation("employmentCard.constructionEmployment")}
        </span>
        <span>
          {formatDate(
            getField("employmentDetails.constructionEmployment", formData),
          )}
        </span>
      </div>
    </div>
  );
};
