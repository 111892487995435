import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmploymentState {
  employmentTypes: Record<string, string>;
}

const initialState: EmploymentState = {
  employmentTypes: {},
};

const employmentSlice = createSlice({
  name: "employment",
  initialState,
  reducers: {
    setEmploymentType(state, action: PayloadAction<{ employeeId: string; employmentType: string }>) {
      const { employeeId, employmentType } = action.payload;
      if (!state.employmentTypes[employeeId]) {
        state.employmentTypes[employeeId] = employmentType;
      }else{
        console.log("BEREITS VORHANDEN")
      }
    },
  },
});

export const { setEmploymentType } = employmentSlice.actions;

export default employmentSlice.reducer;
