import React from "react";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { t } from "i18next";
import { Info } from "lucide-react";
import { ALIGN_OPTIONS } from "@radix-ui/react-popper";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";

export const TaxIdHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.taxId")}
      align={"start"}
      description={t("component.companyForm.accounting.taxIdDesc")}
    />
  );
};

export const VatIdHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.vatId")}
      align={"end"}
      description={t("component.companyForm.accounting.vatIdDesc")}
    />
  );
};

// Registergericht Hover Info
export const RegistrationCourtHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.registrationCourt")}
      align={"start"}
      description={t("component.companyForm.accounting.registrationCourtDesc")}
    />
  );
};

export const InvoiceNumberFormatHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.invoiceNumberFormat")}
      align={"end"}
      description={t(
        "component.companyForm.accounting.invoiceNumberFormatDesc",
      )}
    />
  );
};

export const InvoiceNumberStartHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.invoiceNumberStart")}
      align={"end"}
      description={t("component.companyForm.accounting.invoiceNumberStartDesc")}
    />
  );
};

// Handelsregister Hover Info
export const CommercialRegisterHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.commercialRegister")}
      align={"end"}
      description={t("component.companyForm.accounting.commercialRegisterDesc")}
    />
  );
};

// Umsatzsteuersatz Hover Info
export const VatRateHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.vatRate")}
      align={"start"}
      description={t("component.companyForm.accounting.vatRateDesc")}
    />
  );
};

// Steuersystem SKR03 Hover Info
export const TaxSystemSKR03HoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.taxSystemSKR03")}
      align={"end"}
      description={t("component.companyForm.accounting.taxSystemSKR03Desc")}
    />
  );
};

// Steuersystem SKR04 Hover Info
export const TaxSystemSKR04HoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.taxSystemSKR04")}
      align={"end"}
      description={t("component.companyForm.accounting.taxSystemSKR04Desc")}
    />
  );
};

// Kleinunternehmerregelung Hover Info
export const SmallBusinessHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.isSmallBusiness")}
      align={"end"}
      description={t("component.companyForm.accounting.isSmallBusinessDesc")}
    />
  );
};

// Umsatzsteuerpflicht Hover Info
export const VatLiabilityHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.vatLiability")}
      align={"start"}
      description={t("component.companyForm.accounting.vatLiabilityDesc")}
    />
  );
};

// Beraternummer Hover Info
export const DatevConsultantNumberHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.datevConsultantNumber")}
      align={"end"}
      description={t(
        "component.companyForm.accounting.datevConsultantNumberDesc",
      )}
    />
  );
};

// Mandantennummer Hover Info
export const DatevClientNumberHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.datevClientNumber")}
      align={"end"}
      description={t("component.companyForm.accounting.datevClientNumberDesc")}
    />
  );
};

export const HoverInfo = ({
  title,
  description,
  align,
}: {
  title: string;
  description: string;
  align: (typeof ALIGN_OPTIONS)[number];
}) => {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <QuestionMarkCircledIcon className={"text-muted-foreground"} />
      </HoverCardTrigger>
      <HoverCardContent align={align} side={"top"} className={"w-96"}>
        <VerticalFlex gap={2}>
          <HorizontalFlex gap={2} align={"center"}>
            <Info size={18} className={"text-muted-foreground"} />
            <h4 className="text-sm font-semibold">{title}</h4>
          </HorizontalFlex>
          <p className="text-xs text-muted-foreground">{description}</p>
        </VerticalFlex>
      </HoverCardContent>
    </HoverCard>
  );
};
