import React from "react";
import {
  countriesTranslation,
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { formatDate } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/FormateDateFunction";

export const PrintPersonalDetails: React.FC<{
  formData: FormData;
}> = ({ formData }) => {
  return (
    <div>
      <h2 style={{ borderBottom: "2px solid gray" }}>
        {questionnaireTranslation("personalCard.personalInfo")}
      </h2>
      <div className="grid-container">
        <span className="title-fill">
          {payrollTranslation("universal.birthName")}
        </span>
        <span>{getField("personalDetails.birthName", formData)}</span>
        <span className="title-fill">
          {payrollTranslation("universal.birthDate")}
        </span>
        <span>
          {formatDate(getField("personalDetails.birthDate", formData))}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.phone")}
        </span>
        <span>{getField("personalDetails.phone", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.address.street")}
        </span>
        <span>{getField("personalDetails.address.street", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.address.houseNumber")}
        </span>
        <span>{getField("personalDetails.address.houseNumber", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.address.city")}
        </span>
        <span>{getField("personalDetails.address.city", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.address.postalCode")}
        </span>
        <span>{getField("personalDetails.address.postalCode", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.address.country")}
        </span>
        <span>
          {getField("personalDetails.address.country", formData).length > 0 &&
            countriesTranslation(
              getField("personalDetails.address.country", formData),
            )}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.address.addressAddition")}
        </span>
        <span>{getField("personalDetails.address.additional", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.insuranceNumber")}
        </span>
        <span>{getField("personalDetails.insuranceNumber", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.severelyDisabled")}
        </span>
        <span>
          {getField("personalDetails.disabled", formData).length > 0 &&
            questionnaireTranslation(
              `personalCard.${getField("personalDetails.disabled", formData)}`,
            )}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.birthPlace")}
        </span>
        <span>{getField("personalDetails.birthPlace", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.birthCountry")}
        </span>
        <span>{getField("personalDetails.birthCountry", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.gender")}
        </span>
        <span>
          {getField("personalDetails.gender", formData).length > 0 &&
            questionnaireTranslation(
              `personalCard.${getField("personalDetails.gender", formData)}`,
            )}
        </span>

        <span className="title-fill">
          {questionnaireTranslation("personalCard.nationality")}
        </span>
        <span>{getField("personalDetails.nationality", formData)}</span>
        <span className="title-fill">
          {payrollTranslation("universal.iban")}
        </span>
        <span>{getField("personalDetails.iban", formData)}</span>
        <span className="title-fill">
          {payrollTranslation("universal.bic")}
        </span>
        <span>{getField("personalDetails.bic", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("personalCard.employeeNumber")}
        </span>
        <span>
          {getField("personalDetails.employeeNumberSocialFund", formData)}
        </span>
      </div>
    </div>
  );
};

