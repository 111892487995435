import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import MultiStepCard, {
  UserTypeEnum,
} from "@/feature/payroll/MultiStepGeneral";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import {
  useGetEmployeeQuestionnaireQuery,
  useSaveEmployeeQuestionnaireMutation,
} from "@/api/endpoints/payrollApi";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { useParams } from "react-router";
import { defaultValuesEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/DefaultValuesEmployee";
import { useNavigate } from "react-router-dom";
import { useStepManagement } from "@/feature/payroll/forms/questionnaire/hooks/UseStepManagement";
import { getEmploymentTypeLabel } from "@/feature/payroll/forms/questionnaire/functions/EmploymentTypeLabel";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

const EmployeeForm: React.FC<{
  userType?: UserTypeEnum;
  employee?: Employee;
  setCurrentTitle?: (title: string) => void;
}> = ({ setCurrentTitle, employee }) => {
  const form = useForm<z.infer<typeof FormSchemaEmployee>>({
    resolver: zodResolver(FormSchemaEmployee),
    defaultValues: defaultValuesEmployee,
  });

  const printRef = useRef<HTMLDivElement>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const { currentStep, totalSteps, handleStepChange, handleTotalStepsChange } =
    useStepManagement();

  useEffect(() => {
    const subscription = form.watch((values) => {
      localStorage.setItem("employeeFormData", JSON.stringify(values));
    });
    return () => subscription.unsubscribe();
  }, [form]);

  useEffect(() => {
    const storedData = localStorage.getItem("employeeFormData");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        form.reset(parsedData);
      } catch (e) {
        console.error("Fehler beim Laden der Daten aus dem localStorage:", e);
      }
    }
  }, [form]);

  const { token } = useParams<{ token: string }>();
  const [saveEmployeeQuestionnaire] = useSaveEmployeeQuestionnaireMutation();
  const { data, error } = useGetEmployeeQuestionnaireQuery<any>({
    employeeToken: token!,
  });

  const [employeeName, setEmployeeName] = useState<string>("");
  const [employmentTypeLabel, setEmploymentTypeLabel] = useState<string>("");

  useEffect(() => {
    if (data) {
      setEmployeeName(
        `${data?.employee?.firstName} ${data?.employee?.lastName}`,
      );
      setEmploymentTypeLabel(
        getEmploymentTypeLabel(data?.sendQuestionnaireEvent?.employment_type),
      );
    }
  }, [data]);

  if (error) {
    navigate("/*");
  }

  async function onSubmit(value: z.infer<typeof FormSchemaEmployee>) {
    try {
      const response = await saveEmployeeQuestionnaire({
        employeeForm: value,
        employeeToken: token!,
      }).unwrap();
      setFormSubmitted(true);
      console.log("Successfully submitted:", response);
      navigate("/success");
    } catch (error) {
      navigate("/*");
      console.error(
        "Error while submitting the employee questionnaire:",
        error,
      );
    }
  }

  return (
    <div className="mx-auto w-screen p-10 md:w-[80vw] lg:w-[80vw]">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="">
            <h1 className="mb-8 text-xl">
              Hallo <strong>{employeeName}</strong>! <br />
              Bitte fülle den folgenden {employmentTypeLabel} aus. Bitte achte
              dabei auf die Korrektheit der Daten.
              <br />
              Mit Absenden des Fragebogens werden die eingegebenen Daten
              automatisch an uns übermittelt!
            </h1>
            <MultiStepCard
              form={form}
              employee={employee}
              userType={UserTypeEnum.EMPLOYEE}
              setCurrentTitle={setCurrentTitle}
              onStepChange={handleStepChange}
              onTotalStepsChange={handleTotalStepsChange}
            />

            {currentStep === totalSteps - 1 && (
              <div className={"flex justify-center"}>
                <Button type="submit">Daten absenden</Button>
              </div>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
};

export default EmployeeForm;
