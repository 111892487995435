import React, { useEffect, useState } from "react";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import RadioFormField from "@/feature/payroll/components/formFields/RadioFormField";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

export function TaxesMiniJobber({ form, employee }: QuestionnaireProps) {
  const [freePension, setFreePension] = useState<string>("");

  const { data, error: apiError } = useGetEmployeeDataQuery<any>(
    { employeeId: employee?.id || "" },
    { skip: !employee?.id },
  );

  useEffect(() => {
    if (data) {
      const employeeData = data?.events?.find(
        (event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent,
      )?.employeeFillQuestionnaireEvent;
      const miniJobberPensionInsurance =
        employeeData?.socialSecurity.miniJobberSecurity.freePensionInsurance;
      setFreePension(miniJobberPensionInsurance);
    }
  }, [data]);

  return (
    <div className={"col-span-4 grid grid-cols-2 gap-2"}>
      <RadioFormField
        hoverInfoTitle={questionnaireTranslation("taxCard.generalize")}
        hoverInfoDescription={questionnaireTranslation(
          "taxCard.infoCard.infoGeneralize",
        )}
        control={form.control}
        name={"taxInformation.miniJobberTax.generalize"}
        labelKey={questionnaireTranslation("taxCard.generalize")}
        options={[
          { label: payrollTranslation("universal.yes"), value: "yes" },
          { label: payrollTranslation("universal.no"), value: "no" },
        ]}
      />

      {freePension === "no" && (
        <RadioFormField
          hoverInfoTitle={questionnaireTranslation("taxCard.rollOff")}
          hoverInfoDescription={questionnaireTranslation(
            "taxCard.infoCard.rollOff",
          )}
          control={form.control}
          name={"taxInformation.miniJobberTax.rollOf"}
          labelKey={questionnaireTranslation("taxCard.rollOff")}
          options={[
            { label: "2%", value: "2" },
            { label: "20%", value: "20" },
          ]}
        />
      )}

      {/*<div className={"col-span-4"}>
        <InfoCard
          infoText={
            <>
              Falls es sich um eine geringfügige Beschäftigung handelt, kannst
              du hier Generalisierung und Abwälzung eintragen.
            </>
          }
        />
      </div>*/}
    </div>
  );
}
