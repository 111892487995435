import { reduxApi } from "../reduxApi";
import { Filter, FilterResult } from "@/api/types";
import { BiDocument } from "@/models/document";

const documentApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    documentFilter: builder.query<FilterResult<BiDocument>, Filter<BiDocument>>(
      {
        query: (body) => ({
          url: "document/filter",
          method: "POST",
          body,
        }),
        providesTags: ["Document"],
      },
    ),
    documentEdit: builder.mutation<BiDocument, BiDocument>({
      query: (body) => ({
        url: `document/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Document"],
    }),
    documentDelete: builder.mutation<BiDocument, BiDocument>({
      query: ({ id }) => ({
        url: `document/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Document"],
    }),
    getDocumentById: builder.query<BiDocument, string>({
      query: (id) => ({
        url: `document/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useDocumentFilterQuery,
  useDocumentEditMutation,
  useDocumentDeleteMutation,
  useGetDocumentByIdQuery,
} = documentApi;
