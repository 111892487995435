import React from "react";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { EmploymentTypeEnum } from "@/feature/payroll/MultiStepGeneral";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";

export function Payment({ form, employee }: QuestionnaireProps) {
  const { employmentType } = useEmploymentType(employee!);
  const YEARS = ["yearOne", "yearTwo", "yearThree"] as const;

  return (
    <div className="mb-2 w-full gap-4 rounded-xl border bg-[#1b1b1b] p-4">
      {employmentType === EmploymentTypeEnum.TRAINEE ? (
        <div>
          {YEARS.map((year, yearIndex) => (
            <div key={yearIndex} className="mb-4">
              <h3 className="mb-2 text-lg font-bold text-white">
                {questionnaireTranslation(`paymentCard.${year}`)}
              </h3>
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-1">
                  <FormSimpleNumber
                    form={form}
                    name={`payment.traineePayment.${year}.sum`}
                    label={questionnaireTranslation("paymentCard.sum")}
                    labelClassName={"text-white"}
                  />
                </div>
                <div className="col-span-1 content-end">
                  <DateInputField
                    control={form.control}
                    name={`payment.traineePayment.${year}.dateSum`}
                    labelKey={payrollTranslation("universal.validFrom")}
                  />
                </div>
                <div className="col-span-1">
                  <FormSimpleNumber
                    form={form}
                    name={`payment.traineePayment.${year}.hourlyWage`}
                    label={questionnaireTranslation("paymentCard.hourlyWage")}
                    labelClassName={"text-white"}
                  />
                </div>
                <div className="col-span-1 content-end">
                  <DateInputField
                    control={form.control}
                    name={`payment.traineePayment.${year}.dateWage`}
                    labelKey={payrollTranslation("universal.validFrom")}
                  />
                </div>
              </div>
            </div>
          ))}

          {/*<div className={"col-span-2"}>
            <InfoCard
              infoText={<>Hier kannst du die Ausbildungsvergütung eintragen.</>}
            />
          </div>*/}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <FormSimpleText
              form={form}
              name={"payment.description"}
              label={questionnaireTranslation("paymentCard.paymentDescription")}
            />
          </div>

          <FormSimpleMoney
            form={form}
            name={"payment.sum"}
            label={questionnaireTranslation("paymentCard.sum")}
            labelClassName={"text-white"}
            itemClassName={"mb-2"}
          />
          <DateInputField
            control={form.control}
            name={"payment.dateSum"}
            labelKey={payrollTranslation("universal.validFrom")}
          />
          <FormSimpleMoney
            form={form}
            name={"payment.hourlyWage"}
            label={questionnaireTranslation("paymentCard.hourlyWage")}
            labelClassName={"text-white"}
            itemClassName={"mb-2"}
          />
          <DateInputField
            control={form.control}
            name={"payment.dateWage"}
            labelKey={payrollTranslation("universal.validFrom")}
          />
          {/*<div className={"col-span-2"}>
            <InfoCard
              infoText={<>Hier kannst du die Entlohnung eintragen.</>}
            />
          </div>*/}
        </div>
      )}
    </div>
  );
}
