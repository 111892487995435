import React from "react";
import { t } from "i18next";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import { FieldPath } from "react-hook-form/dist/types";
import { z } from "zod";
import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";

const questionnaireTranslation = (key: string) =>
  t(`component.app.payroll.questionnaireForm.${key}`);
const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export function HourDistribution({ form }: QuestionnaireProps) {
  return (
    <div className="w-full grid grid-cols-7 gap-4">
      {DAYS.map((day) => (
        <div key={day} className="col-span-1">
          <FormSimpleNumber
            itemClassName={"min-w-0"}
            className="col-span-1 min-w-0"
            form={form}
            name={`employmentDetails.hourDistribution.${day}` as FieldPath<z.infer<typeof FormSchemaEmployer>>}
            placeholder={questionnaireTranslation(`employmentCard.distributionValues.${day}` as const)}
          />
        </div>
      ))}
    </div>
  );
}
