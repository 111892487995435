import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import React from "react";

interface DialogOnSubmitProps {
  open: boolean;
  onClose: () => void;
}

export function DialogOnSubmit({ open, onClose }: DialogOnSubmitProps) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Fertig!</DialogTitle>
          <DialogDescription>
            Das Formular wurde erflgreich gespeichert!
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
        </div>
        <DialogFooter>
          <DialogClose onClick={onClose}>
            <Button variant="secondary" type="button">Schließen</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
