import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import EmployeeOverview from "@/feature/payroll/forms/questionnaire/components/employeeOverview/EmployeeOverview";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

export interface PayrollProps<T extends z.ZodTypeAny> {
  form: UseFormReturn<z.infer<T>>;
}


function Payroll() {
  const navigate = useNavigate();
  const [showEmploymentTypeOptions, setShowEmploymentTypeOptions] =
    useState(false); // Steuerung, ob employmentType-Buttons angezeigt werden

  const handleQuestionnaireClick = () => {
    setShowEmploymentTypeOptions(true); // employmentType-Optionen anzeigen, wenn Personalfragebogen gewählt wird
  };

  const handleEmploymentTypeSelection = (type: string) => {
    navigate(`/questionnaire/employer/${type}`); // Navigiere mit employmentType als Parameter
  };


  return (
    <div>
      <h1 className={"mb-4 text-2xl font-medium"}>Lohnbuchhaltung</h1>

      {/*<div className="mb-4 mt-10 flex w-[80%] gap-4">

        <Button
          variant={"secondary"}
          title="questionnaire"
          onClick={handleQuestionnaireClick}
        >
          {payrollTranslation("questionnaire")}
        </Button>

        <Button
          variant={"secondary"}
          title="employmentChanges"
          onClick={() => navigate("/changes")}
        >
          {payrollTranslation("changes")}
        </Button>

        <Button
          variant={"secondary"}
          title="absence"
          onClick={() => navigate("/absence")}
        >
          {payrollTranslation("absence")}
        </Button>

        <Button
          variant={"secondary"}
          title="termination"
          onClick={() => navigate("/termination")}
        >
          {payrollTranslation("termination")}
        </Button>

        <Button
          variant={"secondary"}
          title="documents"
          onClick={() => navigate("/upload")}
        >
          {payrollTranslation("documents")}
        </Button>
      </div>*/}

      {/* Zusatz-Buttons für den Mitarbeitertyp, nur sichtbar nach Auswahl von „Personalfragebogen“ */}
      {showEmploymentTypeOptions && (
        <div className="mb-4 mt-4 flex w-[80%] gap-4">
          {["normalEmployee", "trainee", "miniJob"].map((type) => (
            <Button
              key={type}
              variant="outline"
              onClick={() => handleEmploymentTypeSelection(type)}
            >
              {questionnaireTranslation(type)}
            </Button>
          ))}
        </div>
      )}

      <div className={"pt-8"}>
        <EmployeeOverview />
      </div>
    </div>
  );
}

export default Payroll;
