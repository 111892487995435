import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { EmploymentTypeEnum } from "@/feature/payroll/MultiStepGeneral";

export const PrintSocialSec: React.FC<{
  formData: FormData;
  employee?: Employee;
}> = ({ formData, employee }) => {
  const { employmentType } = useEmploymentType(employee!);
  return (
    <div>
      <h2 style={{ borderBottom: "2px solid gray" }}>
        {questionnaireTranslation("socialSecurityCard.socialSecurity")}
      </h2>

      <div className="grid-container">
        <span className="title-fill">
          {questionnaireTranslation(
            "socialSecurityCard.currentHealthInsurance",
          )}
        </span>
        <span>
          {getField("socialSecurity.currentHealthInsurance", formData).length >
            0 &&
            questionnaireTranslation(
              `socialSecurityCard.insuranceNames.${getField("socialSecurity.currentHealthInsurance", formData)}`,
            )}
        </span>
        <span className="title-fill">
          {questionnaireTranslation(
            "socialSecurityCard.statutoryHealthInsurance",
          )}
        </span>
        <span>
          {getField("socialSecurity.statutoryHealthInsurance", formData)
            .length > 0 &&
            questionnaireTranslation(
              `socialSecurityCard.insuranceNames.${getField("socialSecurity.statutoryHealthInsurance", formData)}`,
            )}
        </span>

        <span className="title-fill">
          {questionnaireTranslation("socialSecurityCard.status")}
        </span>
        <span>
          {getField("socialSecurity.deuevStatus", formData).length > 0 &&
            questionnaireTranslation(
              `socialSecurityCard.statusValues.${getField("socialSecurity.deuevStatus", formData)}`,
            )}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("socialSecurityCard.insuranceKey")}
        </span>
        <span>
          {getField("socialSecurity.insuranceKey", formData).length > 0 &&
            questionnaireTranslation(
              `socialSecurityCard.insuranceKeyValue.${getField("socialSecurity.insuranceKey", formData)}`,
            )}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("socialSecurityCard.parentProperty")}
        </span>
        <span>
          {getField("socialSecurity.parentProperty", formData).length > 0 &&
            payrollTranslation(
              `universal.${getField("socialSecurity.parentProperty", formData)}`,
            )}
        </span>
        {employmentType === EmploymentTypeEnum.MINIJOB && (
          <>
            <span className="title-fill">
              {questionnaireTranslation(
                "socialSecurityCard.miniJobberSecurity.freePensionInsurance",
              )}
            </span>
            <span>
              {getField(
                "socialSecurity.miniJobberSecurity.freePensionInsurance",
                formData,
              ).length > 0 &&
                payrollTranslation(
                  `universal.${getField("socialSecurity.miniJobberSecurity.freePensionInsurance", formData)}`,
                )}
            </span>
          </>
        )}
      </div>
    </div>
  );
};
