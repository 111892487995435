import { FC } from "react";

export const Line: FC<{
  h?: boolean;
  v?: boolean;
}> = (props) => {
  if (props.h) {
    return (
      <div
        className={`h-full w-1 justify-self-stretch border-t border-gray-300`}
      />
    );
  } else {
    return <div className={`w-[1px] self-stretch border border-gray-300`} />;
  }
};
