import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireProps } from "@/feature/payroll/forms/questionnaire/types/InterfaceQuestionnaire";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import InfoCard from "@/feature/payroll/forms/questionnaire/functions/InfoText";

export function Limitation({ form, employee }: QuestionnaireProps) {
  return (
    <div className="mb-2 grid grid-cols-3 gap-4 rounded-xl border bg-[#1b1b1b] p-4">
      <SelectFormField
        name={"contractLimitation.limitType"}
        control={form.control}
        labelKey={questionnaireTranslation("limitCard.limitType")}
        options={[
          {
            label: questionnaireTranslation("limitCard.limitValues.limited"),
            value: "limited",
          },
          {
            label: questionnaireTranslation("limitCard.limitValues.unlimited"),
            value: "unlimited",
          },
          {
            label: questionnaireTranslation(
              "limitCard.limitValues.purposeLimit",
            ),
            value: "purposeLimit",
          },
        ]}
      />
      <DateInputField
        control={form.control}
        name={"contractLimitation.contractEndDate"}
        labelKey={questionnaireTranslation("limitCard.dateOfLimit")}
      />
      <DateInputField
        control={form.control}
        name={"contractLimitation.contractSignedDate"}
        labelKey={questionnaireTranslation("limitCard.dateSetContract")}
      />
      {/*<div className={"col-span-3"}>
        <InfoCard
          infoText={
            <>
              Falls der Arbeitsvertrag befristet ist, kannst du diese Befristung
              hier spezifizieren.
            </>
          }
        />
      </div>*/}
    </div>
  );
}
