import { useEffect } from "react";

export function usePreventScroll(
  shouldPreventScroll: boolean,
  options?: { min: number },
): void {
  useEffect(() => {
    let currentScroll = window.scrollY;
    if (options?.min && options.min > currentScroll) {
      currentScroll = options.min;
    }

    if (shouldPreventScroll) {
      // Save the current scroll position
      // Prevent scrolling by fixing the body position
      document.body.style.overflow = "hidden";
      window.scrollTo({
        top: currentScroll,
        behavior: "smooth",
      });
    }
    // Clean up when component unmounts
    return () => {
      if (shouldPreventScroll) {
        document.body.style.overflow = "auto";

        // Restore scroll to the saved position
        window.scrollTo(0, currentScroll);
      }
    };
  }, [shouldPreventScroll]);
}
