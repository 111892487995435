import * as React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "@/components/modal/useModal";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import AddCompanyForm from "@/components/company/AddCompanyForm";

export const AddCompanyButton: FC<{}> = () => {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const showAddCompanyModal = () => {
    showModal({
      title: t("component.companySettings.modal.title"),
      description: t("component.companySettings.modal.description"),
      content: ({ close }) => <AddCompanyForm close={close} />,
      hint: t("component.companySettings.modal.hintDescription"),
    });
  };

  return (
    <Button
      className={"gap-2"}
      variant={"secondary"}
      onClick={showAddCompanyModal}
    >
      {t("component.companySettings.addCompany")}
      <Plus size={18} />
    </Button>
  );
};
