import React from "react";
import { formatToEuro } from "@/feature/payroll/forms/questionnaire/components/employeeOverview/employeeCard/components/cardContent/employeeDataSteps/TaxData";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";

const VwlData: React.FC<{
  vwlDetails: QuestionnaireEmployee["vwlDetails"];
}> = ({ vwlDetails }) => {
  return (
    <section className="mb-8 font-light">
      <h3 className="mb-4 border-b-2 border-b-zinc-700 text-base font-semibold">
        {questionnaireTranslation("benefitsCard.benefits")}
      </h3>

      {vwlDetails?.length > 0 ? (
        vwlDetails?.map((vwl, index) => (
          <div
            key={index}
            className="mb-4 grid grid-cols-2 gap-4 md:grid-cols-4"
          >
            <div className="col-span-1 font-medium">
              {questionnaireTranslation("benefitsCard.receptionThrough")}:
            </div>
            <div className="col-span-1">{vwl.receptionThrough || "-"}</div>

            <div className="col-span-1 font-medium">
              {questionnaireTranslation("benefitsCard.sumBenefits")}:
            </div>
            <div className="col-span-1">
              {vwl.sumBenefits != null ? formatToEuro(vwl.sumBenefits) : "-"}
            </div>
            <div className="col-span-1 font-medium">
              {questionnaireTranslation("benefitsCard.since")}:
            </div>
            <div className="col-span-1">
              {vwl.dateSince
                ? new Date(vwl.dateSince).toLocaleDateString("de-DE")
                : "-"}
            </div>
            <div className="col-span-1 font-medium">
              {questionnaireTranslation("benefitsCard.contractNr")}:
            </div>
            <div className="col-span-1">{vwl.contractNumber || "-"}</div>
            <div className="col-span-1 font-medium">
              {payrollTranslation("universal.iban")}:
            </div>
            <div className="col-span-1">{vwl.iban || "-"}</div>
            <div className="col-span-1 font-medium">
              {payrollTranslation("universal.bic")}:
            </div>
            <div className="col-span-1">{vwl.bic || "-"}</div>
            <HorizontalGradientLine className={"col-span-4"} />
          </div>
        ))
      ) : (
        <p>{questionnaireTranslation("benefitsCard.noVwl")}</p>
      )}
    </section>
  );
};

export default VwlData;
